import { Routes, Route } from "react-router-dom";
import Layout from "../src/routes/Layout";
import ConsultaTimes from "./routes/Time/ConsultaTimes";
import ConsultaCategorias from "./routes/Categorias/ConsultarCategoria";
import NotFound from "./routes/NotFound";
import ConsultaChamados from "./routes/Chamados/ConsultaChamados";
import Login from "./routes/Login";
import Logout from "./routes/Logout";
import ConsultaDepartamentos from "./routes/Departamentos/ConsultaDepartamentos";
import PesquisaSatisfacao from "./routes/PesquisaSatisfacao";

const App = () => {
  return (
    <Routes>
      <Route path="/Login" element={<Login />} />
      <Route path="/Logout" element={<Logout />} />
      <Route path="/PesquisaSatisfacao" element={<PesquisaSatisfacao />} />
      <Route path="/" element={<Layout />}>
        <Route path="/Times">
          <Route path="/Times/Consulta" element={<ConsultaTimes />} />
        </Route>
        <Route path="/Categorias">
          <Route path="/Categorias/Consulta" element={<ConsultaCategorias />} />
        </Route>
        <Route path="/Departamentos">
          <Route
            path="/Departamentos/Consulta"
            element={<ConsultaDepartamentos />}
          />
        </Route>
        <Route path="/" element={<ConsultaChamados />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
export default App;
