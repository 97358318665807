import clsx from "clsx";
import React, { useRef, useState } from "react";
import { useClick } from "../../../hooks/useClick";
import CardOption from "./CardOption";

const CardOptionContainer = ({ item, consulta }) => {
  const { cardOptions } = consulta;

  const [isVisible, setIsVisible] = useState(false);
  const container = useRef(null);

  useClick(container, isVisible, setIsVisible);

  let optionKeys = Object.keys(cardOptions).filter((item) => item !== "config");

  let hasAlert = false;
  if (cardOptions.config?.hasAlert)
    hasAlert = cardOptions.config.hasAlert(item);

  return (
    <>
      <div className="flex justify-end relative">
        {hasAlert && (
          <div className="absolute h-2 w-2 bg-red-600 rounded-full top-1 right-1"></div>
        )}
        <div
          ref={container}
          onClick={() => setIsVisible(!isVisible)}
          className="acoes w-[40px] h-[40px] bg-[#F4F4F4] rounded-[50%] flex flex-col items-center cursor-pointer"
        >
          <div className="w-auto top-[100%] h-auto mt-2 absolute z-10 overflow-y-auto right-0 pb-4">
            <div
              className={clsx(
                "rounded-lg border",
                "bg-white shadow-lg ring-1 ring-black ring-opacity-5",
                isVisible ? "" : "hidden"
              )}
            >
              <ul className="text-black cursor-pointer flex flex-col items-end min-w-36">
                {optionKeys.map((optionKey, index) => (
                  <CardOption
                    key={index}
                    optionKey={optionKey}
                    item={item}
                    setIsVisible={setIsVisible}
                    consulta={consulta}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardOptionContainer;
