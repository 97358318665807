import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useCallback } from "react";

import Input from "../../components/Input";
import Body from "../../components/Modal/body";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/Modal";
import Dropdown from "../../components/Dropdown";
import { useSubmit } from "../../hooks/useSubmit";
import { useDepartamentos } from "../../hooks/fetch";
import { timeApi, userApi } from "../../utility/api/";
import { selectUserToken } from "../../features/user";
import { CancelarButton } from "../../components/Cancelar/CancelarButton";
import Button, { buttonStyle } from "../../components/Button";
import DropdownSearch from "../../components/Dropdown/DropdownSearch";
import Loading from "../../components/Loading";
import { useTeam } from "../../hooks/fetch/useTeam";

const Editar = ({ teamId, setIsVisible, onFinished }) => {
  const { t } = useTranslation();

  const form = useForm();
  const { getValues } = form;

  const token = useSelector(selectUserToken);

  const { result: departamentos, isLoading: isDepartmentLoading } =
    useDepartamentos();
  const { result: team, isLoading: isTeamLoading } = useTeam(form, teamId);

  const prepareData = useCallback((data) => {
    return { ...data, users: data.users ? data.users : null };
  }, []);

  const { onSubmit, isLoading } = useSubmit({
    prepareData,
    endpoint: timeApi.update,
    form,
    onSuccess: onFinished,
  });

  if (isDepartmentLoading || isTeamLoading) {
    return <Loading />;
  }

  return (
    <Modal
      title={t("EDIT_TEAM")}
      onClose={() => setIsVisible(false)}
      form={form}
    >
      <Body>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid grid-rows-[repeat(4,_auto)] grid-flow-col gap-4"
        >
          <Dropdown
            label="Departamento"
            name="departmentId"
            readOnly
            form={form}
            options={departamentos}
            defaultValue={team.departmentId}
          />

          <Input
            name="description"
            label={t("NAME")}
            form={form}
            className="max-h-40"
            placeholder={t("PLACEHOLDER_TEAM")}
          />

          <div>
            <DropdownSearch
              placeholder={
                getValues("departmentId")
                  ? "Selecione..."
                  : "É necessário selecionar um departamento"
              }
              name="users"
              label="Vincule usuários ao time"
              listLabel="Usuários Vinculados"
              form={form}
              readOnly={!getValues("departmentId")}
              searchEndpoint={async (text) => {
                return userApi.getAllForTeam(
                  {
                    currentUsers: getValues("users"),
                    departmentId: getValues("departmentId"),
                    filter: text,
                    take: 10,
                  },
                  token
                );
              }}
              startEndpoint={async (users) =>
                userApi.startDropdownSearch(
                  {
                    teamId,
                  },
                  token
                )
              }
              configuration={{
                labelProp: "text",
                valueProp: "id",
                columns: [
                  {
                    label: "Nome",
                    prop: "text",
                  },
                ],
              }}
            />
          </div>

          <div className="mt-5 flex justify-between">
            <CancelarButton form={form} onReturn={() => setIsVisible(false)} />

            <Button
              style={buttonStyle.darkGreen}
              isLoading={isLoading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>
      </Body>
    </Modal>
  );
};
export default Editar;
