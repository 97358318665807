import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { selectModules } from "../features/user";
import { Navigate } from "react-router-dom";

export const functions = {
  category: "Categoria",
  team: "Time",
  ticket: "Chamados",
  department: "Departamento",
};

export const actions = {
  read: 0,
  update: 1,
  delete: 2,
  create: 3,
  active: 4,
  atender: 5,
  reprovar: 6,
  configurar: 7,
  finalizar: 8,
  retornar: 13,
  chat: 10,
  finalizarGestor: 12,
};

export const NotAllowed = () => {
  Swal.fire({
    title: "Não Autorizado!",
    icon: "error",
  });

  return <Navigate to="/" />;
};

export const useAuthorize = (_function, action) => {
  const modules = useSelector(selectModules);

  let module = modules.filter((module) => module.name === "SCI");

  if (!module.length) return false;

  let currentFunction = module[0].functions.filter(
    (item) => item.name === _function
  );

  if (!currentFunction.length) return false;

  let hasAction = currentFunction[0].actions.indexOf(action) >= 0;

  return hasAction;
};
