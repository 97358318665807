const pt = {
  ACTIVE: 'Ativo',
  ADD: 'Adicionar',
  ADD_CATEGORY: 'Adicionar Categoria',
  ADD_TEAM: 'Adicionar Time',
  ALREADY_EXISTS: 'The {{field}} field already exists.',
  ANALYST: 'Analista',
  ATTENTION: 'ATENÇÃO',
  ATTENTION_INFORMATION: 'Todos os campos com o símbolo "*" na frente, são obrigatórios o preenchimento.',

  CANCEL: 'Cancelar',
  CATEGORIES: 'Categorias',
  CATEGORY: "Categoria",
  CONCLUSION_DATE: "Data de conclusão",
  CREATION_DATE: "Data de criação",

  DEADLINE: 'Prazo',
  DELETE: 'Excluir',
  DEPARTAMENT: 'Departamento',
  DESCRIPTION: 'Descrição',
  DIVISION: 'Divisão',
  
  EDIT: 'Editar',
  EDIT_CATEGORY: 'Editar Categoria',
  EDIT_TEAM: 'Editar Time',
  EFFORT: 'Esforço',

  FILTER: 'Filtrar',
  FILTER_BY: 'Filtrar por',

  GRAVITY: 'Gravidade',

  INACTIVE: 'Inativo',

  LISTING: 'Listagem',

  NAME: 'Nome',
  NUMBER: 'Número',

  ORDER: 'Ordem',

  PLACEHOLDER_CATEGORY: 'Insira o nome da categoria',
  PLACEHOLDER_DEPARTAMENT: 'Pesquise pelo departamento',
  PLACEHOLDER_DROPDOWN: 'Selecione...',
  PLACEHOLDER_NAME: 'Pesquise pela descrição',
  PLACEHOLDER_TEAM: 'Insira o nome do time',
  PLACEHOLDER_TICKET: 'Insira a dica',

  REQUESTER: 'Solicitante',
  REQUIRED_FIELD_MESSAGE: 'O campo {{field}} é obrigatório.',
  FAIL: 'Reprovar',

  SAVE: 'Salvar',
  SCORE: 'Pontuação',
  SETOR: 'Tecnologia da Informação',
  STATUS: 'Status',
  SUBJECT: "Assunto",
  
  TEAM: 'Time',
  TEAMS: 'Times',
  TICKET_TIP: 'Dica do ticket',
  TICKET_INFORMATION: 'Informações para o ticket',
  
  URGENCY: 'Urgência',
};

module.exports = pt;
