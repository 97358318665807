import Header from "./Header";
import MenuLeft from "./MenuLeft";

import Loading from "../../components/Loading";
import { Outlet } from "react-router-dom";
import useRequireLogin from "../../hooks/useRequireLogin";
import { useEffect } from "react";

const Layout = () => {
  const { isLoading, redirectPath } = useRequireLogin();

  useEffect(() => {
    if (redirectPath) window.location.replace(redirectPath);
  }, [redirectPath]);

  if (isLoading) return <Loading />;
  if (redirectPath) return <></>;

  return (
    <div id="background-geral">
      <Header />
      <MenuLeft />
      <div className="pt-20 px-3 m-f9:pt-40 m-f9:pl-24 m-f9:px-12">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
