import { NavLink } from "react-router-dom";

const MenuImage = ({configMenu}) => {
    return (
        <div>
            {configMenu.map((menu, index) => (
                <div key={index} className="h-[100px] flex justify-center">
                    <button>
                        <NavLink to={menu.to && `${menu.to}`} onClick={menu.actionImage}>
                            <img alt={menu.alt} src={menu.icon} width={menu.width} height={menu.height} className="cursor-pointer" />
                        </NavLink>
                    </button>
                </div>
            ))}
        </div>
    );
}
export default MenuImage;