import { clsx } from "clsx";
import React, { useState, useRef, useCallback } from "react";

import iconDashBoard from "../../../assets/png/Home.png";
import iconModules from "../../../assets/png/modules.png";
import iconModulesBlue from "../../../assets/png/modules-blue.png";
import iconOpenMenu from "../../../assets/png/chevron-double-right.png";

import Modulo from "./Modulo";
import SubMenu from "./SubMenu";
import MenuText from "./MenuText";
import MenuImage from "./MenuImage";
import HeaderMenu from "./HeaderMenu";
import { useClick } from "../../../hooks/useClick";
import { useAuthorize, functions, actions } from "../../../hooks/useAthorize";
import { MobileMenu } from "../../../components/MobileMenu";

const MenuLeft = () => {
  const container = useRef(null);

  const [isAsideVisible, setAsideVisibility] = useState(false);
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [isSubMenuVisible, setSubMenuVisibility] = useState(false);

  const allowCategory = useAuthorize(functions.category, actions.read);
  const allowTeam = useAuthorize(functions.team, actions.read);
  const allowDepartment = useAuthorize(functions.department, actions.read);

  const configMenu = [
    {
      title: "Home",
      icon: iconDashBoard,
      to: "/",
      actionImage: () => {
        setMenuVisibility(false);
        setSubMenuVisibility(false);
      },
      alt: "",
      width: "",
      height: "",
    },
    {
      title: "Funcionalidades",
      icon: iconModules,
      to: false,
      alt: "",
      actionImage: () => {
        setMenuVisibility(true);
        setSubMenuVisibility(!isSubMenuVisible);
      },
      actionText: () => {
        setSubMenuVisibility(!isSubMenuVisible);
      },
      width: "",
      height: "",
    },
  ];

  const onClickOutside = useCallback(() => {
    setAsideVisibility(false);
    setMenuVisibility(false);
    setSubMenuVisibility(false);
  }, []);

  const toggleMobileVisibility = useCallback(() => {
    setAsideVisibility((state) => !state);

    setMenuVisibility((state) => (state ? !state : false));
    setSubMenuVisibility((state) => (state ? !state : false));
  }, []);

  useClick(
    container,
    isMenuVisible,
    setMenuVisibility,
    undefined,
    onClickOutside
  );

  return (
    <>
      <div
        ref={container}
        className={clsx(
          "transition-all fixed flex flex-row z-30 top-0 -left-12 m-f9:left-0",
          isAsideVisible && "!left-0"
        )}
      >
        <MobileMenu setter={toggleMobileVisibility} />
        <div className="bg-[#1B2646] z-30 h-screen w-12 left-0 pt-14">
          <div className="h-12 flex justify-center">
            <button
              className={clsx("hidden m-f9:block", isMenuVisible && "hidden")}
              onClick={() => {
                setMenuVisibility(!isMenuVisible);
              }}
            >
              <div>
                <img
                  alt="Abrir Menu Lateral"
                  src={iconOpenMenu}
                  className="min-w-[1.5rem] min-h-[1.5rem]"
                />
              </div>
            </button>

            <button
              className={clsx(
                "m-f9:hidden block transition-all duration-500",
                isSubMenuVisible && "rotate-180"
              )}
              onClick={() => {
                setSubMenuVisibility((subMenuVisibility) => !subMenuVisibility);
              }}
            >
              <div>
                <img
                  alt="Abrir Menu Lateral"
                  src={iconOpenMenu}
                  className="min-w-[1.5rem] min-h-[1.5rem]"
                />
              </div>
            </button>
          </div>
          <MenuImage configMenu={configMenu} />
        </div>
        <div
          className={clsx(
            "bg-[#1B2646] z-20 h-screen w-56 absolute left-12 duration-500 pt-14 hidden m-f9:block",
            !isMenuVisible && " -translate-x-full "
          )}
        >
          <HeaderMenu
            closeMenu={() => {
              setAsideVisibility(false);
              setMenuVisibility(false);
              setSubMenuVisibility(false);
            }}
          />
          <MenuText configMenu={configMenu} />
        </div>
        <SubMenu
          isVisibleSubMenuFuncionalidades={isSubMenuVisible}
          text="Funcionalidades"
          icon={iconModulesBlue}
        >
          <Modulo
            text="Chamados"
            link="/"
            onClick={() => {
              setAsideVisibility(false);
              setMenuVisibility(false);
              setSubMenuVisibility(false);
            }}
          />
          {allowDepartment && (
            <Modulo
              text="Departamentos"
              link="/Departamentos/Consulta"
              onClick={() => {
                setAsideVisibility(false);
                setMenuVisibility(false);
                setSubMenuVisibility(false);
              }}
            />
          )}
          {allowTeam && (
            <Modulo
              text="Times"
              link="/Times/Consulta"
              onClick={() => {
                setAsideVisibility(false);
                setMenuVisibility(false);
                setSubMenuVisibility(false);
              }}
            />
          )}
          {allowCategory && (
            <Modulo
              text="Categorias"
              link="/Categorias/Consulta"
              onClick={() => {
                setAsideVisibility(false);
                setMenuVisibility(false);
                setSubMenuVisibility(false);
              }}
            />
          )}
        </SubMenu>
      </div>
    </>
  );
};

export default MenuLeft;
