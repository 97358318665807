import clsx from "clsx";
import Button from "../Button";
import { useCancelar } from "./useCancelar";

export const CancelarButton = ({ form, onReturn, wide, className }) => {
  const { hasData, onCancelarClick } = useCancelar({
    form,
    onReturn,
  });

  return (
    <Button
      className={clsx(wide && "!w-[181px]", className)}
      type="button"
      onClick={onCancelarClick}
    >
      {hasData ? "Cancelar" : "Voltar"}
    </Button>
  );
};
