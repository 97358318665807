import { useSelector } from "react-redux";
import useFetch from "./useFetch";
import { divisaoApi } from "../../utility/api";
import { selectUser } from "../../features/user";

export const useCorpDivisions = () => {
  const user = useSelector(selectUser);
  
  const { result: corpDivisions } = useFetch({
    endpoint: divisaoApi.getCorpDivisions,
    data: null,
    prepareResult: null,
  });

  return user.isCorporate ? corpDivisions : [];
};
