const ChatDivisor = ({ date }) => {
  let today = new Date().toISOString().split("T")[0];
  let yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split("T")[0];

  if (date === today) date = "Hoje";
  else if (date === yesterday) date = "Ontem";
  else {
    date = new Date(date);

    let day = date
      .getDate()
      .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

    let month = (date
      .getMonth() + 1)
      .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

    date = `${day}/${month}/${date.getUTCFullYear()}`;
  }

  return (
    <div className="flex flex-row items-center">
      <div className="w-[45%] border-b-[1px] border-[#B3B3B3]" />
      <div className="flex-auto text-center font text-[10px] text-stone-400">
        {date}
      </div>
      <div className="w-[45%] border-b-[1px] border-[#B3B3B3]" />
    </div>
  );
};

export default ChatDivisor;
