import { useMemo } from "react";
import { actions, functions, useAuthorize } from "../../../hooks/useAthorize";

const useDropdownOptions = () => {
  const allowAtendimento = useAuthorize(functions.ticket, actions.atender);

  const filterOptions = useMemo(() => {
    if (allowAtendimento)
      return [
        { id: 0, text: "Número" },
        { id: 1, text: "Assunto" },
        { id: 2, text: "Solicitante" },
        { id: 4, text: "Data de Criação" },
        { id: 5, text: "Prazo" },
        { id: 6, text: "Data de Conclusão" },
        { id: 7, text: "Departamento" },
      ];

    return [
      { id: 3, text: "Categoria" },
      { id: 6, text: "Data de Conclusão" },
      { id: 7, text: "Departamento" },
    ];
  }, [allowAtendimento]);

  const deadlineFilterOptions = useMemo(
    () => [
      { id: "1", text: "Dentro do prazo" },
      { id: "2", text: "Próximo ao vencimento" },
      { id: "3", text: "Em atraso" },
      { id: "0", text: "Não configurado" },
    ],
    []
  );

  const statusOptions = useMemo(
    () => [
      { id: "0", text: "Reprovado" },
      { id: "1", text: "Finalizado" },
      { id: "2", text: "Em Atendimento" },
      { id: "3", text: "Em Análise" },
      { id: "4", text: "Visualizado" },
    ],
    []
  );

  const orderOptions = useMemo(
    () => [
      { id: 0, text: "Decrescente" },
      { id: 1, text: "Crescente" },
    ],
    []
  );

  return {
    filterOptions,
    deadlineFilterOptions,
    statusOptions,
    orderOptions,
  };
};

export default useDropdownOptions;
