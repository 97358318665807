import { throttle } from "lodash";
import { configureStore } from "@reduxjs/toolkit";

import usersReducer from "./user";
import paginationReducer from "./pagination";
import { saveState, loadState } from "./localStorage";

const storageName = "sci-local-storage";

const store = configureStore({
  reducer: {
    user: usersReducer,
    pagination: paginationReducer,
  },
  preloadedState: loadState(storageName),
});

store.subscribe(throttle(() => saveState(store.getState(), storageName), 1000));

export default store;
