import { useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import EditarCategoria from "./EditarCategoria";
import Consulta from "../../components/Consulta";
import { categoriaApi } from "../../utility/api/";
import { selectUserToken } from "../../features/user";
import AdicionarCategoria from "./AdicionarCategoria";
import useConsulta from "../../components/Consulta/useConsulta";
import {
  useAuthorize,
  functions,
  actions,
  NotAllowed,
} from "../../hooks/useAthorize";
import useDownloadFile from "../../hooks/useDownloadFile";

const ConsultaCategorias = () => {
  const token = useSelector(selectUserToken);
  const { t } = useTranslation();

  const allowRead = useAuthorize(functions.category, actions.read);
  const allowCreate = useAuthorize(functions.category, actions.create);
  const allowEdit = useAuthorize(functions.category, actions.update);
  const allowDelete = useAuthorize(functions.category, actions.delete);
  const allowActive = useAuthorize(functions.category, actions.active);

  const filtros = [
    {
      label: "Departamento",
      placeholder: "Pesquise pelo departamento",
      name: "department",
    },
    {
      label: "Descrição",
      placeholder: "Pesquise pela descrição",
      name: "description",
    },
  ];

  const colunas = [
    {
      label: "Departamento",
      prop: "department",
      width: "25%",
    },
    {
      label: t("DESCRIPTION"),
      prop: "description",
      width: "45%",
    },
    {
      label: t("DIVISION"),
      prop: "divison",
      width: "10%",
    },
  ];

  let config = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: categoriaApi.getListPaged,
      },
    };
  }, []);

  const cardOptions = useMemo(() => {
    return {
      config: {
        hasAlert: (item) => item.hasAlert,
      },
      editar: {
        label: t("EDIT"),
        isVisible: () => allowEdit,
        action: (item) => editCategoryId(item.id),
      },
      ativar: {
        label: "Ativar",
        isVisible: (item) => !item.status && allowActive,
        questionText: "Tem certeza que deseja ativar?",
        fetchFunction: (id, token) => categoriaApi.activate(id, token),
      },
      inativar: {
        label: "Inativar",
        isVisible: (item) => item.status && allowActive,
        questionText: "Tem certeza que deseja inativar?",
        fetchFunction: (id, token) => categoriaApi.inactivate(id, token),
      },
      excluir: {
        label: "Excluir",
        isVisible: (item) => item.isDeletable && allowDelete,
        questionText: "Tem certeza que deseja excluir?",
        successText: "Sucesso",
        fetchFunction: (id, token) => categoriaApi.delete(id, token),
      },
    };
  }, [allowActive, allowDelete, allowEdit, t]);

  const consulta = useConsulta({ token, config, cardOptions });
  const [showAddCategoria, setShowAddCategoria] = useState(false);
  const [categoryId, editCategoryId] = useState(undefined);

  const onFinished = useCallback(() => {
    consulta.onSearch();
    setShowAddCategoria(false);
    editCategoryId(undefined);
  }, [consulta]);

  const download = useDownloadFile("/Category/ExportExcel", "Categorias.xlsx");

  if (!allowRead) return <NotAllowed />;

  return (
    <div className="w-full min-h-screen">
      <Consulta
        title={t("CATEGORIES")}
        onAdd={allowCreate ? () => setShowAddCategoria(true) : undefined}
        colunas={colunas}
        filtros={filtros}
        consulta={consulta}
        download={download}
      />

      {showAddCategoria && allowCreate && (
        <AdicionarCategoria
          setIsVisible={setShowAddCategoria}
          onFinished={onFinished}
        />
      )}

      {categoryId && allowEdit && (
        <EditarCategoria
          categoryId={categoryId}
          setIsVisible={editCategoryId}
          onFinished={onFinished}
        />
      )}
    </div>
  );
};

export default ConsultaCategorias;
