import { useCallback } from "react";
import { timeApi } from "../../utility/api";
import useFetch from "./useFetch";

export const useTeam = (form, teamId) => {
  const data = useCallback(() => teamId, [teamId]);

  const prepareReset = useCallback(
    (result) => {
      return {
        id: teamId,
        ...result.data,
      };
    },
    [teamId]
  );

  return useFetch({
    endpoint: timeApi.getById,
    data,
    form,
    doReset: true,
    prepareReset
  });
};
