import { useCallback } from "react";
import { useRef, useState, useEffect } from "react";
import Swal from "sweetalert2";

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export function customJoin(arr, defaultSeparator, lastSeparator) {
  return arr
    .slice(0, -1)
    .join(defaultSeparator)
    .concat(arr.length > 1 ? lastSeparator : "", arr.slice(-1));
}

export const validateFile = (file, validExtensions, maxSize) => {
  if (validExtensions) {
    let extension = "." + file.name.split(".").at(-1);

    if (!validExtensions.includes(extension.toLowerCase()))
      return {
        isValid: false,
        message: `O arquivo "${
          file.name
        }" tem um formato ou a extensão do arquivo não é válida. ${"\n"}
        Formatos válidos: ${customJoin(validExtensions, ", ", " e ")}`,
      };
  }

  if (maxSize) {
    if (file.size > maxSize) {
      return {
        isValid: false,
        message: `O arquivo "${
          file.name
        }" é maior que o limite de ${humanFileSize(maxSize)}`,
      };
    }
  }

  return { isValid: true, message: undefined };
};

const useFileContainer = ({
  form,
  name,
  validExtensions,
  maxFileQuantity,
  maxSize,
}) => {
  const [arquivos, setArquivos] = useState([]);
  const fileInputRef = useRef(null);

  const { watch, setValue } = form ?? {};

  const showError = (text) => {
    Swal.fire({
      icon: "error",
      title: "Erro!",
      text: text,
    });
  };

  const adicionarArquivos = useCallback(
    (files) => {
      let temp = [...arquivos];

      if (temp.length + files.length > maxFileQuantity){
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `Quantidade de arquivos excede o permitido. Quantidade permitida é de ${maxFileQuantity} arquivos.`,
        });
        return;
      }

      for (let file of files) {
        let { isValid, message } = validateFile(file, validExtensions, maxSize);

        if (!isValid) {
          showError(message);
          return;
        }

        temp.push(file);
      }

      if (form && name) setValue(name, temp);

      fileInputRef.current.value = "";
      setArquivos(temp);
    },
    [arquivos, form, maxFileQuantity, maxSize, name, setValue, validExtensions]
  );

  const removerArquivo = useCallback(
    (index) => {
      let temp = [...arquivos];
      temp.splice(index, 1);
      setArquivos(temp);
    },
    [arquivos]
  );

  const openFileSearchModal = useCallback(() => {
    if (fileInputRef) fileInputRef.current?.click();
  }, []);

  useEffect(() => {
    const subscription = watch?.((_, { name, type }) => {
      if (name === undefined && type === undefined) setArquivos([]);
    });

    return () => subscription.unsubscribe?.();
  }, [watch]);

  return {
    fileInputRef,
    arquivos,
    adicionarArquivos,
    removerArquivo,
    openFileSearchModal,
  };
};

export default useFileContainer;
