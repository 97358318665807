import { useCallback, useEffect, useState } from "react";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import { filterType } from "../typing";

const Filtro = ({ filtro, form }) => {
  const [options, setOptions] = useState([]);

  const fetch = useCallback(async () => {
    let result = await filtro.fetch();

    if (result.hasError) return;

    setOptions(result.data);
  }, [filtro]);

  useEffect(() => {
    if (filtro.fetch) fetch();
  }, [fetch, filtro.fetch]);

  if (!filtro.hasOwnProperty("isVisible")) filtro.isVisible = true;

  let content = undefined;

  if (filtro.element) {
    content = filtro.element(form);
  } else if (!filtro.type || filtro.type === filterType.input) {
    content = (
      <Input
        label={filtro.label}
        placeholder={filtro.placeholder}
        form={form}
        name={filtro.name}
        ref={filtro.ref}
      />
    );
  } else if (filtro.type === filterType.select) {
    content = (
      <Dropdown
        label={filtro.label}
        placeholder={filtro.placeholder}
        name={filtro.name}
        form={form}
        options={options}
        fetch={filtro.fetch}
        onChange={filtro.onChange}
        ref={filtro.ref}
      />
    );
  }

  return (
    <div className={`flex ${filtro.isVisible ? "" : "hidden"}`}>
      <div className="w-full flex flex-col">{content}</div>
    </div>
  );
};

export default Filtro;
