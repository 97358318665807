import { useMemo, useEffect, useCallback } from "react";

const useScore = (form) => {
  const onScoreChanged = useCallback(() => {
    const gravity = form.getValues("gravity");
    const urgency = form.getValues("urgency");

    let score = gravity * urgency;

    if (isNaN(score)) score = 0;

    form.setValue("score", score);
  }, [form]);

  useEffect(() => {
    const subscription = form.watch((_, { name }) => {
      if (name === "gravity" || name === "urgency") onScoreChanged();
    });
    return () => subscription.unsubscribe();
  }, [form, onScoreChanged]);

  const nivelOptions = useMemo(
    () => [
      { text: "Baixo", id: "1" },
      { text: "Médio", id: "2" },
      { text: "Alto", id: "3" },
    ],
    []
  );

  return { nivelOptions };
};

export default useScore;
