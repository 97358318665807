import clsx from "clsx";
import { useState } from "react";
import { getI18n } from "react-i18next";
import Arrow from "../../../../assets/svg/arrow.svg";

const Dropdown = ({ userImagem, user }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectPT, isSelectPT] = useState(true);
  const [selectUS, isSelectUS] = useState(false);
  const [selectES, isSelectES] = useState(false);

  return (
    <div className="flex flex-col">
      <button
        type="button"
        className="flex relative justify-center mr-5 cursor-pointer m-f1:mr-0"
        onClick={() => setIsVisible(!isVisible)}
        onBlur={() => setIsVisible(false)}
      >
        <div className="flex m-f1:py-2 t-f1:py-1">
          <div className="rounded-full overflow-hidden border-2 relative mr-5 m-f1:w-8 m-f1:h-8 m-f1:mr-3 t-f1:h-12 t-f1:w-12">
            <img
              alt="Sua Foto"
              src={userImagem}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full max-w-none"
            />
          </div>
          <div className="flex flex-col text-left justify-center m-f1:hidden t-f1:flex">
            <h5 className="p-0 text-lg font-medium leading-5 whitespace-nowrap text-ellipsis max-w-sm overflow-hidden">
              {user.data.name}
            </h5>
            <span className="text-xs whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis">
              {user.data.departments
                .filter((d) => d.departmentDefault)
                .map((d) => d.departmentName)
                .join(", ") || "Padrão"}
            </span>
          </div>
          <div className="h-full ml-2 flex items-start py-[10px] m-f1:hidden t-f1:flex">
            <div
              className={clsx(
                "flex transition-all",
                isVisible ? "rotate-0" : "rotate-180"
              )}
            >
              <img alt="Flecha" src={Arrow} />
            </div>
          </div>
        </div>
        {isVisible && (
          <div className="absolute top-full left-0 w-[200px] mt-4 rounded-md bg-white shadow-lg z-10 ring-1 ring-black ring-opacity-5 m-f1:hidden t-f1:flex">
            <ul className={`w-full`}>
              <li className="flex items-center text-[#676767] px-4 py-2 text-[14px] border-b cursor-default">
                <div className="max-w-[25px] max-h-[25px] border rounded-[50%] mr-3">
                  <img alt="Sua Foto" src={userImagem} />
                </div>
                <span className="text-left">{user.data.name}</span>
              </li>

              <li
                className="hidden justify-around px-5 py-3 border-b hover:bg-slate-100 cursor-pointer"
                onClick={() => {
                  setIsVisible(false);
                }}
              >
                <div
                  id="portugues"
                  className={clsx(
                    "w-6 h-6 rounded-[50%] cursor-pointer",
                    selectPT ? "border-2 border-[#005BBF]" : "border-0"
                  )}
                  onClick={() => {
                    isSelectPT(true);
                    isSelectUS(false);
                    isSelectES(false);
                    getI18n().changeLanguage("pt");
                  }}
                ></div>
                <div
                  id="ingles"
                  className={clsx(
                    `w-6 h-6 rounded-[50%] cursor-pointer`,
                    selectUS ? "border-2 border-[#005BBF]" : "border-0"
                  )}
                  onClick={() => {
                    isSelectUS(true);
                    isSelectPT(false);
                    isSelectES(false);
                    getI18n().changeLanguage("en");
                  }}
                ></div>
                <div
                  id="espanhol"
                  className={clsx(
                    `w-6 h-6 rounded-[50%] cursor-pointer`,
                    selectES ? "border-2 border-[#005BBF]" : "border-0"
                  )}
                  onClick={() => {
                    isSelectES(true);
                    isSelectUS(false);
                    isSelectPT(false);
                    getI18n().changeLanguage("es");
                  }}
                ></div>
              </li>
            </ul>
          </div>
        )}
      </button>
    </div>
  );
};

export default Dropdown;
