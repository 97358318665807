import clsx from "clsx";
import { v4 as newGuid } from "uuid";
import { useSelector } from "react-redux";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import { Textbox } from "./components/Textbox";
import { useChat } from "./hooks/useChat";
import Message from "./components/Message";
import { group } from "../../../utility/util";
import HeaderChat from "./components/HeaderChat";
import ChatDivisor from "./components/ChatDivisor";
import ChatLoading from "./components/ChatLoading";
import { useFileUpload } from "./hooks/useFileUpload";
import { selectUser, selectUserToken } from "../../../features/user";
import { Modal } from "../../../components/Modal";

const Chat = ({ onClose, ticket }) => {
  const user = useSelector(selectUser);
  const token = useSelector(selectUserToken);

  const maxLength = 200;

  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);

  const [hasToScrollBottom, setHasToScrollBottom] = useState(false);

  const onReceiveJoinMessage = useCallback(() => {
    setHasToScrollBottom(true);
  }, []);

  const { connection, messages, setMessages, hasLoadedAll } = useChat({
    ticket,
    token,
    onReceiveJoinMessage,
  });

  // Esse useEffect e o state hasScroll são necessários para
  // manter a margem das mensagens alinhadas com o input.
  // De outra forma a variável hasScroll não é atualizada
  // quando necessário.
  useEffect(() => {
    setHasScroll(
      containerRef.current?.scrollHeight > containerRef.current?.clientHeight
    );

    if (hasToScrollBottom) {
      containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
      setHasToScrollBottom(false);
    }
  }, [hasToScrollBottom, messages]);

  const onSend = useCallback(async () => {
    let text = inputRef.current.value.trim();

    if (!text || text.length === 0) return;
    if (text.length > maxLength) return;

    inputRef.current.value = null;

    const id = newGuid();

    let message = {
      id: id,
      message: text,
      date: new Date(),
      userSenderId: user.userId,
      userName: user.name,
    };

    setMessages((array) => [...array, message]);

    setHasToScrollBottom(true);

    try {
      await connection.invoke("SendMessage", ticket.id, text, token);
    } catch (err) {
      console.error(err);

      message.hasError = true;
      setMessages((array) => [
        ...array.map((a) => (a.id === id ? message : a)),
      ]);
    }
  }, [connection, setMessages, ticket.id, token, user.userId, user.name]);

  const onKeyUp = useCallback(
    (ev) => {
      if (ev.code === "NumpadEnter" || ev.code === "Enter") {
        onSend();
      }
    },
    [onSend]
  );

  const onScrollTop = useCallback(async () => {
    if (!hasLoadedAll) {
      if (containerRef.current.scrollTop === 0) {
        if (connection?._connectionStarted) {
          setIsLoading(true);

          await connection.invoke(
            "GetMoreMessages",
            ticket.id,
            messages.length,
            token
          );

          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }
      }
    }
  }, [connection, hasLoadedAll, messages.length, ticket.id, token]);

  // Esse useEffect é utilizado para adicionar o evento
  // de scroll ao container de mensagens
  useEffect(() => {
    let container = containerRef.current;

    if (container) {
      container.addEventListener("scroll", onScrollTop);
    }

    return () => {
      container.removeEventListener("scroll", onScrollTop);
    };
  }, [containerRef, onScrollTop]);

  // Essa função agrupa as mensagens de acordo com o dia que foram
  // enviadas, fazendo com que o ChatDivisor seja atualizado
  // automaticamente
  const groupedMessages = useMemo(
    () =>
      group(messages, (item) => {
        let date = new Date(item.date);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      }),
    [messages]
  );

  const orderedDates = useMemo(
    () =>
      Object.keys(groupedMessages)
        .map((key) => new Date(key))
        .sort((a, b) => a - b),
    [groupedMessages]
  );

  const orderByDateDesc = (a, b) => {
    if (a.date < b.date) {
      return -1;
    } else if (a.date > b.date) {
      return 1;
    }

    return 0;
  };

  const { inputFileRef, onAttachmentClick, onInputFileChanged } = useFileUpload(
    {
      ticket,
      token,
      setMessages,
      connection,
      user,
      setHasToScrollBottom,
    }
  );

  return (
    <Modal
      title={"Chat"}
      onClose={async () => {
        onClose?.();
        await connection.invoke("LeaveChat", ticket.id);
        await connection.stop();
      }}
      className="m-f9:w-1/3"
      containerClassName="!-mx-[0.75rem] relative"
      background="bg-[#f8f8f8]"
    >
      <input
        type="file"
        className="hidden"
        ref={inputFileRef}
        onChange={onInputFileChanged}
      />

      <HeaderChat title="Chat" ticket={ticket} />
      <div
        ref={containerRef}
        className={clsx(
          "bg-[#f8f8f8] overflow-auto m-f9:h-[calc(100vh_-_300px)] h-[calc(100vh_-_265px)] w-full relative",
          hasScroll ? "pl-4" : "p-4"
        )}
      >
        {isLoading ? (
          <ChatLoading />
        ) : (
          orderedDates.map((date, divisorIndex) => (
            <React.Fragment key={divisorIndex}>
              <ChatDivisor date={date} />
              {groupedMessages[date]
                .sort(orderByDateDesc)
                .map((message, index) => (
                  <Message key={index} {...message} />
                ))}
            </React.Fragment>
          ))
        )}
      </div>
      <Textbox
        maxLength={maxLength}
        ticket={ticket}
        onAttachmentClick={onAttachmentClick}
        inputRef={inputRef}
        onKeyUp={onKeyUp}
        onSend={onSend}
      />
    </Modal>
  );
};

export default Chat;
