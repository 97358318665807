import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import UserDropdown from "./UserDropdown";
import Input from "../../../components/Input";
import Dropdown from "../../../components/Dropdown";
import { useSubmit } from "../../../hooks/useSubmit";
import { Validator } from "../../../utility/validations";
import FileContainer from "../../../components/FileContainer";
import { categoriaApi, ticketApi } from "../../../utility/api/";
import Button, { buttonStyle } from "../../../components/Button";
import { useTicketDepartments, useCategories } from "../../../hooks/fetch";
import { selectUser, selectUserToken } from "../../../features/user";
import { useAuthorize, functions, actions } from "../../../hooks/useAthorize";
import useFileContainer from "../../../components/FileContainer/useFileContainer";
import Loading from "../../../components/Loading";
import Label from "../../../components/Label";
import { Modal } from "../../../components/Modal";
import { CancelarButton } from "../../../components/Cancelar/CancelarButton";
import { useRef } from "react";

const validExtensions = [
  ".xlsx",
  ".docx",
  ".pptx",
  ".pdf",
  ".jpeg",
  ".jpg",
  ".png",
  ".txt",
  ".mp4",
  ".avi",
  ".mpeg",
  ".mpg",
];

const AbrirChamado = ({ setIsVisible, onFinished }) => {
  const token = useSelector(selectUserToken);
  const user = useSelector(selectUser);

  const allowUserChange = useAuthorize(functions.ticket, actions.atender);

  const [isDescriptionLoading, setIsDescriptionLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      userId: user.userId,
    },
  });

  const { setValue, trigger } = form;

  const { result: departments, isLoading: isDepartamentosLoading } =
    useTicketDepartments(form);

  const { result: categories, isLoading: isCategoriesLoading } = useCategories({
    form,
  });

  const maxSize = 2 * 10 ** 9; // 2Gb

  const {
    fileInputRef,
    arquivos,
    adicionarArquivos,
    removerArquivo,
    openFileSearchModal,
  } = useFileContainer({
    form,
    name: "files",
    validExtensions,
    maxFileQuantity: 5,
    maxSize,
  });

  const onSuccess = useCallback(
    (result) => {
      onFinished?.();
      setIsVisible(false);
    },
    [onFinished, setIsVisible]
  );

  const prepareData = useCallback(
    (data) => {
      const formData = new FormData();
      let keys = Object.keys(data);

      for (let key of keys) {
        formData.append(key, data[key]);
      }

      for (let file of arquivos) formData.append("files", file);

      return formData;
    },
    [arquivos]
  );

  const successMessage = useCallback((result) => {
    const ticket = result.data.data;
    return (
      <>
        <p>Cadastrado com sucesso</p>
        <p>
          Chamado número: <span className="font-bold">#{ticket}</span>
        </p>
      </>
    );
  }, []);

  const { onSubmit, isLoading } = useSubmit({
    endpoint: ticketApi.create,
    successMessage,
    onSuccess,
    form,
    prepareData,
  });

  const fetchCategory = useCallback(async () => {
    setIsDescriptionLoading(true);
    let categoryId = form.getValues("categoryId");

    let result = await categoriaApi.getById(categoryId, token);
    setIsDescriptionLoading(false);

    if (result.hasError) return;

    setValue("description", result.data.ticketDescription);
    trigger("description");
  }, [form, setValue, token, trigger]);

  useEffect(() => {
    const subscription = form.watch((_, { name }) => {
      if (name === "categoryId") fetchCategory();
    });
    return () => subscription.unsubscribe();
  }, [form, fetchCategory]);

  let userInput = <Input readOnly defaultValue={user.name} />;

  if (allowUserChange) {
    userInput = <UserDropdown form={form} />;
  }

  const categoryDropdownRef = useRef(null);

  const requireCategory = useCallback(() => {
    return categoryDropdownRef?.current?.value
      ? null
      : `Para preencher o campo "Descrição" é necessário selecionar uma categoria`;
  }, []);

  if (isDepartamentosLoading || isCategoriesLoading) {
    return <Loading />;
  }

  return (
    <Modal
      title="Adicionar Chamado"
      onClose={() => setIsVisible(false)}
      form={form}
    >
      {isDescriptionLoading && <Loading />}
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-wrap gap-3"
      >
        <div className="order-1 w-full">{userInput}</div>

        <div className="order-2 w-full m-f9:w-[calc(50%_-_0.375rem)]">
          <Dropdown
            name="departmentId"
            form={form}
            options={departments}
            label="Departamento"
            placeholder="Selecione..."
            required
          />
        </div>

        <div className="order-3 w-full m-f9:w-[calc(50%_-_0.375rem)]">
          <Dropdown
            name="categoryId"
            form={form}
            ref={categoryDropdownRef}
            options={categories}
            label="Categoria"
            placeholder="Selecione..."
            required
          />
        </div>

        <div className="order-3 w-full">
          <Input
            name="subject"
            label="Assunto"
            placeholder="Digite um assunto..."
            form={form}
            className="max-h-40"
            multiline
            required
          />
        </div>

        <div className="order-3 w-full">
          <Input
            name="description"
            label="Descrição"
            form={form}
            className="max-h-40"
            placeholder="Digite uma descrição..."
            multiline
            disabled={!categoryDropdownRef?.current?.value}
            validate={(text) =>
              new Validator.Builder()
                .customValidation(requireCategory)
                .required()
                .build()
                .validate(text)
            }
          />
        </div>

        <div className="order-4 m-f9:flex w-full grid grid-cols-2 gap-2 justify-between">
          <div className="">
            <Input
              name="occuredTime"
              label="Hora do Ocorrido"
              form={form}
              placeholder="00:00"
              mask={{
                mask: "__:__",
                replacement: { _: /\d/ },
              }}
              validate={(text) => {
                return new Validator.Builder()
                  .validTime()
                  .build()
                  .validate(text);
              }}
            />
          </div>
          <div className="flex flex-col">
            <Label>Adicionar Arquivos</Label>
            <Button
              style={buttonStyle.darkBlue}
              onClick={() => openFileSearchModal()}
              className="min-w-0 leading-4 h-11 w-full"
            >
              Procurar Arquivo
            </Button>
          </div>
        </div>

        <div className="order-5 w-full">
          <FileContainer
            arquivos={arquivos}
            adicionarArquivos={adicionarArquivos}
            removerArquivo={removerArquivo}
            fileInputRef={fileInputRef}
          />
        </div>

        <div className="order-6 w-full m-f9:flex justify-between col-span-2 grid grid-cols-2 gap-10">
          <CancelarButton
            form={form}
            onReturn={() => setIsVisible(false)}
            className="w-auto min-w-0 m-f9:min-w-[11rem] h-auto py-2"
          />

          <div className="flex justify-center">
            <Button
              style={buttonStyle.darkGreen}
              type="submit"
              isLoading={isLoading}
              className={clsx(
                "min-w-0 m-f9:min-w-[11rem] h-auto py-2",
                isLoading ? "w-auto" : "w-full"
              )}
            >
              Salvar
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AbrirChamado;
