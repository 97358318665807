import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Arrow from "../../../assets/svg/arrow.svg";
import Loading from "../../../components/Loading";
import { useClick } from "../../../hooks/useClick";
import { accountApi } from "../../../utility/api/";
import {
  selectUserDivisionId,
  selectUserDivisions,
  selectUserToken,
  userloggedIn,
  selectCurrentUserDivision,
} from "../../../features/user";
import clsx from "clsx";

export const DivisionDropdown = () => {
  const divisions = useSelector(selectUserDivisions);
  const userDivisionId = useSelector(selectUserDivisionId);
  const [showLoading, setShowLoading] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(selectUserToken);

  const [isVisibleDivision, setIsVisibleDivision] = useState(false);
  const currentDivision = useSelector(selectCurrentUserDivision);

  const divisionDropDownRef = useRef(null);
  useClick(divisionDropDownRef, isVisibleDivision, setIsVisibleDivision);

  const onDivisionChange = useCallback(
    async (divisionId) => {
      if (!divisionId) return;
      if (userDivisionId === divisionId) return;

      setShowLoading(true);

      let result = await accountApi.changeDivision({ divisionId }, token);

      if (!result.hasError) dispatch(userloggedIn(result.data));

      setTimeout(() => {
        setShowLoading(false);
      }, 2000);
    },
    [dispatch, token, userDivisionId]
  );

  let isClickable = divisions.length > 1;

  return (
    <>
      {showLoading && <Loading />}
      <div
        ref={divisionDropDownRef}
        className={clsx(
          "border border-[#686868] relative rounded-md px-3 ml-2 mt-[1px] flex text-[18px] font-semibold text-[#1B2646] items-center",
          isClickable && "cursor-pointer"
        )}
        onClick={() => {
          if (isClickable) setIsVisibleDivision(!isVisibleDivision);
        }}
      >
        <div className="bg-[#1B2646] w-[6px] h-[6px] rounded-full ml-1 mr-2"></div>
        <span className="mr-2">{currentDivision.name}</span>

        {isClickable && (
          <div
            className={`flex transition-all ${
              isVisibleDivision ? "rotate-0" : "rotate-180"
            }`}
          >
            <img alt="Flecha" src={Arrow} />
          </div>
        )}

        {isVisibleDivision && (
          <div className="absolute left-0 top-5 w-full">
            <div className="mr-5 w-full mt-3">
              <div
                className={`max-h-[200px] rounded-md bg-white ring-1 ring-black ring-opacity-5 shadow-lg overflow-y-auto`}
              >
                <ul>
                  {divisions.map((item, index) => (
                    <li
                      key={index}
                      className={`${
                        userDivisionId === item.id
                          ? "bg-[#187733] text-white"
                          : "text-gray-700 hover:bg-slate-100"
                      } block px-4 py-3 text-sm border-b cursor-pointer `}
                      onClick={() => {
                        onDivisionChange(item.id);
                      }}
                    >
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
