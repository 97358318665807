import "tippy.js/dist/tippy.css";
import Tippy from "@tippyjs/react";

const CardTooltip = ({
  item,
  text,
  configuration: { customBody, background },
  children,
}) => {
  if (customBody) {
    let body = customBody(item);

    if (body) return body;
  }

  if (!text) return <></>;

  return (
    <Tippy content={text} className="block">
      <span className={`cursor-pointer ${background}`}>{children}</span>
    </Tippy>
  );
};

export default CardTooltip;
