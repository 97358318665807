import api from "./api";

export const satisfactionSurveyApi = {
  create: async (data) => {
    return api.post(`/SatisfactionSurvey/Create`, data);
  },
  get: async (data) => {
    return api.get(`/SatisfactionSurvey/GetByHash`, data);
  },
};
