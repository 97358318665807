import Tippy from "@tippyjs/react";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ticketDeadlineStatus, ticketStatus } from "../../../utility/enums";
import Alert from "../../../assets/png/alert.png";
import moment from "moment/moment";

export const getStatusBody = (item) => {
  let background = "";

  if (item.statusCode === ticketStatus.reproved)
    background = "bg-[#AF0000] text-white";
  else if (item.statusCode === ticketStatus.closed)
    background = "bg-[#000000] text-white";
  else if (item.statusCode === ticketStatus.attending)
    background = "bg-[#187733] text-white";
  else if (item.statusCode === ticketStatus.analyzing)
    background = "bg-[#9B9B9B] text-white";
  else if (item.statusCode === ticketStatus.configured)
    background = "bg-[#005BBF] text-white";

  background = clsx(
    "!text-white rounded-md text-center py-1 px-3 text-xs block w-min",
    background
  );

  return (
    <div className="flex items-center pr-3 whitespace-nowrap">
      <span className={`mr-1 ${background}`}>{item.status}</span>
    </div>
  );
};

const useColumns = () => {
  const { t } = useTranslation();

  const getDeadlineBackground = useCallback((item) => {
    if (item.statusCode === ticketStatus.reproved) return;
    if (item.statusCode === ticketStatus.closed) return;

    let bg = "#9B9B9B";

    if (item.deadlineStatus === ticketDeadlineStatus.onTime)
      bg = "bg-[#187733]";
    else if (item.deadlineStatus === ticketDeadlineStatus.almostLate)
      bg = "bg-[#FFFF00] !text-black";
    else if (item.deadlineStatus === ticketDeadlineStatus.late)
      bg = "bg-[#AF0000]";

    return clsx(
      "!text-white rounded-md text-center py-1 px-3 text-xs block w-min",
      bg
    );
  }, []);

  const getDeadlineBody = useCallback(
    (item) => {
      let text = "Não configurado";
      let background = getDeadlineBackground(item);
      const isReproved = item.statusCode === ticketStatus.reproved;
      const isFinished = item.statusCode === ticketStatus.closed;

      if (isReproved || isFinished) text = null;
      else if (item.deadlineStatus === ticketDeadlineStatus.onTime)
        text = "Em dia";
      else if (item.deadlineStatus === ticketDeadlineStatus.almostLate)
        text = "Próximo ao vencimento";
      else if (item.deadlineStatus === ticketDeadlineStatus.late)
        text = "Em atraso";

      return (
        <div className="flex items-center pr-3">
          <span className={`mr-1 ${background}`}>{item.deadline}</span>
          {text && (
            <Tippy content={text} className="block">
              <img src={Alert} alt="Informação" className="cursor-pointer" />
            </Tippy>
          )}
        </div>
      );
    },
    [getDeadlineBackground]
  );

  const customFinishBody = useCallback((item) => {
    let content = item.closedDate;

    let text = null;
    const isFinished = item.statusCode === ticketStatus.closed;
    const isReproved = item.statusCode === ticketStatus.reproved;

    if (isFinished) {
      let deadline = moment(item.deadline, "DD/MM/YYYY").toDate();
      let closedDate = moment(item.closedDate, "DD/MM/YYYY").toDate();

      if (deadline < closedDate) text = "Finalizado em Atraso";
    } else if (isReproved) {
      content = item.denyDate;
    }

    return (
      <div className="flex items-center pr-3">
        <span className={`mr-1`}>{content}</span>
        {text && (
          <Tippy content={text} className="block">
            <img src={Alert} alt="Informação" className="cursor-pointer" />
          </Tippy>
        )}
      </div>
    );
  }, []);

  const colunas = useMemo(
    () => [
      {
        label: t("NUMBER"),
        prop: "number",
        className: "order-4 w-[33%] m-f9:w-[10%] m-f9:order-1",
      },
      {
        label: t("SUBJECT"),
        prop: "subject",
        className: "order-1 w-[80%] m-f9:w-[30%] m-f9:order-2",
      },
      {
        label: t("REQUESTER"),
        prop: "keyUserName",
        className: "order-10 w-[33%] m-f9:w-[15%] m-f9:order-3",
      },
      {
        label: t("DIVISION"),
        prop: "divisionName",
        className: "order-10 w-[33%] m-f9:w-[15%] m-f9:order-3",
      },
      {
        label: "Departamento",
        prop: "departmentDescription",
        className: "order-2 w-[66%] m-f9:flex m-f9:w-[15%] m-f9:order-4",
      },
      {
        label: t("CATEGORY"),
        prop: "category",
        className: "order-5 w-[33%] m-f9:w-[15%] m-f9:order-5",
      },
      {
        label: t("TEAM"),
        prop: "team",
        className: "order-6 w-[33%] m-f9:w-[15%] m-f9:order-6",
      },
      {
        label: t("CREATION_DATE"),
        prop: "creationDate",
        className: "order-7 w-[33%] m-f9:w-[10%] m-f9:order-7",
      },
      {
        label: t("DEADLINE"),
        prop: "deadline",
        customBody: getDeadlineBody,
        hasOverflow: false,
        className: "order-8 w-[33%] m-f9:w-[15%] m-f9:order-8",
      },
      {
        customBody: customFinishBody,
        label: "Data de Encerramento",
        prop: "closedDate",
        className: "order-9 w-[33%] m-f9:w-[15%] m-f9:order-9",
      },
      {
        label: "Responsável",
        prop: "analystName",
        className: "order-11 w-[33%] m-f9:w-[15%] m-f9:order-10",
      },
      {
        label: t("STATUS"),
        prop: "status",
        customBody: getStatusBody,
        hasOverflow: false,
        className: "order-12 w-[33%] m-f9:w-[15%] m-f9:order-11",
      },
      {
        label: t("SCORE"),
        prop: "score",
        className: "order-3 w-[33%] m-f9:w-[13%] m-f9:order-12",
      },
    ],
    [customFinishBody, getDeadlineBody, t]
  );

  return colunas;
};

export default useColumns;
