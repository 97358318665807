import { useCallback, useEffect } from "react";

export const useClick = (
  element,
  state,
  setter,
  onClickInside,
  onClickOutside
) => {
  const onClick = useCallback(
    (event) => {
      if (!element.current.contains(event.target)) {
        onClickOutside?.(state);
        setter(false);
      } else if (!state) {
        onClickInside?.();
      }
    },
    [element, onClickInside, onClickOutside, setter, state]
  );

  useEffect(() => {
    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [element, onClick, onClickInside, onClickOutside, setter, state]);
};
