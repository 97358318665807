const CardOption = ({ optionKey, item, setIsVisible, consulta }) => {
  const { cardOptions, onSearch } = consulta;

  let isVisible = cardOptions[optionKey].isVisible
    ? cardOptions[optionKey].isVisible?.(item)
    : true;

  let content = "";

  let hasAlert = false;
  if (cardOptions[optionKey].hasAlert)
    hasAlert = cardOptions[optionKey].hasAlert(item);

  if (isVisible)
    content = (
      <li
        className="w-full flex justify-end text-[#676767] px-4 py-2 text-[13px] border-b hover:bg-slate-100 relative h-9 whitespace-nowrap"
        onClick={() => {
          cardOptions[optionKey].action?.(item, onSearch);
          setIsVisible(false);
        }}
      >
        {hasAlert && (
          <span className="absolute h-2 w-2 bg-red-600 rounded-full top-1/2 -translate-y-1/2 left-2"></span>
        )}
        {cardOptions[optionKey].label}
      </li>
    );

  return content;
};

export default CardOption;
