import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
import LogoutIcon from "../../../../assets/png/Logout.png";
import Avatar from "../../../../assets/png/Avatar-Padrao.png";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../features/user";

const UserInfo = () => {
  const user = useSelector(selectUser);
  let userImagem = Avatar;
  if (user.userImagem) userImagem = user.userImagem;

  return (
    <div className="flex">
      <Dropdown userImagem={userImagem} user={user} />
      <div className="my-2 border-r-2 mx-2 m-f1:mx-0 t-f1:mx-2"></div>
      <div className="flex mx-3 m-f9:w-10 m-f9:mx-2 items-center">
        <NavLink
          to="/Logout"
          className="w-full h-full flex items-center justify-center"
        >
          <img alt="Sair" src={LogoutIcon} />
        </NavLink>
      </div>
    </div>
  );
};
export default UserInfo;
