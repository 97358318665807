import Pagination from "./Pagination";
import Hamburguer from "../assets/png/hamburguer.png";
import { useDispatch, useSelector } from "react-redux";
import { selectPagination, resetPagination } from "../features/pagination";
import { useQueryString } from "../utility/useQueryString";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export const MobileMenu = ({ setter }) => {
  let query = useQueryString();
  let page = parseInt(query.get("page") ?? 0);

  const location = useLocation();
  const dispatch = useDispatch();
  const [previousLocation, setPreviousLocation] = useState(undefined);

  const { length, recordsTotal, recordsFiltered } =
    useSelector(selectPagination);

  useEffect(() => {
    let currentLocation = window.location.pathname;

    if (currentLocation === previousLocation) return;

    setPreviousLocation(currentLocation);
    dispatch(resetPagination());
  }, [dispatch, location, previousLocation]);

  return (
    <div className="fixed w-full bottom-0 bg-white py-2 px-4 flex left-0 z-10 shadow-[0px_-2px_4px_#b1b1b140] m-f9:hidden">
      <div className="w-full items-center justify-start flex">
        {length > 0 && recordsTotal > recordsFiltered && (
          <Pagination
            maxPages={3}
            currentPage={page}
            recordsTotal={recordsTotal}
            recordsFiltered={recordsFiltered}
          />
        )}
      </div>
      <div className="flex justify-end">
        <button type="button" onClick={() => setter((state) => !state)}>
          <img alt="Abrir menu lateral" src={Hamburguer} />
        </button>
      </div>
    </div>
  );
};
