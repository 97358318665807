import clsx from "clsx";

const Body = ({ children, className }) => {
  return (
    <div className={clsx("mt-4 w-4/5 mx-auto", className)}>
      {children}
    </div>
  );
};

export default Body;
