import clsx from "clsx";

const Label = ({ id, children, className }) => {
  return (
    <label
      htmlFor={id}
      className={clsx(
        "text-[#8A92A6] text-sm whitespace-nowrap overflow-hidden text-ellipsis",
        className
      )}
    >
      {children}
    </label>
  );
};

export default Label;
