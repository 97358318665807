import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";

import { selectUserToken } from "../../features/user";

const useFetch = ({
  endpoint,
  data,
  form,
  watchFields,
  doReset,
  prepareResult,
  prepareReset,
}) => {
  const token = useSelector(selectUserToken);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const hideLoading = useCallback(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const fetch = useCallback(async () => {
    let result = await endpoint(data?.(), token);

    if (result.hasError) {
      Swal.fire({
        title: "Erro!",
        text: "Algo deu errado",
        icon: "error",
      });
      hideLoading();
      return;
    }

    if (form) {
      if (doReset) {
        if (prepareReset) {
          form.reset(prepareReset(result));
        } else {
          form.reset({
            ...result.data,
          });
        }
      }
    }

    if (prepareResult) setResult(prepareResult(result.data));
    else setResult(result.data);

    hideLoading();
  }, [
    data,
    doReset,
    endpoint,
    form,
    hideLoading,
    prepareReset,
    prepareResult,
    token,
  ]);

  useEffect(() => {
    fetch();
  }, [data, fetch]);

  useEffect(() => {
    if (!watchFields) return;
    if (!form) return;

    const subscription = form.watch((_, { name }) => {
      if (watchFields.includes(name)) {
        fetch();
      }
    });

    return () => subscription.unsubscribe();
  }, [form, fetch, watchFields]);

  return { result, isLoading };
};

export default useFetch;
