import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserToken } from "../features/user";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const useSubmit = ({
  endpoint,
  prepareData,
  form,
  onSuccess,
  onError,
  onFinished,
  onAlertSuccess,
  title,
  successMessage,
  icon,
}) => {
  const token = useSelector(selectUserToken);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState();

  const onSubmit = useCallback(
    async (data) => {
      if (isLoading) return;
      setIsLoading(true);

      if (prepareData) data = prepareData(data);

      let result = await endpoint(data, token);

      if (result.hasError) {
        let text = "Algo deu errado";

        if (result.error.response.data.notifications) {
          text = result.error.response.data.notifications[0].message;
        }

        Swal.fire({
          title: "Erro!",
          text: text,
          icon: "error",
        });

        onError?.();
        onFinished?.();

        setHasError(true);
        setIsLoading(false);

        return;
      }

      if (form) form.reset();

      onSuccess?.(result);
      onFinished?.();

      setIsLoading(false);

      const mySwal = withReactContent(Swal)

      mySwal.fire({
        title: title || "Sucesso!",
        html: successMessage instanceof Function ? successMessage?.(result) : successMessage || "Cadastrado com sucesso",
        icon: icon || "success",
      }).then((swalResponse) => {
        if (swalResponse.isConfirmed) {
          onAlertSuccess?.();
        }
      });
    },
    [
      endpoint,
      form,
      icon,
      isLoading,
      onAlertSuccess,
      onError,
      onFinished,
      onSuccess,
      prepareData,
      successMessage,
      title,
      token,
    ]
  );

  return { onSubmit, isLoading, hasError };
};
