import clsx from "clsx";
import "./button.css";

export const buttonStyle = {
  lightGreen: "bg-[#9B9B9B] text-[#FFF] rounded-md hover:bg-[#B5B2B2]",
  darkGreen: "bg-[#187733] text-[#FFF] rounded-md hover:bg-[#1F803A]",
  darkBlue: "bg-[#005BBF] text-[#FFF] rounded-md",
};

const Index = ({
  style = buttonStyle.lightGreen,
  type = "button",
  text,
  children,
  className,
  onClick,
  isLoading,
}) => {
  let content = text || children;

  if (isLoading) content = <span className="spinner"></span>;

  return (
    <button
      type={isLoading ? "button" : type}
      className={clsx(
        "button px-4 h-11 flex items-center justify-center",
        style,
        className,
        isLoading ? "loading overflow-hidden" : "min-w-[157px]"
      )}
      onClick={() => {
        if (isLoading) return;
        onClick?.();
      }}
    >
      {content}
    </button>
  );
};

export default Index;
