import { useCallback } from "react";
import { useSelector } from "react-redux";

import { ticketApi } from "../../../../../utility/api";
import InfoIcon from "../../../../../assets/svg/info.svg";
import { selectUserToken } from "../../../../../features/user";
import AttachmentIcon from "../../../../../assets/svg/attachment.svg";
import Swal from "sweetalert2";

export const FileContent = ({
  ticketId,
  fileName,
  isLoading,
  hasError,
  ticketFileId,
}) => {
  const token = useSelector(selectUserToken);

  const downloadFile = useCallback(async () => {
    if (isLoading) return;

    let response = await ticketApi.downloadTicketFile(
      { ticketFileId, ticketId },
      token
    );

    if (response.hasError) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: "Algo deu errado.",
      });
      return;
    }

    const href = response.data;

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName); //or any other extension
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [fileName, isLoading, ticketFileId, ticketId, token]);

  let icon;

  if (hasError) {
    icon = (
      <div className="h-3 w-3 mr-2">
        <img src={InfoIcon} alt="Error" />
      </div>
    );
  } else if (isLoading) {
    icon = (
      <div className="w-3 h-3 border-b-2 border-[#1B2646] rounded-full animate-spin"></div>
    );
  } else {
    icon = (
      <div className="h-3 w-3 mr-2">
        <img src={AttachmentIcon} alt="Download" />
      </div>
    );
  }

  let content = (
    <>
      {icon}
      <span className="underline italic overflow-x-hidden text-ellipsis leading-4 w-[calc(100%_-_1.25rem)] text-left">
        {fileName}
      </span>
    </>
  );

  if (isLoading || hasError)
    return <div className="flex items-center gap-2">{content}</div>;

  return (
    <button
      type="button"
      onClick={() => downloadFile()}
      className="flex items-center gap-2"
    >
      {content}
    </button>
  );
};
