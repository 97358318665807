export function deepCopy(obj) {
  if (typeof target === "object") {
    let cloneTarget = {};
    for (const key in obj) {
      cloneTarget[key] = deepCopy(obj[key]);
    }
    return cloneTarget;
  } else return obj;
}

// Essa função realiza a comparação entre objetos
// de forma shallow, ou seja, compara os parâmetros
// do objeto ao invés do valor do seu ponteiro, como ocorre
// se utilizarmos de obj1 === obj2
export function shallowObjectComparison() {
  let objs = [...arguments].map((item) => JSON.stringify(trimObject(item)));

  for (let i = 0; i < objs.length; i++) {
    // Se não tivermos mais nenhum valor para comparar
    // na lista retornamos true
    if (i + 1 === objs.length) return true;

    if (`${objs[i]}` !== `${objs[i + 1]}`) return false;
  }

  return true;
}

// Essa função apaga as propriedades de um objeto vazias
// para fazer com que a comparação entre objetos seja
// mais precisa
export function trimObject(obj) {
  // Aqui estou criando uma Shallow copy do objeto original para que os dados
  // do objeto original não sejam alterados
  obj = JSON.parse(JSON.stringify(obj ?? {}));
  let keys = Object.keys(obj);

  for (let prop of keys) {
    if (!Boolean(obj[prop])) {
      delete obj[prop];
    } else obj[prop] = `${obj[prop]}`;
  }

  return obj;
}

export function group(data, callbackFn) {
  return data.reduce(function (rv, x) {
    let key = callbackFn(x);
    rv[key] = rv[key] || [];
    rv[key].push(x);
    return rv;
  }, {});
}
