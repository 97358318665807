import api from "./api";

export const divisaoApi = {
  getDropdown: async (data, token) => {
    return api.get(`/Division/GetAllActives`, data, token);
  },
  getCorpDivisions: async (data, token) => {
    return api.get('/Division/GetDivisionsToCorp', data, token);
  },
};
