/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";

import "tippy.js/dist/tippy.css";
import Tippy from "@tippyjs/react";
import { dropdownPosition } from ".";
import Input from "../Input";
import Thrash from "../../assets/png/thrash.png";
import Search from "../../assets/png/search.png";
import { useClick } from "../../hooks/useClick";
import Label from "../Label";

const DropdownSearch = ({
  placeholder,
  name,
  onChange,
  label,
  required,
  listLabel,
  searchEndpoint,
  startEndpoint,
  configuration,
  position = dropdownPosition.Bottom,
  form,
  readOnly
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = form;

  const [isVisible, setVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  const searchInput = useRef(null);
  const content = useRef(null);

  useClick(content, isVisible, setVisibility);

  const disableLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const onSearch = useCallback(async () => {
    setIsLoading(true);
    let result = await searchEndpoint(searchInput.current?.value);

    if (!result.hasError) setOptions(result.data);

    disableLoading();
  }, [searchEndpoint, setOptions]);

  const onSelectOption = useCallback(
    (option) => {
      let currentSelectedOptions = [option, ...selectedOptions];

      setSelectedOptions(currentSelectedOptions);

      currentSelectedOptions = currentSelectedOptions.map(
        (item) => item[configuration.valueProp]
      );

      setValue(name, currentSelectedOptions, {
        shouldTouch: true,
        shouldValidate: true,
      });

      if (searchInput.current) searchInput.current.value = "";

      onChange?.();
      setVisibility(false);
    },
    [
      configuration.valueProp,
      name,
      onChange,
      options,
      selectedOptions,
      setValue,
    ]
  );

  const onUnselectOption = useCallback(
    (option) => {
      let currentSelectedOptions = selectedOptions.filter(
        (item) => item !== option
      );

      setSelectedOptions(currentSelectedOptions);

      currentSelectedOptions = currentSelectedOptions.map(
        (item) => item[configuration.valueProp]
      );

      setValue(name, currentSelectedOptions, {
        shouldTouch: true,
        shouldValidate: true,
      });
      onChange?.();
    },
    [
      configuration.valueProp,
      name,
      onChange,
      options,
      selectedOptions,
      setValue,
    ]
  );

  const reset = useCallback(async () => {
    let values = getValues?.(name) || [];

    if (!Array.isArray(values)) values = [...values];

    values = values.map((item) => item.toString());

    if (!startEndpoint) return;
    let result = await startEndpoint(values);

    if (result.hasError) return;

    setSelectedOptions(result.data);
  }, [getValues, name]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // Quando name e type forem undefined quer dizer que o campo foi resetado
      if (name === undefined && type === undefined) reset();
    });
    return () => subscription.unsubscribe();
  }, [name, options, reset, watch]);

  return (
    <div className="relative">
      <input
        type="hidden"
        {...register?.(name, {
          required: required && "Este campo não pode ficar vazio",
        })}
      />
      <Label>{label}</Label>
      <div className="relative pt-1" ref={content}>
        <Input
          placeholder={placeholder}
          errors={errors}
          readOnly={readOnly}
          onChange={() => onSearch()}
          onFocus={() => {
            if (readOnly) return;
            onSearch();
            setVisibility(true);
          }}
          ref={searchInput}
          button={
            <button
              type="button"
              className="absolute top-1/2 right-0 -translate-y-1/2 -translate-x-1/2"
              onClick={() => false}
            >
              <img alt="Buscar" src={Search} />
            </button>
          }
        />

        {isVisible && (
          <div
            className={`${
              position === dropdownPosition.Top
                ? "bottom-full flex-col-reverse mb-2"
                : "top-full flex-col"
            } absolute flex left-0 w-full text-left font-normal z-10`}
          >
            <div className="max-h-[200px] my-2 rounded-md bg-white ring-1 shadow-lg ring-black ring-opacity-5 overflow-y-auto">
              <ul className="text-black cursor-pointer">
                {isLoading && (
                  <li className="text-gray-700 text-sm px-4 py-3 bg-[#ece6e6]">
                    Carregando
                  </li>
                )}

                {!isLoading && options.length === 0 && (
                  <li className="text-gray-700 text-sm px-4 py-3 bg-[#ece6e6]">
                    Nenhuma opção encontrada
                  </li>
                )}

                {!isLoading &&
                  options?.map((item, index) => (
                    <li
                      key={index}
                      className="text-gray-700 hover:bg-slate-100 block px-4 py-3 text-sm border-b"
                      onClick={() => {
                        onSelectOption(item);
                      }}
                    >
                      {item[configuration.labelProp]}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}
      </div>

      {selectedOptions.length > 0 && (
        <div className="mt-5 text-sm">
          <Label>{listLabel}</Label>
          <div
            className="grid text-center border border-[#686868] rounded-[4px] px-1 overflow-auto max-h-[145px]"
            style={{
              gridTemplateColumns: `repeat(${
                configuration.columns.length + 1
              }, auto`,
            }}
          >
            {configuration.columns.map((item, index) => (
              <span
                key={index}
                className="whitespace-nowrap overflow-hidden text-ellipsis px-2 py-2 border-b border-[#686868] text-[#8A92A6]"
              >
                {item.label}
              </span>
            ))}

            <span className="whitespace-nowrap overflow-hidden text-ellipsis px-2 py-2 border-b border-[#686868]"></span>

            {selectedOptions.map((item, index) => (
              <React.Fragment key={index}>
                {configuration.columns.map((column, columnIndex) => (
                  <React.Fragment key={columnIndex}>
                    {item[column.prop] ? (
                      <Tippy content={item[column.prop]}>
                        <span
                          className={`whitespace-nowrap overflow-hidden text-ellipsis px-2 py-2 border-[#686868] ${
                            selectedOptions.length - 1 > index ? "border-b" : ""
                          }`}
                        >
                          {item[column.prop]}
                        </span>
                      </Tippy>
                    ) : (
                      <span
                        className={`whitespace-nowrap overflow-hidden text-ellipsis px-2 py-2 border-[#686868] ${
                          selectedOptions.length - 1 > index ? "border-b" : ""
                        }`}
                      ></span>
                    )}
                  </React.Fragment>
                ))}

                <span
                  className={`flex justify-center items-center px-1 py-2 border-[#686868] ${
                    selectedOptions.length - 1 > index ? "border-b" : ""
                  }`}
                >
                  <button type="button" onClick={() => onUnselectOption(item)}>
                    <img alt="Remover" src={Thrash} />
                  </button>
                </span>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSearch;
