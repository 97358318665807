import { useCallback, useMemo, useState } from "react";

import Input from "../../../components/Input";
import { useTicketDepartments, useTimes } from "../../../hooks/fetch";
import Dropdown from "../../../components/Dropdown";
import useDropdownOptions from "./useDropdownOptions";
import { actions, functions, useAuthorize } from "../../../hooks/useAthorize";
import { useCorpDivisions } from "../../../hooks/fetch/useCorpDivisions";

const filter = {
  none: 0,
  value: 1,
  date: 2,
  deadline: 3,
  department: 4,
};

const useFiltros = (departments, categories, user) => {
  const allowAtendimento = useAuthorize(functions.ticket, actions.atender);

  const { filterOptions, deadlineFilterOptions, statusOptions, orderOptions } =
    useDropdownOptions();

  const [visibleFilter, setVisibleFilter] = useState(filter.none);

  const { result: teams } = useTimes(null, departments);
  const corpDivisions = useCorpDivisions();

  const prepareTicketDepartmentResult = useCallback(
    (result) =>
      result.map((item) => {
        return { id: item.id, text: item.text };
      }),
    []
  );

  const { result: ticketDepartments } = useTicketDepartments({
    prepareResult: prepareTicketDepartmentResult,
  });

  const onFilterTypeChanged = useCallback((option) => {
    const type = option.id;

    if (!option || Object.keys(option).length === 0)
      setVisibleFilter(filter.none);
    else if (type === 4 || type === 6) setVisibleFilter(filter.date);
    else if (type === 5) setVisibleFilter(filter.deadline);
    else if (type === 7) setVisibleFilter(filter.department);
    else setVisibleFilter(filter.value);
  }, []);

  const filtros = useMemo(() => {
    if (allowAtendimento) {
      const camposFiltros = [
        {
          element: (form) => (
            <Dropdown
              form={form}
              label="Filtrar por categoria"
              name="Categories"
              placeholder="Selecione..."
              options={categories}
              multiple
            />
          ),
        },
        {
          label: "Filtrar por responsável",
          name: "analyst",
          placeholder: "Escreva...",
        },
        {
          element: (form) => (
            <Dropdown
              form={form}
              label="Filtrar por time"
              name="team"
              placeholder="Selecione..."
              options={teams}
              multiple
            />
          ),
        },
        {
          element: (form) => (
            <Dropdown
              form={form}
              label="Filtrar por status"
              name="status"
              options={statusOptions}
              multiple
              placeholder="Selecione..."
            />
          ),
        },
        {
          element: (form) => (
            <Dropdown
              form={form}
              label="Ordem"
              name="ticketNumberOrder"
              options={orderOptions}
              placeholder="Selecione..."
            />
          ),
        },
        {
          element: (form) => (
            <Dropdown
              form={form}
              name="type"
              onChange={onFilterTypeChanged}
              label="Filtrar por"
              options={filterOptions}
              placeholder="Selecione..."
            />
          ),
        },
        {
          label: "Digite aqui",
          name: "value",
          isVisible: visibleFilter === filter.value,
          placeholder: "Escreva...",
        },
        {
          element: (form) => (
            <Input
              mask={{
                mask: "__/__/____",
                replacement: { _: /\d/ },
              }}
              form={form}
              label="Digite aqui"
              name="date"
              placeholder="Escreva..."
            />
          ),
          isVisible: visibleFilter === filter.date,
        },
        {
          element: (form) => (
            <Dropdown
              form={form}
              name="deadline"
              label="Filtrar por status do prazo"
              options={deadlineFilterOptions}
              placeholder="Selecione..."
            />
          ),
          isVisible: visibleFilter === filter.deadline,
        },
        {
          element: (form) => (
            <Dropdown
              form={form}
              label="Filtrar por Departamento"
              name="departments"
              options={ticketDepartments}
              multiple
              placeholder="Selecione..."
            />
          ),
          isVisible: visibleFilter === filter.department,
        },
      ];

      if (user.isCorporate) {
        camposFiltros.splice(4, 0, {
          element: (form) => (
            <Dropdown
              form={form}
              label="Filtrar por divisão"
              name="divisions"
              options={corpDivisions}
              multiple
              placeholder="Selecione..."
            />
          ),
        });
      }

      return camposFiltros;
    }

    return [
      {
        element: (form) => (
          <Dropdown
            form={form}
            label="Filtrar por status"
            name="status"
            options={statusOptions}
            multiple
            placeholder="Selecione..."
          />
        ),
      },
      {
        element: (form) => (
          <Dropdown
            form={form}
            name="type"
            onChange={onFilterTypeChanged}
            label="Filtrar por"
            options={filterOptions}
            placeholder="Selecione..."
          />
        ),
      },
      {
        element: (form) => (
          <Dropdown
            form={form}
            label="Ordem"
            name="ticketNumberOrder"
            options={orderOptions}
            placeholder="Selecione..."
          />
        ),
      },
      {
        label: "Digite aqui",
        name: "value",
        isVisible: visibleFilter === filter.value,
        placeholder: "Escreva...",
      },
      {
        element: (form) => (
          <Input
            mask={{
              mask: "__/__/____",
              replacement: { _: /\d/ },
            }}
            form={form}
            label="Digite aqui"
            name="date"
            placeholder="Escreva..."
          />
        ),
        isVisible: visibleFilter === filter.date,
      },
      {
        element: (form) => (
          <Dropdown
            form={form}
            label="Filtrar por Departamento"
            name="departments"
            options={ticketDepartments}
            multiple
            placeholder="Selecione..."
          />
        ),
        isVisible: visibleFilter === filter.department,
      },
    ];
  }, [
    allowAtendimento,
    categories,
    deadlineFilterOptions,
    filterOptions,
    onFilterTypeChanged,
    statusOptions,
    teams,
    ticketDepartments,
    visibleFilter,
  ]);

  return filtros;
};

export default useFiltros;
