import { useState } from "react";
import { useSelector } from "react-redux";

import Dropdown from "../../../components/Dropdown";
import { selectUser } from "../../../features/user";
import { useTicketUsers } from "../../../hooks/fetch";

const UserDropdown = ({ form }) => {
  const user = useSelector(selectUser);
  const [filterUserName, setFilterUserName] = useState(null);
  const { result: users } = useTicketUsers(form, filterUserName);

  return (
    <Dropdown
      name={"userId"}
      form={form}
      options={users}
      label="Usuário"
      placeholder="Selecione..."
      onFilter={setFilterUserName}
      defaultValue={user.userId}
    />
  );
};

export default UserDropdown;
