import React from "react";
import { useSelector } from "react-redux";

import imageUser from "../../../../assets/png/Avatar-Padrao.png";
import { selectUser, selectUserDepartments } from "../../../../features/user";

const HeaderChat = ({ ticket }) => {
  const user = useSelector(selectUser);
  const departments = useSelector(selectUserDepartments).map(d => d.name).join(', ');

  return (
    <div className="bg-[#f8f8f8] drop-shadow-md px-4 w-full">
      <div className="w-full h-[55px] flex items-center ">
        <div className="w-full flex flex-row items-center ">
          <div className=" ">
            <img alt="user" src={imageUser} className="w-[26px] h-[26px]" />
          </div>
          <div className="flex-auto pl-2 w-64">
            <div className="font-bold text-[14px]">{user.name}</div>
            <div className="font text-[10px] text-stone-500">
              {departments}
            </div>
          </div>
          <div className="grid justify-items-end">
            <div className="font-bold text-[14px]">#{ticket.number}</div>
            <div className="font text-[10px] text-stone-500">Chamado</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderChat;
