import api from "./api";

export const departamentoApi = {
  getById: async (id, token) => {
    return api.get(`/Department/${id}`, null, token);
  },
  update: async (data, token) => {
    return api.put(`/Department/Update`, data, token);
  },
  getDropdown: async (data, token) => {
    return api.get(`/Department/GetAllActives`, data, token);
  },
  getAllForTicket: async (data, token) => {
    return api.get(`/Department/GetAllForTicket`, data, token);
  },
  getListPaged: async (data, token) => {
    let hasSCI = undefined;

    if (data.hasOwnProperty("hasSCI") && Number.isInteger(data.hasSCI))
      hasSCI = data.hasSCI === 1;

    return api.get(
      `/Department/GetListPaged`,
      {
        ...data,
        hasSCI,
      },
      token
    );
  },
  activateSci: async (id, token) => {
    return api.patch(`/Department/${id}/SCIStatus`, { isActive: true }, token);
  },
  inactivateSci: async (id, token) => {
    return api.patch(`/Department/${id}/SCIStatus`, { isActive: false }, token);
  },
};
