import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import moment from 'moment';
import 'moment/locale/pt-br';
import en from './languages/en-US';
import pt from './languages/pt-BR';
import es from './languages/ES';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt',
    lng: 'pt',
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    debug: false,
    resources: {
      en: {
        translation: en,
      },
      pt: {
        translation: pt,
      },
      es: {
        translation: es,
      },
    },
  });

i18n.on('languageChanged', function(lng) {
  i18n.reloadResources(lng);
  moment.locale(changeFormat(lng));
});

const changeFormat = (language) => {
  if (language === 'en')
    return language.concat('-US')
  return language.concat('-BR')
}

export default i18n;
