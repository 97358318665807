import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser, selectUserToken } from "../features/user";

const useRequireLogin = () => {
  const token = useSelector(selectUserToken);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectPath, setRedirectPath] = useState(undefined);
  const user = useSelector(selectUser);

  window.user = user;

  useEffect(() => {
    if (token) {
      setIsLoading(false);
      return;
    }

    setRedirectPath(process.env.REACT_APP_Login_URL);
    setIsLoading(false);
  }, [token]);

  return {
    isLoading,
    redirectPath,
  };
};

export default useRequireLogin;
