import clsx from "clsx";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import Input from "../../components/Input";
import Loading from "../../components/Loading";
import { ticketApi } from "../../utility/api/";
import { Modal } from "../../components/Modal";
import { selectUserToken } from "../../features/user";
import IconeDownload from "../../assets/svg/download.svg";
import { getStatusBody } from "./ConsultaChamados/useColumns";
import Label from "../../components/Label";

const VisualizarChamado = ({ setIsVisible, ticketId }) => {
  const form = useForm();
  const token = useSelector(selectUserToken);
  const [chamado, setChamado] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const fetchChamado = useCallback(async () => {
    let result = await ticketApi.examine(ticketId, token);

    if (result.hasErro) {
      Swal.fire({
        icon: "error",
        text: "Algo deu errado",
      });
      return;
    }

    form.reset({ ...result.data });
    setChamado({ ...result.data });

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [form, ticketId, token]);

  useEffect(() => {
    fetchChamado();
  }, [fetchChamado]);

  const downloadFile = useCallback(
    async (arquivo) => {
      let response = await ticketApi.downloadTicketFile({ ...arquivo }, token);

      if (response.hasError) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Algo deu errado."
        });
        return;
      }

      const href = response.data;

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", arquivo.name); //or any other extension
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
    [token]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Modal
      title="Visualizar Chamado"
      containerClassName=""
      childrenClassName="grid grid-cols-8 gap-x-2 gap-y-3"
      form={form}
      onClose={() => setIsVisible(false)}
    >
      <div className="order-1 m-f9:col-span-6 col-span-4">
        <Input name="number" label="Número" form={form} readOnly />
      </div>

      <div className="order-2 m-f9:col-span-2 col-span-4">
        <Input name="score" label="Pontuação" form={form} readOnly />
      </div>

      <div className="order-3 col-span-8">
        <Input name="team" label="Time" form={form} readOnly />
      </div>

      <div className="order-4 col-span-8">
        <Input name="category" label="Categoria" form={form} readOnly />
      </div>

      <div className="order-5 m-f9:col-span-3 col-span-4">
        <Input
          name="parsedCreationDate"
          label="Data de Criação"
          form={form}
          readOnly
        />
      </div>

      <div className="order-7 m-f9:order-6 m-f9:col-span-3 col-span-4">
        <Input
          name="parsedClosedDate"
          label="Data de Conclusão"
          form={form}
          readOnly
        />
      </div>

      <div className="order-6 m-f9:order-7 m-f9:col-span-2 col-span-4">
        <Input name="parsedDeadline" label="Prazo" form={form} readOnly />
      </div>

      <div className="order-8 m-f9:col-span-3 col-span-4">
        <Input name="keyUser" label="Solicitante" form={form} readOnly />
      </div>

      <div className="order-9 m-f9:col-span-3 col-span-6">
        <Input name="analyst" label="Analista" form={form} readOnly />
      </div>

      <div className="order-10 m-f9:col-span-2 col-span-4">
        <Input
          name="status"
          className={clsx(
            getStatusBody(chamado),
            "!text-black h-auto p-0 overflow-hidden text-ellipsis",
            "w-[8rem] !text-md"
          )}
          label="Status"
          form={form}
          readOnly
        />
      </div>

      <div className="order-11 col-span-8">
        <Input name="department" label="Departamento" form={form} readOnly />
      </div>

      <div className="order-12 col-span-8">
        <Input name="subject" label="Assunto" form={form} readOnly multiline />
      </div>

      <div className="order-[13] col-span-8">
        <Input
          name="description"
          label="Descrição"
          form={form}
          multiline
          readOnly
        />
      </div>

      {chamado.creationFiles && chamado.creationFiles.length > 0 && (
        <div className="order-[14] col-span-8">
          <Label>Abertura de Chamado</Label>
          <div className="border border-black rounded-md">
            <div className="overflow-auto max-h-[160px] pt-3">
              {chamado.creationFiles?.map((arquivo, index) => (
                <React.Fragment key={index}>
                  <div className="grid grid-cols-[1.25rem_calc(100%_-_0.5rem)] items-center gap-3 px-4 justify-between m-f9:justify-start">
                    <button
                      type="button"
                      className="min-w-[1.25rem] w-5 max-w-[1.25rem]"
                      onClick={() => downloadFile(arquivo)}
                    >
                      <img
                        className="max-h-8"
                        alt="Apagar arquivo"
                        src={IconeDownload}
                      />
                    </button>
                    <div className="max-w-[calc(100%_-_1.5rem)] grid grid-cols-[1fr_max-content]">
                      <span className="font-bold text-sm text-right whitespace-nowrap overflow-hidden text-ellipsis">
                        {arquivo.name}
                      </span>
                      <span className="font-bold text-sm text-right">
                        {arquivo.extension}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-2 border-b my-3 mx-4 border-black last:border-0"></div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}

      {chamado.resolution && (
        <div className="order-[14] col-span-8">
          <Input
            name="resolution"
            label="Solução do Chamado"
            form={form}
            multiline
            readOnly
          />
        </div>
      )}

      {chamado.disapproveReason && (
        <div className="order-[15] col-span-8">
          <Input
            name="disapproveReason"
            label="Motivo da Reprovação"
            form={form}
            multiline
            readOnly
          />
        </div>
      )}

      {chamado.closingFiles && chamado.closingFiles.length > 0 && (
        <div className="order-[14] col-span-8">
          <Label>Finalização de Chamado</Label>
          <div className="border border-black rounded-md">
            <div className="overflow-auto max-h-[160px] pt-3">
              {chamado.closingFiles?.map((arquivo, index) => (
                <React.Fragment key={index}>
                  <div className="grid grid-cols-[1.25rem_calc(100%_-_0.5rem)] items-center gap-3 px-4 justify-between m-f9:justify-start">
                    <button
                      type="button"
                      className="min-w-[1.25rem] w-5 max-w-[1.25rem]"
                      onClick={() => downloadFile(arquivo)}
                    >
                      <img
                        className="max-h-8"
                        alt="Apagar arquivo"
                        src={IconeDownload}
                      />
                    </button>
                    <div className="max-w-[calc(100%_-_1.5rem)] grid grid-cols-[1fr_max-content]">
                      <span className="font-bold text-sm text-right whitespace-nowrap overflow-hidden text-ellipsis">
                        {arquivo.name}
                      </span>
                      <span className="font-bold text-sm text-right">
                        {arquivo.extension}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-2 border-b my-3 mx-4 border-black last:border-0"></div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default VisualizarChamado;
