import { v4 as newGuid } from "uuid";
import { useRef, useEffect, useState, useCallback } from "react";

import { ticketApi } from "../../../../utility/api";
import { validateFile } from "../../../../components/FileContainer/useFileContainer";
import Swal from "sweetalert2";

const validExtensions = [
  ".xlsx",
  ".docx",
  ".pptx",
  ".pdf",
  ".jpeg",
  ".jpg",
  ".png",
  ".txt",
  ".mp4",
  ".avi",
  ".mpeg",
  ".mpg"
];

export const useFileUpload = ({
  ticket,
  token,
  setMessages,
  connection,
  user,
  setHasToScrollBottom,
}) => {
  const inputFileRef = useRef(null);
  const [uploadingFileId, setUploadingFileId] = useState(undefined);

  const onAttachmentClick = useCallback(() => {
    inputFileRef.current.click();
  }, []);

  const uploadFile = useCallback(async () => {
    if (!uploadingFileId) return;

    const { message, file, id } = uploadingFileId;

    const formData = new FormData();
    formData.append("ticketId", ticket.id);
    formData.append("file", file);

    let response = await ticketApi.uploadTicketMessageFile(formData, token);

    if (response.hasError) {
      const {
        hasMaxFileError,
        hasUploadError,
        message: errorMessage,
      } = response.error.response.data;

      if (hasMaxFileError) {
        Swal.fire({
          icon: "info",
          title: "Atenção!",
          text: "O número máximo de arquivos foi atingido!",
        });
      } else if (hasUploadError) {
        Swal.fire({
          icon: "info",
          title: "Atenção!",
          text: "Algo deu errado ao enviar o arquivo! Por favor, tente novamente mais tarde",
        });
      } else {
        Swal.fire({
          icon: "info",
          title: "Atenção!",
          text: errorMessage,
        });
      }

      message.isLoading = false;
      message.hasError = true;

      setMessages((array) => [
        ...array.map((a) => (a.id === id ? message : a)),
      ]);

      return;
    }

    message.ticketFileId = response.data.id;
    message.isLoading = false;

    setMessages((array) => [...array.map((a) => (a.id === id ? message : a))]);

    try {
      await connection.invoke(
        "SendFileMessage",
        ticket.id,
        file.name,
        message.ticketFileId,
        token
      );
    } catch (err) {
      console.error(err);
    }

    setUploadingFileId(undefined);
  }, [connection, setMessages, ticket, token, uploadingFileId]);

  useEffect(() => {
    uploadFile();
  }, [uploadFile]);

  const onInputFileChanged = useCallback(async () => {
    let file = inputFileRef.current.files?.[0];

    if (!file) return;

    inputFileRef.current.value = "";

    const maxSize = 2 * 10 ** 9; // 2Gb

    const { isValid, message: validationMessage } = validateFile(
      file,
      validExtensions,
      maxSize,
    );

    if (!isValid) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: validationMessage,
      });
      return;
    }

    const id = newGuid();

    let message = {
      id: id,
      ticketId: ticket.id,
      message: file.name,
      date: new Date(),
      userSenderId: user.userId,
      userName: user.name,
      isFile: true,
      isLoading: true,
    };

    setMessages((array) => [...array, message]);
    setUploadingFileId({ id, file, message });
    setHasToScrollBottom(true);
  }, [setHasToScrollBottom, setMessages, ticket.id, user.name, user.userId]);

  return {
    inputFileRef,
    onAttachmentClick,
    onInputFileChanged,
  };
};
