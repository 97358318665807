import { useMemo, useCallback } from "react";
import useFetch from "./useFetch";
import { userApi } from "../../utility/api";

export const useTicketUsers = (form, userName) => {
  const data = useCallback(() => {
    return {
      userName: userName,
    };
  }, [userName]);

  const watchFields = useMemo(() => ["teamId"], []);

  return useFetch({
    endpoint: userApi.getAllForTicket,
    data,
    form,
    watchFields,
  });
};
