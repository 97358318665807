import React from "react";
import DropdownMultiple from "./DropdownMultiple";
import DropdownSingle from "./DropdownSingle";

export const dropdownPosition = {
  Top: 0,
  Bottom: 1,
};

const Dropdown = React.forwardRef((props, ref) => {
  if (props.multiple) {
    return <DropdownMultiple ref={ref} {...props} />;
  }

  return <DropdownSingle ref={ref} {...props} />;
});

export default Dropdown;
