import { useSelector } from "react-redux";
import React, { useCallback, useMemo, useState } from "react";

import Chat from "../Chat";
import useFiltros from "./useFiltros";
import useColumns from "./useColumns";
import AbrirChamado from "../AbrirChamado";
import useCardOptions from "./useCardOptions";
import { ticketApi } from "../../../utility/api";
import ReprovarChamado from "../ReprovarChamado";
import Loading from "../../../components/Loading";
import FinalizarChamado from "../FinalizarChamado";
import Consulta from "../../../components/Consulta";
import ConfigurarChamado from "../ConfigurarChamado";
import VisualizarChamado from "../VisualizarChamado";
import useNotification from "../Chat/hooks/useNotification";
import useDownloadFile from "../../../hooks/useDownloadFile";
import useConsulta from "../../../components/Consulta/useConsulta";
import { actions, functions, useAuthorize } from "../../../hooks/useAthorize";
import {
  selectUser,
  selectUserDepartments,
  selectUserTeams,
  selectUserToken,
} from "../../../features/user";
import { useCategories } from "../../../hooks/fetch";

const ConsultaChamados = () => {
  const token = useSelector(selectUserToken);
  const user = useSelector(selectUser);
  const teams = useSelector(selectUserTeams);
  const departments = useSelector(selectUserDepartments);

  const { result: categories } = useCategories({});

  const allowAtendimento = useAuthorize(functions.ticket, actions.atender);

  const [isAbrirVisible, setIsAbrirVisible] = useState(false);
  const [examineTicketId, setExamineTicketId] = useState(undefined);
  const [configureTicketId, setConfigureTicketId] = useState(undefined);
  const [reproveTicketId, setReproveTicketId] = useState(undefined);
  const [chatTicket, setChatTicket] = useState(undefined);
  const [closeTicket, setCloseTicket] = useState({
    ticketId: undefined,
    isManager: false,
  });

  const filtros = useFiltros(departments, categories, user);
  const colunas = useColumns();

  const toggleNotification = useCallback((itemId, items, setter) => {
    let records = [...items.records];
    let index = -1;
    index = records.findIndex((record) => record.id === itemId);
    records[index].hasNotification = true;
    setter({ ...items, records });
  }, []);

  const onAfterReset = useCallback((form) => {
    const { reset, setValue } = form;

    reset({
      status: "",
      analyst: "",
      team: "",
      ticketNumberOrder: 0,
    });

    setValue("status", "");
    setValue("analyst", "");
    setValue("team", "");
    setValue("ticketNumberOrder", 0);
  }, []);

  let config = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: ticketApi.getListPaged,
      },
      onAfterReset,
    };
  }, [onAfterReset]);

  const cardOptions = useCardOptions({
    setExamineTicketId,
    setChatTicket,
    setConfigureTicketId,
    setCloseTicket,
    setReproveTicketId,
  });

  const consulta = useConsulta({
    token,
    config,
    cardOptions,
    defaultFields: {
      status: [2, 3, 4],
      analyst: allowAtendimento ? user.userName : undefined,
      team: allowAtendimento ? teams : undefined,
      ticketNumberOrder: 0,
    },
  });

  const { onSearch, items, setItems, isLoading } = consulta;

  useNotification(items, setItems, toggleNotification);

  const onFinished = useCallback(() => {
    onSearch();
  }, [onSearch]);

  const download = useDownloadFile("/Ticket/ExportExcel", "Chamados.xlsx");

  return (
    <div className="pb-16">
      {isLoading && <Loading />}

      {!isLoading && (
        <Consulta
          title="Chamados"
          colunas={colunas}
          filtros={filtros}
          consulta={consulta}
          filtroStyle="grid"
          onAdd={() => setIsAbrirVisible(true)}
          download={download}
        />
      )}

      {isAbrirVisible && (
        <AbrirChamado
          setIsVisible={setIsAbrirVisible}
          onFinished={onFinished}
        />
      )}

      {configureTicketId && (
        <ConfigurarChamado
          setIsVisible={setConfigureTicketId}
          ticketId={configureTicketId}
          onFinished={onFinished}
        />
      )}

      {examineTicketId && (
        <VisualizarChamado
          setIsVisible={setExamineTicketId}
          ticketId={examineTicketId}
        />
      )}

      {closeTicket?.ticketId && (
        <FinalizarChamado
          setCloseTicket={setCloseTicket}
          closeTicket={closeTicket}
          onFinished={onFinished}
        />
      )}

      {reproveTicketId && (
        <ReprovarChamado
          setIsVisible={setReproveTicketId}
          ticketId={reproveTicketId}
          onFinished={onFinished}
        />
      )}

      {chatTicket && (
        <Chat
          ticket={chatTicket}
          onClose={() => {
            setChatTicket(undefined);
            onSearch();
          }}
        />
      )}
    </div>
  );
};

export default ConsultaChamados;
