import { DivisionDropdown } from "./DivisionDropdown";
import MenuHeader from "./MenuHeader";
import UserInfo from "./UserInfo";

const Header = () => {
  return (
    <div className="fixed flex top-0 left-0 w-full shadow h-14 justify-between z-40 bg-white m-f1:h-12 t-f1:h-14">
      <MenuHeader>
        <DivisionDropdown />
      </MenuHeader>
      <UserInfo />
    </div>
  );
};
export default Header;
