import { clsx } from "clsx";

import Filtro from "./Filtro";
import Button, { buttonStyle } from "../../Button";
import FecharFiltro from "../../../assets/png/fechar-filtro.png";

const FiltroContainer = ({
  form,
  configuration,
  isFiltroVisible,
  toggleFiltro,
  resetFiltro,
  search,
  filtroStyle,
}) => {
  const { handleSubmit } = form;

  return (
    isFiltroVisible && (
      <form
        onSubmit={handleSubmit(search)}
        className={clsx(
          "py-5 px-10 relative border bg-white text-[#FFF] mt-10 rounded-lg",
          filtroStyle
        )}
      >
        <div className="flex items-center w-full justify-end gap-2">
          <Button
            onClick={resetFiltro}
            style={buttonStyle.darkGreen}
            className="min-w-0 text-xs h-auto py-2"
          >
            Limpar
          </Button>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => toggleFiltro(false)}
          >
            <img
              alt="Fechar filtro"
              src={FecharFiltro}
              className="w-[26px] h-[26px]"
            />
          </button>
        </div>
        <div className="w-full grid m-f9:grid-cols-3 gap-5 ">
          {configuration.map((filtro, index) => (
            <Filtro key={index} filtro={filtro} form={form} />
          ))}
        </div>
      </form>
    )
  );
};

export default FiltroContainer;
