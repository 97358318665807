import { useCallback, useMemo } from "react";

import useFetch from "./useFetch";
import { categoriaApi } from "../../utility/api";

export const useCategory = (form, categoryId) => {
  
  const data = useCallback(() => categoryId, [categoryId]);

  const watchFields = useMemo(() => ["departmentId"], []);

  return useFetch({
    endpoint: categoriaApi.getById,
    data,
    form,
    watchFields,
    doReset: true,
  });
};
