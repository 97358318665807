import clsx from "clsx";
import { useCallback, useState } from "react";
import SendIcon from "../../../../../assets/png/icon-send.png";
import AttachmentIcon from "../../../../../assets/svg/attachment.svg";

export const DefaultTextbox = ({
  onAttachmentClick,
  inputRef,
  onKeyUp,
  onSend,
  maxLength,
}) => {
  const [hasError, setHasError] = useState();

  const onChange = useCallback(
    (ev) => {
      let text = ev.target.value.trim().length;
      setHasError(text > maxLength);
    },
    [maxLength]
  );

  return (
    <div className="mx-4 w-full">
      <div
        className={clsx(
          "max-h-[80px] border rounded-md bg-white pl-4 pr-2 py-2 flex w-full",
          hasError ? "border-[#AF0505]" : "border-[#B3B3B3]"
        )}
      >
        <div className="flex content-center mr-2">
          <button
            type="button"
            className="cursor-pointer"
            onClick={onAttachmentClick}
          >
            <img
              alt="Adicionar anexo"
              src={AttachmentIcon}
              className="w-[20px] h-[20px]"
            />
          </button>
        </div>
        <div className="flex-auto">
          <textarea
            ref={inputRef}
            className="w-full max-h-[60px] resize-none placeholder:italic block bg-white rounded-md focus:outline-none font text-[12px]"
            placeholder="Digite aqui..."
            onKeyUp={onKeyUp}
            onChange={onChange}
          />
        </div>
        <div className="flex content-center ml-2">
          <button type="button" className="cursor-pointer" onClick={onSend}>
            <img alt="Enviar" src={SendIcon} className="w-[20px] h-[20px]" />
          </button>
        </div>
      </div>
      {hasError && (
        <span className="text-[#AF0505] text-[12px] my-[3px]">
          A mensagem não pode ter mais de {maxLength} caracteres
        </span>
      )}
    </div>
  );
};
