import api from "./api";

export const categoriaApi = {
  getDropdown: async (data, token) => {
    return api.get(`/Category/GetAllActives`, data, token);
  },
  getById: async (id, token) => {
    return api.get(`/Category/${id}`, null, token);
  },
  update: async (data, token) => {
    return api.put(`/Category/Update`, data, token);
  },
  getListPaged: async (data, token) => {
    return api.get(`/Category/GetListPaged`, data, token);
  },
  create: async (data, token) => {
    return api.post(`/Category/Create`, data, token);
  },
  delete: async (id, token) => {
    return api.delete(`/Category/${id}`, null, token);
  },
  activate: async (id, token) => {
    return api.patch(
      `/Category/${id}/IsDisabled`,
      { isDisabled: false },
      token
    );
  },
  inactivate: async (id, token) => {
    return api.patch(`/Category/${id}/IsDisabled`, { isDisabled: true }, token);
  },
};
