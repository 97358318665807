import clsx from "clsx";

import { columnType } from "../typing";
import CardTooltip from "./CardTooltip";
import Avatar from "../../../assets/png/Avatar-Padrao.png";
import Tippy from "@tippyjs/react";

const CardColumn = ({ configuration, item }) => {
  let {
    hasOverflow = true,
    value,
    prop,
    tipo,
    link,
    className,
    width,
    label,
    customLabel,
  } = configuration;
  let text = value ? value(item) : item[prop];

  let innerContent = (
    <div
      className={clsx(
        "text-left text-base font-semibold w-full",
        hasOverflow && "overflow-hidden text-ellipsis"
      )}
    >
      <CardTooltip item={item} configuration={configuration} text={text}>
        <span className="whitespace-nowrap overflow-hidden text-ellipsis">
          {text}
        </span>
      </CardTooltip>
    </div>
  );

  if (tipo === columnType.image) {
    innerContent = (
      <div
        className={clsx(
          "w-[50px] h-[50px] rounded-[50%] mr-8 border-2 relative",
          hasOverflow && "overflow-hidden text-ellipsis"
        )}
      > 
        <img
          alt="Sua foto"
          className={clsx(
            "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full max-w-none"
          )}
          src={item[prop] ?? Avatar} 
        />
      </div>
    );
  }

  let content = <div className="w-full flex">{innerContent}</div>;

  if (link) {
    content = (
      <button type="button" onClick={() => link(item)}>
        {innerContent}
      </button>
    );
  }

  label = customLabel ? customLabel(item) : label;

  return (
    <div
      className={clsx("flex pr-4 mb-2 m-f9:mb-0", className)}
      style={{
        width: width,
      }}
    >
      <div className="flex flex-col w-full">
        {label && (
          <Tippy content={label} className="block">
            <span className="text-sm text-[#8A92A6] overflow-hidden text-ellipsis whitespace-nowrap">
              {label}
            </span>
          </Tippy>
        )}
        {content}
      </div>
    </div>
  );
};

export default CardColumn;
