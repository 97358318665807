import clsx from "clsx";
import React from "react";
import { useCancelar } from "../Cancelar/useCancelar";
import Header from "./header";

export const Modal = ({
  title,
  children,
  onClose,
  className,
  containerClassName,
  childrenClassName,
  background = "bg-white",
  form,
}) => {
  const { onCancelarClick } = useCancelar({
    form,
    onReturn: onClose,
  });

  return (
    <div>
      <div
        className="fixed z-20 top-0 left-0 w-full h-full bg-[#69626271]"
        onClick={onCancelarClick}
      />
      <div
        className={clsx(
          "fixed z-30 top-12 m-f9:top-14 right-0 h-full w-full m-f9:w-1/2",
          className
        )}
      >
        <div
          className={clsx(
            "flex flex-col h-full elem translate-x-full",
            background
          )}
        >
          <div className="mx-4 flex flex-col h-full">
            <Header title={title} onClose={onClose} />
            <div
              className={clsx(
                "overflow-auto m-f9:max-h-[calc(100vh_-_130px)] max-h-[calc(100vh_-_110px)] grow mt-4 pb-3",
                containerClassName
              )}
            >
              <div
                className={clsx("flex flex-wrap gap-y-3", childrenClassName)}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
