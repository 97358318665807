import React, { useCallback, useEffect, useRef, useState } from "react";

import Input from "../Input";
import Label from "../Label";
import { dropdownPosition } from ".";
import { useClick } from "../../hooks/useClick";

const DropdownMultiple = React.forwardRef(
  (
    {
      placeholder,
      options: originalOptions,
      name,
      onChange,
      py = "py-2",
      width,
      label,
      required,
      position = dropdownPosition.Bottom,
      form,
    },
    ref
  ) => {
    const {
      register,
      watch,
      formState: { errors },
      getValues,
      setValue,
    } = form;

    const [isVisible, setVisibility] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [options, setOptions] = useState([]);

    const searchInput = useRef(null);
    const content = useRef(null);

    const sort = useCallback((options) => {
      let top = options.filter((item) => item.selected);
      let bottom = options.filter((item) => !item.selected);

      top.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      );
      bottom.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      );

      return [...top, ...bottom];
    }, []);

    const onSearch = useCallback(() => {
      let text = searchInput.current?.value;

      setSearchText(text);

      let tempOptions = options.map((item) => {
        return {
          ...item,
          isVisible: text ? item.text.search(new RegExp(text, "i")) >= 0 : true,
        };
      });

      setOptions(sort(tempOptions));
    }, [options, sort]);

    const onClickOutside = useCallback(
      (state) => {
        if (state) onSearch();
      },
      [onSearch]
    );

    useClick(content, isVisible, setVisibility, null, onClickOutside);

    const onSelectChange = useCallback(
      (index) => {
        let visibleOptions = [...options.filter((item) => item.isVisible)];
        let currentOptions = [...options];

        let option = visibleOptions[index];
        let currentIndex = options.indexOf(option);

        // Se o valor clicado já estiver sido selecionado, ele vai ser
        // deselecionado
        currentOptions[currentIndex] = {
          ...option,
          isVisible: true,
          selected: !option.selected,
        };

        // O setter de useState é assíncrono, por isso utilizar do valor de
        // "selectedOptions" não realiza-rá a ação esperada
        setOptions(currentOptions);
        setValue(
          name,
          currentOptions
            .filter((item) => item.selected)
            .map((item) => item.id),
          {
            shouldTouch: true,
            shouldValidate: true,
          }
        );
        onChange?.();
      },
      [options, name, onChange, setValue]
    );

    const reset = useCallback(() => {
      let values = getValues?.(name) || [];

      if (!Array.isArray(values)) values = [...values];

      values = values.map((item) => item.toString());

      setOptions(
        sort(
          originalOptions.map((item) => {
            return {
              ...item,
              selected: values.indexOf(item.id) >= 0 ? true : false,
              isVisible: true,
            };
          })
        )
      );
    }, [getValues, name, sort, originalOptions]);

    useEffect(() => {
      if (isVisible) {
        searchInput.current?.focus();
      }
    }, [isVisible]);

    useEffect(() => {
      reset();
    }, [reset]);

    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        // Quando name e type forem undefined quer dizer que o campo foi resetado
        if (name === undefined && type === undefined) reset();
      });
      return () => subscription.unsubscribe();
    }, [name, reset, watch]);

    const getTitle = () => {
      let selectedOptions = options.filter((item) => item.selected);

      if (selectedOptions.length === 0) return placeholder;

      if (selectedOptions.length === 1) return selectedOptions[0].text;

      return `${selectedOptions.length} opções selecionadas`;
    };

    const customRegister = register?.(name, {
      onChange: () => {
        onChange?.();
      },
      required: required && "Este campo não pode ficar vazio",
    });

    const customOnChange = (ev) => {
      customRegister.onChange?.(ev);
      onChange?.(ev);
    };

    return (
      <div className="relative flex flex-col">
        <input
          type="hidden"
          onChange={(ev) => customOnChange(ev)}
          name={name}
          ref={(el) => {
            if (customRegister) customRegister.ref(el);
            if (ref) ref.current = el;
          }}
        />
        <Label>{label}</Label>
        <div className="relative" ref={content}>
          <div
            onClick={() => setVisibility(true)}
            className={`${
              errors?.[name] ? "border-[#AF0505]" : "border-[#187733]"
            } relative flex cursor-pointer h-11 justify-between items-center pl-3 ${py} ${width} font-semibold text-white  border  rounded-md  focus:outline-none focus:shadow-outline w-full`}
          >
            <div className="text-[#8A92A6] font-normal text-xs overflow-hidden text-ellipsis">
              {getTitle()}
            </div>
            <svg
              className="ml-2 h-6 w-6 text-[#BBB]"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z" />
            </svg>
          </div>
          {isVisible && (
            <div
              className={`${
                position === dropdownPosition.Top
                  ? "bottom-full flex-col-reverse mb-2"
                  : "top-full flex-col"
              } absolute flex left-0 w-full text-left font-normal z-10`}
            >
              <div className="text-black my-2 shadow-lg">
                <Input ref={searchInput} onChange={() => onSearch()} value={searchText} />
              </div>

              <div
                className={`max-h-[200px] rounded-md bg-white ring-1 shadow-lg ring-black ring-opacity-5 overflow-y-auto`}
              >
                <ul className="text-black cursor-pointer">
                  {options.filter((item) => item.isVisible).length === 0 && (
                    <li className="text-gray-700 text-sm px-4 py-3 bg-[#ece6e6]">
                      Nenhuma opção encontrada
                    </li>
                  )}

                  {options
                    .filter((item) => item.isVisible)
                    ?.map((item, index) => (
                      <li
                        key={index}
                        className={`${
                          item.selected
                            ? "bg-[#187733] text-white"
                            : "text-gray-700 hover:bg-slate-100"
                        } block px-4 py-3 text-sm border-b `}
                        onClick={() => {
                          onSelectChange(index);
                        }}
                      >
                        {item.text}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        {errors?.[name] && (
          <span className="text-[#AF0505] text-[12px]">
            {errors?.[name].message}
          </span>
        )}
      </div>
    );
  }
);

export default DropdownMultiple;
