import React, { useCallback, useEffect, useRef, useState } from "react";

import Input from "../Input";
import { dropdownPosition } from ".";
import { useClick } from "../../hooks/useClick";
import Label from "../Label";
import clsx from "clsx";

const DropdownSingle = React.forwardRef(
  (
    {
      placeholder,
      options,
      name,
      onChange,
      py = "py-2",
      width,
      label,
      required,
      readOnly,
      defaultValue,
      form,
      hideSearchInput,
      position = dropdownPosition.Bottom,
      onFilter,
    },
    ref
  ) => {
    const {
      register,
      watch,
      formState: { errors },
      getValues,
      setValue,
    } = form ?? {
      formState: {},
    };
    const [isVisible, setVisibility] = useState(false);
    const [visibleOptions, setVisibleOptions] = useState(options);
    const [selectedOption, setSelectedOption] = useState(undefined);

    const searchInput = useRef(null);
    const content = useRef(null);

    useClick(content, isVisible, setVisibility);

    const onSearch = useCallback(() => {
      if (searchInput.current == null) return;

      let text = searchInput.current.value;

      if (onFilter) {
        onFilter(text);
        setVisibleOptions(options);
        return;
      }

      setVisibleOptions(
        text
          ? options.filter(
              (item) => item.text.search(new RegExp(text, "i")) >= 0
            )
          : options
      );
    }, [onFilter, options]);

    const onSelectChange = useCallback(
      (option, keepOption) => {
        if (!option) return;

        if (!keepOption && option?.id === selectedOption?.id) {
          option = {};
        }

        setSelectedOption(option);

        if (setValue) {
          setValue?.(name, option?.id, {
            shouldTouch: false,
            shouldValidate: true,
          });
        } else if (ref) {
          ref.current.value = option?.id;
        }

        onChange?.(option);
        setVisibility(false);
      },
      [name, onChange, ref, selectedOption, setValue]
    );

    const reset = useCallback(() => {
      let id = getValues?.(name) || defaultValue;

      if (!options) return;
      if (options?.length === 0) return;

      if (id !== null && id !== undefined) {
        let index = options.findIndex((option) => option.id === `${id}`);
        onSelectChange(options[index], true);
      } else {
        setSelectedOption(undefined);
        setValue?.(name, "");
      }
    }, [defaultValue, getValues, name, onSelectChange, options, setValue]);

    useEffect(() => {
      if (!options) return;

      if (defaultValue) {
        let index = options.findIndex(
          (option) => option.id === `${defaultValue}`
        );

        if (index < 0) return;

        setSelectedOption(options[index]);

        if (setValue) {
          setValue?.(name, options[index].id, {
            shouldTouch: false,
            shouldValidate: true,
          });
        } else if (ref) {
          ref.current.value = options[index].id;
        }
      }
    }, [defaultValue, name, options, ref, setValue]);

    useEffect(() => {
      onSearch(options);
    }, [onSearch, options]);

    useEffect(() => {
      if (isVisible) {
        searchInput.current?.focus();
        onSearch();
      }
    }, [isVisible, onSearch]);

    useEffect(() => {
      const subscription = watch?.((_, { name, type }) => {
        // Quando name e type forem undefined quer dizer que o campo foi resetado
        if (name === undefined && type === undefined) {
          reset?.();
        }
      });
      return () => subscription?.unsubscribe();
    }, [getValues, name, options, reset, watch]);

    const customRegister = register?.(name, {
      onChange: () => {
        onChange?.();
      },
      required: required && "Este campo não pode ficar vazio",
    });

    const customOnChange = (ev) => {
      customRegister.onChange?.(ev);
      onChange?.(ev);
    };

    const getBorderColor = useCallback(() => {
      let border = "border-[#187733]";

      if (readOnly) return "border-[#ccc] bg-[#f5f5f5]";
      else if (errors?.[name]) return "border-[#AF0505]";

      return border;
    }, [errors, name, readOnly]);

    return (
      <div className="flex flex-col">
        <Label>{label}</Label>
        <input
          type="hidden"
          onChange={(ev) => customOnChange(ev)}
          name={name}
          ref={(el) => {
            if (customRegister) customRegister.ref(el);
            if (ref) ref.current = el;
          }}
        />
        <div className="relative" ref={content}>
          <div
            onClick={() => {
              setVisibility(true);
            }}
            className={clsx(
              "relative flex cursor-pointer h-11 justify-between items-center pl-3",
              "font-semibold text-white  border  rounded-md  focus:outline-none focus:shadow-outline w-full",
              getBorderColor(),
              width,
              py
            )}
          >
            <div className="text-[#8A92A6] font-normal text-xs overflow-hidden text-ellipsis">
              {selectedOption && Object.keys(selectedOption).length > 0
                ? selectedOption.text
                : placeholder}
            </div>
            <svg
              className="ml-2 h-6 w-6 text-[#BBB]"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z" />
            </svg>
          </div>
          {isVisible && !readOnly && (
            <div
              className={clsx(
                "absolute flex left-0 w-full text-left font-normal z-10",
                position === dropdownPosition.Top
                  ? "bottom-full flex-col-reverse"
                  : "top-full flex-col"
              )}
            >
              <div className="text-black my-2 shadow-lg">
                {!hideSearchInput && (
                  <Input ref={searchInput} onChange={() => onSearch()} />
                )}
              </div>

              <div
                className={`max-h-[200px] mb-6 rounded-md bg-white ring-1 ring-black ring-opacity-5 shadow-lg overflow-y-auto`}
              >
                <ul className="text-black cursor-pointer">
                  {visibleOptions?.length === 0 && (
                    <li className="text-gray-700 text-sm px-4 py-3 bg-[#ece6e6]">
                      Nenhuma opção encontrada
                    </li>
                  )}

                  {visibleOptions?.map((item, index) => (
                    <li
                      key={index}
                      className={clsx(
                        "block px-4 py-3 text-sm border-b",
                        selectedOption?.id === item.id
                          ? "bg-[#187733] text-white"
                          : "text-gray-700 hover:bg-slate-100"
                      )}
                      onClick={() => {
                        onSelectChange(item);
                      }}
                    >
                      {item.text}
                    </li>
                  ))}

                  {onFilter && options.length >= 10 && (
                    <li
                      className={clsx(
                        "text-gray-700 hover:bg-slate-100 block px-4 py-3 text-sm border-b"
                      )}
                    >
                      Para visualizar mais resultados, por favor, realize uma
                      pesquisa
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
        {errors?.[name] && (
          <span className="text-[#AF0505] text-[12px]">
            {errors?.[name].message}
          </span>
        )}
      </div>
    );
  }
);

export default DropdownSingle;
