import React, { useEffect, useId, useRef, useState } from "react";

import OlhoAberto from "../../assets/png/olho-aberto.svg";
import OlhoFechado from "../../assets/png/olho-fechado.svg";
import { useMask } from "react-mask-field";
import Label from "../Label";
import clsx from "clsx";
import { useCallback } from "react";
import { useMemo } from "react";

const InputInline = React.forwardRef(
  (
    {
      label,
      name,
      type = "text",
      placeholder,
      value,
      readOnly,
      required,
      icon,
      button,
      className,
      validate,
      onChange,
      onBlur,
      onFocus,
      form,
      mask,
      defaultValue,
    },
    ref
  ) => {
    const id = useId();
    const [isVisible, setIsVisible] = useState(false);
    const { register, formState: { errors } = {} } = form || {};

    const maskRef = useMask(mask ?? {});
    const customRef = useRef();

    const customRegister = useMemo(() => {
      return (
        register?.(name, {
          validate: validate,
          value: value,
          required: required && "Este campo não pode ficar vazio",
        }) ?? {}
      );
    }, [name, register, required, validate, value]);

    const customOnChange = useCallback(
      (ev) => {
        customRegister.onChange?.(ev);
        onChange?.(ev);
      },
      [customRegister, onChange]
    );

    useEffect(() => {
      if (!customRef.current) return;

      customRef.current.addEventListener("keyup", (ev) => {
        customOnChange(ev);
      });
    }, [customOnChange]);

    const customOnBlur = (ev) => {
      if (customRegister) customRegister.onBlur?.(ev);
      onBlur?.(ev);
    };

    return (
      <div className="flex flex-col">
        {label && <Label id={id}>{label}</Label>}
        <div className="relative flex w-full">
          <input
            id={id}
            readOnly={readOnly}
            onChange={(ev) => customOnChange(ev)}
            onBlur={(ev) => customOnBlur(ev)}
            onFocus={(ev) => onFocus?.(ev)}
            ref={(el) => {
              customRef.current = el;
              if (customRegister) customRegister.ref?.(el);
              if (ref) ref.current = el;
              if (mask && maskRef) maskRef.current = el;
            }}
            name={name}
            value={!register ? value : undefined}
            defaultValue={defaultValue}
            className={clsx(
              "w-full rounded-md outline-none text-black text-xs overflow-hidden text-ellipsis",
              className,
              icon && "pl-10",
              readOnly
                ? "font-semibold text-base"
                : "border border-[#686868] pl-2 m-f2:py-2 d-f1:py-3 m-f1:py-1 h-11",
              errors?.[name] && "!border-[#AF0505]"
            )}
            type={isVisible ? "text" : type}
            placeholder={placeholder}
          />
          {icon && (
            <img
              className="absolute top-1/2 left-2 -translate-y-1/2"
              alt="Icone de Nome"
              src={icon}
            />
          )}
          {button}
          {type === "password" && (
            <button
              type="button"
              onClick={() => !readOnly && setIsVisible(!isVisible)}
              className="absolute bottom-0 right-0 h-full px-2 w-11 flex items-center justify-center bg-[#1D284A] rounded-r"
            >
              <img
                alt="Exibir senha"
                title="Exibir senha"
                src={isVisible ? OlhoAberto : OlhoFechado}
              />
            </button>
          )}
        </div>
        <div className="flex justify-start">
          {errors?.[name] && (
            <span className="text-[#AF0505] text-[12px] my-[3px]">
              {errors?.[name].message}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default InputInline;
