/*
 * Este é um código de validação que utiliza do design pattern builder para constuir uma
 * função de validação.
 * Basicamente você concatena cada validação que você deseja adicionar e no fim
 * você utiliza da função "validate" do objeto de tipo "Validator".
 *
 * A função atualmente retorna uma string para conseguir integrar com
 * a biblioteca react-form-hook.
 *
 * Exemplo:
 * let validator = new Validator.Builder().required().minLenght(5).build();
 * validator.validate('a') // Resulta em: "Este campo deve ter mais de 5 caracteres"
 *
 * Se estiver ruim, o responsável foi o: Emanuel Hiroshi Miyagawa.
 */

export class Validator {
  static Builder = class {
    validations = [];
    allowEmpty = true;

    required() {
      this.allowEmpty = false;

      this.validations.push((text) => {
        if (text.length === 0)
          return `Este campo não pode ficar vazio`;
        return null;
      });

      return this;
    }

    minLenght(length) {
      this.validations.push((text) => {
        if (text.length < length)
          return `Este campo deve ter mais de ${length} caracteres`;
        return null;
      });

      return this;
    }

    maxLenght(length) {
      this.validations.push((text) => {
        if (text.length > length)
          return `Este campo deve ter menos de ${length} caracteres`;
        return null;
      });

      return this;
    }

    hasNumber() {
      this.validations.push((text) => {
        const regex = /^(?=.*\d).+$/g;
        if (!regex.test(text)) return `Este campo deve ter ao menos um número`;
        return null;
      });

      return this;
    }

    hasUpperCase() {
      this.validations.push((text) => {
        const regex = /^(?=.*[A-Z]).+$/g;
        if (!regex.test(text))
          return `Este campo deve ter ao menos uma letra minúscula`;
        return null;
      });

      return this;
    }

    hasLowerCase() {
      this.validations.push((text) => {
        const regex = /^(?=.*[a-z]).+$/g;
        if (!regex.test(text))
          return `Este campo deve ter ao menos uma letra maiúscula`;
        return null;
      });

      return this;
    }

    hasSpecialCaracter() {
      this.validations.push((text) => {
        const regex = /^(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]).+$/g;
        if (!regex.test(text))
          return `Este campo deve ter ao menos um caractere especial`;
        return null;
      });

      return this;
    }

    validEmail() {
      this.validations.push((text) => {
        const regex = /^\w+([.-]?\w+)*@valgroupco.com$/g;
        if (!regex.test(text))
          return `Este campo deve ser um email valgroup válido`;
        return null;
      });

      return this;
    }

    validTime() {
      this.validations.push((text) => {
        const regex = /^(\d|0\d|1\d|2[0-3]):[0-5]\d$/g;
        if (!regex.test(text)) return `Este campo deve ser um horário válido`;
        return null;
      });

      return this;
    }

    validDate() {
      this.validations.push((text) => {
        const regex =
          /^(((0[1-9])|([12]\d)|(3[01]))\/((0\d)|(1[012]))\/((20[012]\d|19\d\d)|(1\d|2[0123])))$/g;
        if (!regex.test(text)) return `Este campo deve ser uma data válida`;
        return null;
      });

      return this;
    }

    customValidation(validationFunction) {
      this.validations.push(validationFunction);
      return this;
    }

    // when we're done setting arguments, we can call the build method
    // to give us the `Car` instance
    build() {
      const validator = new Validator(this.validations, this.allowEmpty);
      return validator;
    }
  };

  constructor(validations, allowEmpty) {
    this.validations = validations;
    this.allowEmpty = allowEmpty;
  }

  validate(text) {
    if (text === null || text?.length === 0) {
      if (this.allowEmpty) return undefined;
    }

    for (let validation of this.validations) {
      let result = validation(text);
      if (result) return result;
    }

    return undefined;
  }

  getValidationList(text) {
    let result = [];

    // Se for "required"
    if (!this.allowEmpty) {
      result.push({
        isOk: text.length !== 0,
        message: `Este campo não pode ficar vazio`,
      });
    }

    for (let validation of this.validations) {
      result.push({
        isOk: validation(text) === null,
        message: validation(""), // Com o texto vazio sempre teremos um erro e a função retornará o texto
      });
    }

    return result;
  }
}
