import informacaoNaoCadastradaImg from "../../assets/svg/informacao-nao-cadastrada.svg";

const InformacaoNaoCadastrada = () => {
  return (
    <div className="w-full mt-10 pb-10 flex justify-center">
      <img
        className="m-f9:max-w-[60%]"
        alt="Informação não cadastrada"
        src={informacaoNaoCadastradaImg}
      />
    </div>
  );
};

export default InformacaoNaoCadastrada;
