import Swal from "sweetalert2";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useCallback } from "react";

import Loading from "../../components/Loading";
import { timeApi, userApi } from "../../utility/api";
import { useQueryString } from "../../utility/useQueryString";
import { userFetchedTeams, userloggedIn } from "../../features/user";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = useQueryString();
  const accessToken = query.get("token");

  const showError = debounce(() => {
    Swal.fire({
      icon: "error",
      title: "Erro!",
      text: "Algo deu errado",
    }).then(() => {
      window.location.href = process.env.REACT_APP_Portal_URL;
    });
  }, 1000);

  const getTeams = useCallback(
    async (token) => {
      if (!token) return false;

      let result = await timeApi.getLoggedUserTeams(token);

      if (result.hasError) {
        showError();
        return false;
      }

      dispatch(userFetchedTeams(result.data));
      return true;
    },
    [dispatch, showError]
  );

  const getUserData = useCallback(async () => {
    let result = await userApi.getLoginData({ accessToken: accessToken, module: 'SCI' });

    if (result.hasError) {
      showError();
      return undefined;
    }

    dispatch(userloggedIn(result.data));
    return result.data;
  }, [accessToken, dispatch, showError]);

  const fetchData = useCallback(async () => {
    if (!accessToken) {
      showError();
      return;
    }

    let userData = await getUserData();
    let isTeamOk = await getTeams(userData?.token);

    if (isTeamOk && userData) navigate("/");
  }, [accessToken, getTeams, getUserData, navigate, showError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return <Loading />;
};

export default Login;
