const en = {
  ADD_CATEGORY: 'Add Category',
  ACTIVE: 'Active',
  ADD: 'Add',
  ADD_TEAM: 'Add Team',
  ALREADY_EXISTS: 'The {{field}} field already exists.',
  ANALYST: 'Analyst',
  ATTENTION: 'ATTENTION',
  ATTENTION_INFORMATION: 'All fields with the symbol "*" in front are mandatory.',

  CANCEL: 'Cancel',
  CATEGORIES: 'Categories',
  CATEGORY: "Category",
  CONCLUSION_DATE: "Conclusion date",
  CREATION_DATE: "Creation date",

  DEADLINE: 'Deadline',
  DELETE: 'Delete',
  DEPARTAMENT: 'Departament',
  DESCRIPTION: 'Description',
  DIVISION: 'Division',
  
  EDIT: 'Edit',
  EDIT_CATEGORY: 'Edit Category',
  EDIT_TEAM: 'Edit Team',
  EFFORT: 'Effort',

  FILTER: 'Filter',
  FILTER_BY: 'Filter by',

  GRAVITY: 'Gravity',

  INACTIVE: 'Inactive',

  LISTING: 'Listing',

  NAME: 'Name',
  NUMBER: 'Number',

  ORDER: 'Order',

  PLACEHOLDER_CATEGORY: 'Enter the category name',
  PLACEHOLDER_DEPARTAMENT: 'Search by department',
  PLACEHOLDER_DROPDOWN: 'Select...',
  PLACEHOLDER_NAME: 'Search by description',
  PLACEHOLDER_TEAM: 'Enter team name',
  PLACEHOLDER_TICKET: 'Enter the tip',

  REQUESTER: 'Requester',
  REQUIRED_FIELD_MESSAGE: 'The {{field}} field is required.',
  FAIL: 'Fail',

  SAVE: 'Save',
  SCORE: 'Score',
  SETOR: 'Information Technology',
  STATUS: 'Status',
  SUBJECT: "Subject",
  
  TEAM: 'Team',
  TEAMS: 'Teams',
  TICKET_TIP: 'Ticket tip',
  TICKET_INFORMATION: 'Ticket information',
  
  URGENCY: 'Urgency',
};

module.exports = en;
