import informacaoNaoEncontradaImg from "../../assets/png/informacao-nao-encontrada.png";

const InformacaoNaoEncontrada = () => {
  return (
    <div className="w-full mt-10 pb-10 flex justify-center">
      <img
        className="m-f9:max-w-[60%]"
        alt="Informação não encontrada"
        src={informacaoNaoEncontradaImg}
      />
    </div>
  );
};

export default InformacaoNaoEncontrada;
  