import React from "react";
import InputInline from "./InputInline";
import InputMultiline from "./InputMultiline";

const Input = React.forwardRef((props, ref) => {
  if (props.multiline) return <InputMultiline ref={ref} {...props} />;
  return <InputInline ref={ref} {...props} />;
});

export default Input;
