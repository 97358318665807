import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useCallback, useMemo } from "react";

import { ticketApi } from "../../../utility/api";
import { actions, functions, useAuthorize } from "../../../hooks/useAthorize";
import {
  selectUserDepartments,
  selectUserId,
  selectUserToken,
} from "../../../features/user";
import { ticketStatus } from "../../../utility/enums";

const useCardOptions = ({
  setExamineTicketId,
  setChatTicket,
  setConfigureTicketId,
  setCloseTicket,
  setReproveTicketId,
}) => {
  const token = useSelector(selectUserToken);
  const departments = useSelector(selectUserDepartments);

  const userId = useSelector(selectUserId);

  const allowConfiguracao = useAuthorize(functions.ticket, actions.configurar);
  const allowFinalizacao = useAuthorize(functions.ticket, actions.finalizar);
  const allowReprovacao = useAuthorize(functions.ticket, actions.reprovar);
  const allowAtendimento = useAuthorize(functions.ticket, actions.atender);
  const allowRetornar = useAuthorize(functions.ticket, actions.retornar);
  const allowFinalizacaoGestor = useAuthorize(
    functions.ticket,
    actions.finalizarGestor
  );

  const checkAnswer = useCallback(
    async (id, token, item) => {

      let department = departments.filter((d) => d.id === item.departmentId);

      const showError = () => {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          html: `Sem permissão. Contate um administrador`,
        });
      };

      if (department.length === 0) {
        showError();
        return;
      }

      department = department[0];

      if (department.teams?.length === 0) {
        showError();
        return;
      }

      return ticketApi.answer(id, token);
    },
    [departments]
  );

  const cardOptions = useMemo(() => {
    return {
      config: {
        hasAlert: (item) => item.hasNotification,
        hasWrap: true,
        hideStatus: true,
      },
      visualizar: {
        label: "Visualizar",
        action: (item) => setExamineTicketId(item.id),
      },
      chat: {
        label: "Chat",
        action: (item) => {
          setChatTicket(item);
          ticketApi.postChatMessageViewed({ ticketId: item.id }, token);
        },
        hasAlert: (item) => item.hasNotification,
      },
      configurar: {
        label: "Configurar",
        isVisible: (item) =>
          allowConfiguracao &&
          item.statusCode !== ticketStatus.closed &&
          item.statusCode !== ticketStatus.reproved,
        action: (item) => setConfigureTicketId(item.id),
      },
      finalizar: {
        label: "Finalizar",
        isVisible: (item) =>
          allowFinalizacao &&
          item.analystId === userId &&
          item.statusCode !== ticketStatus.closed &&
          item.statusCode !== ticketStatus.reproved,
        action: (item) =>
          setCloseTicket({
            ticketId: item.id,
            isManager: false,
          }),
      },
      finalizarGestor: {
        label: "Finalizar como gestor",
        isVisible: (item) =>
          allowFinalizacaoGestor &&
          item.analystId !== userId &&
          item.statusCode !== ticketStatus.closed &&
          item.statusCode !== ticketStatus.reproved,
        action: (item) =>
          setCloseTicket({
            ticketId: item.id,
            isManager: true,
          }),
      },
      reprovar: {
        label: "Reprovar",
        isVisible: (item) =>
          allowReprovacao &&
          (!item.analystId || item.analystId === userId) &&
          item.statusCode !== ticketStatus.reproved &&
          item.statusCode !== ticketStatus.closed,
        action: (item) => setReproveTicketId(item.id),
        icon: "info",
        title: "Reprovado!",
        questionText: "Tem certeza que deseja reprovar esse chamado?",
        successText: "Chamado cancelado",
      },
      atender: {
        label: "Atender",
        isVisible: (item) =>
          allowAtendimento && item.statusCode === ticketStatus.configured,
        fetchFunction: checkAnswer,
        questionText: "Tem certeza que deseja responder esse chamado?",
        successText: "Chamado alterado",
      },
      retornar: {
        label: "Retornar",
        isVisible: (item) =>
          allowRetornar && item.statusCode === ticketStatus.attending,
        fetchFunction: (id, token) => ticketApi.returnTicket(id, token),
        questionText: "Alerta!",
        questionHtml: (
          <>
            <p>Tem certeza que deseja retornar esse chamado?</p>
            <p>Isso irá remover o responsável pelo chamado</p>
          </>
        ),
        successText: "Chamado retornado com sucesso",
      },
    };
  }, [
    allowAtendimento,
    allowConfiguracao,
    allowFinalizacao,
    allowFinalizacaoGestor,
    allowReprovacao,
    allowRetornar,
    checkAnswer,
    setChatTicket,
    setCloseTicket,
    setConfigureTicketId,
    setExamineTicketId,
    setReproveTicketId,
    token,
    userId,
  ]);

  return cardOptions;
};

export default useCardOptions;
