import clsx from "clsx";
import React from "react";

import CardColumn from "./CardColumn";
import CardOptionContainer from "./CardOptionContainer";

export const cardBackground = {
  red: "bg-[#AF0011]",
  green: "bg-[#187733]",
};

const Card = ({ item, colunas, consulta }) => {
  const { cardOptions } = consulta;

  let gridConfig =
    "m-f9:grid-cols-[minmax(calc(100%_-_180px),_1fr)_minmax(180px,_180px)]";

  if (cardOptions.config?.hideStatus)
    gridConfig =
      "m-f9:grid-cols-[minmax(calc(100%_-_50px),_1fr)_minmax(50px,_300px)]";

  let visibleOptions = Object.keys(cardOptions).filter(
    (key) =>
      key !== "config" &&
      (cardOptions[key].isVisible ? cardOptions[key].isVisible(item) : true)
  );

  return (
    <div
      className={clsx(
        `w-full min-h-20 py-2 px-[2%] grid grid-cols-[1fr] items-center justify-center mt-2 border bg-white rounded-lg`,
        gridConfig
      )}
    >
      <div
        className={clsx(
          "flex relative w-full overflow-hidden",
          cardOptions.config?.hasWrap && "flex-wrap"
        )}
      >
        {colunas.map((coluna, index) => (
          <CardColumn key={index} configuration={coluna} item={item} />
        ))}

        <div className="flex justify-center m-f9:hidden absolute top-1 right-1">
          {visibleOptions.length > 0 && cardOptions && (
            <CardOptionContainer item={item} consulta={consulta} />
          )}
        </div>
      </div>
      <div className="justify-between content-center hidden m-f9:flex">
        {!cardOptions.config?.hideStatus && (
          <div className="flex">
            {item.hasOwnProperty("status") && (
              <div className="flex flex-col">
                <div className="text-[13px] text-[#8A92A6]">Status</div>
                <div>
                  <button
                    className={`${
                      item.status ? "bg-[#187733]" : "bg-[#AF0011] "
                    } text-[10px] py-1 px-1 font-semibold text-white rounded-md w-[50px]`}
                  >
                    {item.status ? "Ativo" : "Inativo"}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="flex justify-center">
          {visibleOptions.length > 0 && cardOptions && (
            <CardOptionContainer item={item} consulta={consulta} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
