const GreyBalloon = ({ children }) => {
  return (
    <div className="relative">
      <svg
        width="338"
        height="20"
        viewBox="0 0 338 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.77017 0.5H1.10495L5.14578 5.09851L5.14911 5.10238L8.26938 8.72732C8.97229 9.54391 9.35886 10.5856 9.35886 11.663V28.5V53C9.35886 54.933 10.9259 56.5 12.8589 56.5H334C335.933 56.5 337.5 54.933 337.5 53V4C337.5 2.067 335.933 0.5 334 0.5H22.4879H4.77017Z"
          fill="#E4E4E4"
          stroke="#D3D3D3"
        />
      </svg>
      <div
        className="bg-[#E4E4E4] border-[#D3D3D3] w-[329px] ml-[8.8px] px-[8px] leading-[10px]"
        style={{
          borderRadius: "0px 0px 6px 6px",
          borderWidth: "0px 1px 1px 1px",
        }}
      >
        <div className="leading-7 text-[10px] text-[#3E3E3E] font-semibold relative -top-[10px] break-words">
          {children}
        </div>
      </div>
    </div>
  );
};

export default GreyBalloon;
