import { useState, useCallback, useEffect } from "react";

const ChatLoading = () => {
  const [dots, setDots] = useState(0);

  const updateDots = useCallback(() => {
    if (dots === 3) setDots(0);
    else setDots(dots + 1);
  }, [dots]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateDots();
    }, 500);

    return () => clearInterval(intervalId);
  }, [updateDots]);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-50 flex justify-center`}
    >
      <div className="relative flex items-center justify-center ">
        <div className="w-[140px] h-[140px] border-b-4 border-[black] rounded-full animate-spin"></div>
        <span className="absolute text-black">
          Carregando{".".repeat(dots)}
        </span>
      </div>
    </div>
  );
};

export default ChatLoading;
