import { useCallback } from "react";
import { departamentoApi } from "../../utility/api";
import useFetch from "./useFetch";

export const useDepartment = ({ departmentId, form, prepareReset }) => {
  const data = useCallback(() => departmentId, [departmentId]);

  return useFetch({
    endpoint: departamentoApi.getById,
    data,
    form,
    doReset: true,
    prepareReset,
  });
};
