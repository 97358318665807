import clsx from "clsx";
import React from "react";
import GreyBalloon from "./GreyBallon";
import GreenBalloon from "./GreenBallon";
import { useSelector } from "react-redux";

import { FileContent } from "./FileContent";
import { selectUser } from "../../../../../features/user";

const formatMessageInfo = ({ dateTime, userName, useDate }) => {
  let hour = dateTime
    .getHours()
    .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

  let minute = dateTime
    .getMinutes()
    .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

  let day = dateTime
    .getDay()
    .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

  let month = dateTime
    .getMonth()
    .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

  let year = dateTime
    .getFullYear()
    .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

  return clsx(
    `${hour}:${minute}`,
    useDate && `${day}/${month}/${year}`,
    userName && ` - ${userName}`
  );
};

const Message = React.forwardRef(
  (
    {
      date,
      userName,
      hasError,
      errorMessage,
      id: messageId,
      userSenderId,
      isFile,
      ticketId,
      message,
      isLoading,
      ticketFileId,
    },
    ref
  ) => {
    const user = useSelector(selectUser);
    const isMine = user.userId === userSenderId;

    let content = message;
    let Container = GreyBalloon;

    if (isMine) {
      Container = GreenBalloon;
    }

    if (isFile) {
      content = (
        <FileContent
          isLoading={isLoading}
          hasError={hasError}
          errorMessage={errorMessage}
          messageId={messageId}
          ticketId={ticketId}
          fileName={message}
          ticketFileId={ticketFileId}
        />
      );
    }

    return (
      <div
        data-content={message}
        data-id={messageId}
        ref={ref}
        className={clsx("mb-4", isMine && "grid justify-items-end")}
      >
        <div className="w-[50%] text-right">
          {hasError && (
            <span className="text-[#E86F51] text-[10px] mr-2">
              {errorMessage || "Falha ao enviar mensagem"}
            </span>
          )}
          <span className="text-[#9E9E9E] text-[10px]">
            {formatMessageInfo({ dateTime: new Date(date), useDate: false })}
          </span>
        </div>
        <Container>{content}</Container>
      </div>
    );
  }
);

export default Message;
