import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userloggedIn(_, action) {
      return {
        ...action.payload,
      };
    },
    userFetchedTeams(state, action) {
      return {
        ...state,
        teams: action.payload,
      };
    },
    userLoggedOut() {
      return {};
    },
  },
});

export const { userloggedIn, userLoggedOut, userFetchedTeams } =
  usersSlice.actions;

export const selectUserToken = (state) => state.user.token;

export const selectUserDivisions = (state) =>
  state.user.data.divisions.map((item) => {
    return { id: item.id, text: item.name };
  });

export const selectCurrentUserDivision = (state) =>
  state.user.data.divisions.filter(
    (item) => item.id === state.user.data.defaultDivisionId
  )[0] ?? state.user.data.divisions[0];

export const selectUserDepartments = (state) =>
  state.user.data.departments || [];

export const selectUserTeams = (state) => state.user.teams;

export const selectUser = (state) => state.user;

export const selectUserId = (state) => state.user.userId;

export const selectUserDivisionId = (state) =>
  state.user.data.defaultDivisionId;

export const selectModules = (state) => state.user.data.modules;

export default usersSlice.reducer;
