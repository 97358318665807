import { useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";

import Input from "../../components/Input";
import Body from "../../components/Modal/body";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { Modal } from "../../components/Modal";
import { useSubmit } from "../../hooks/useSubmit";
import { departamentoApi } from "../../utility/api";
import { CancelarButton } from "../../components/Cancelar/CancelarButton";
import Button, { buttonStyle } from "../../components/Button";
import { useDepartment } from "../../hooks/fetch";
import InformationGroupName from "../../components/InformationGroupName";
import Toggle from "../../components/Toggle";
import Label from "../../components/Label";
import { Validator } from "../../utility/validations";

const EditarDepartamento = ({ departmentId, onFinished, setDepartmentId }) => {
  const { t } = useTranslation();

  const form = useForm({
    defaultValues: {
      score: 0,
      gravity: null,
      urgency: null,
    },
  });

  const prepareReset = useCallback((result) => {
    const { data } = result;

    let weekStart = data.weekStart || "07:30";
    let weekEnd = data.weekEnd || "17:30";

    return { ...data, weekStart, weekEnd };
  }, []);

  const { result: department, isLoading } = useDepartment({
    departmentId,
    form,
    prepareReset,
  });

  const { onSubmit } = useSubmit({
    endpoint: departamentoApi.update,
    form,
    onSuccess: onFinished,
  });

  let weekStart = useMemo(
    () => department.weekStart || "07:30",
    [department.weekStart]
  );

  let weekEnd = useMemo(
    () => department.weekEnd || "07:30",
    [department.weekEnd]
  );

  let fridayStart = useMemo(
    () => department.fridayStart || "07:30",
    [department.fridayStart]
  );

  let fridayEnd = useMemo(
    () => department.fridayEnd || "16:30",
    [department.fridayEnd]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Modal
      title="Editar Departamento"
      onClose={() => setDepartmentId(undefined)}
      width="w-[50%]"
      form={form}
    >
      <Body>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid grid-cols-2 gap-4"
        >
          <div className="col-span-2">
            <Input
              name="description"
              label={t("DESCRIPTION") + "*"}
              form={form}
              className="max-h-40"
              readOnly
              defaultValue={department.description}
            />
          </div>

          <div>
            <Input
              name="ramal"
              label="Ramal"
              form={form}
              readOnly
              defaultValue={department.ramal}
            />
          </div>

          <div>
            <Label>Tem SCI?</Label>
            <Toggle
              form={form}
              name="HasSCI"
              defaultValue={department.hasSCI}
            />
          </div>

          <div>
            <InformationGroupName
              Name="Jornada de Tabalho"
              className="leading-[30px] mb-3"
            />

            <div className="flex mb-3">
              <label className="min-w-[90px] mr-4 mt-2">Seg. a Quinta</label>
              <Input
                form={form}
                name="weekStart"
                required
                className="w-[110px]"
                defaultValue={weekStart}
                mask={{ mask: "__:__", replacement: { _: /\d/ } }}
                validate={(text) =>
                  new Validator.Builder().validTime().build().validate(text)
                }
              />
              <label className="whitespace-nowrap mx-4 mt-2 min-w-[20px] text-center">
                a
              </label>
              <Input
                form={form}
                name="weekEnd"
                defaultValue={weekEnd}
                mask={{ mask: "__:__", replacement: { _: /\d/ } }}
                required
                className="w-[110px]"
                validate={(text) =>
                  new Validator.Builder().validTime().build().validate(text)
                }
              />
            </div>

            <div className="flex mb-3">
              <label className="min-w-[90px] mr-4 mt-2">Sexta</label>
              <Input
                form={form}
                name="fridayStart"
                required
                className="w-[110px]"
                defaultValue={fridayStart}
                mask={{ mask: "__:__", replacement: { _: /\d/ } }}
                validate={(text) =>
                  new Validator.Builder().validTime().build().validate(text)
                }
              />
              <label className="whitespace-nowrap mx-4 mt-2 min-w-[20px] text-center">
                a
              </label>
              <Input
                form={form}
                name="fridayEnd"
                defaultValue={fridayEnd}
                mask={{ mask: "__:__", replacement: { _: /\d/ } }}
                required
                className="w-[110px]"
                validate={(text) =>
                  new Validator.Builder().validTime().build().validate(text)
                }
              />
            </div>

            <div className="flex mb-3">
              <label className="min-w-[90px] mr-4 mt-2">Sábado</label>
              <Input
                form={form}
                name="saturdayStart"
                defaultValue={department.saturdayStart}
                mask={{ mask: "__:__", replacement: { _: /\d/ } }}
                className="w-[110px]"
                validate={(text) =>
                  new Validator.Builder().validTime().build().validate(text)
                }
              />
              <label className="whitespace-nowrap mx-4 mt-2 min-w-[20px] text-center">
                a
              </label>
              <Input
                form={form}
                name="saturdayEnd"
                defaultValue={department.saturdayEnd}
                mask={{ mask: "__:__", replacement: { _: /\d/ } }}
                className="w-[110px]"
                validate={(text) =>
                  new Validator.Builder().validTime().build().validate(text)
                }
              />
            </div>

            <div className="flex mb-3">
              <label className="min-w-[90px] mr-4 mt-2">Domingo</label>
              <Input
                form={form}
                name="sundayStart"
                defaultValue={department.sundayStart}
                mask={{ mask: "__:__", replacement: { _: /\d/ } }}
                className="w-[110px]"
                validate={(text) =>
                  new Validator.Builder().validTime().build().validate(text)
                }
              />
              <label className="whitespace-nowrap mx-4 mt-2 min-w-[20px] text-center">
                a
              </label>
              <Input
                form={form}
                name="sundayEnd"
                defaultValue={department.sundayEnd}
                mask={{ mask: "__:__", replacement: { _: /\d/ } }}
                className="w-[110px]"
                validate={(text) =>
                  new Validator.Builder().validTime().build().validate(text)
                }
              />
            </div>
          </div>

          <div className="mt-5 flex justify-between col-span-2">
            <CancelarButton
              form={form}
              onReturn={() => setDepartmentId(undefined)}
            />
            <Button style={buttonStyle.darkGreen} type="submit">
              Salvar
            </Button>
          </div>
        </form>
      </Body>
    </Modal>
  );
};

export default EditarDepartamento;
