import api from "./api";

export const accountApi = {
  login: async (data) => {
    return api.post(`/Account/Authenticate`, data);
  },
  changeDivision: async (data, token) => {
    return api.get(`​/Account/ChangeDivision`, data, token, {
      serverUrl: process.env.REACT_APP_Portal_API_URL,
    });
  },
};
