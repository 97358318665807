import IconeExcluir from "../../assets/png/excluir-icone.png";
import { clsx } from "clsx";
import React from "react";

const getFileName = (file) => {
  return file.name.split(".").slice(0, -1).join(".");
};

const getExtension = (file) => {
  return "." + file.name.split(".").at(-1);
};

const FileContainer = ({
  arquivos,
  adicionarArquivos,
  removerArquivo,
  fileInputRef,
}) => {
  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        multiple
        onChange={() => {
          adicionarArquivos(fileInputRef.current.files);
        }}
      />
      <div className={clsx(!arquivos.length && "hidden")}>
        <label>Lista de Arquivos</label>
        <div className="border border-black rounded-md">
          <div className="overflow-auto max-h-[160px] pt-2">
            {arquivos.map((arquivo, index) => (
              <React.Fragment key={index}>
                <div className="grid grid-cols-[1.25rem_calc(100%_-_0.5rem)] items-center gap-3 px-4 justify-between m-f9:justify-start">
                  <button
                    type="button"
                    className="min-w-[1.25rem] w-5 max-w-[1.25rem]"
                    onClick={() => removerArquivo(index)}
                  >
                    <img
                      className="max-h-8"
                      alt="Apagar arquivo"
                      src={IconeExcluir}
                    />
                  </button>
                  <div className="max-w-[calc(100%_-_1.5rem)] grid grid-cols-[1fr_max-content]">
                    <span className="font-bold text-sm text-right whitespace-nowrap overflow-hidden text-ellipsis">
                      {getFileName(arquivo)}
                    </span>
                    <span className="font-bold text-sm text-right">
                      {getExtension(arquivo)}
                    </span>
                  </div>
                </div>
                <div className="col-span-2 border-b my-2 mx-4 border-black last:border-0"></div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileContainer;
