import React from "react";
import { Link } from "react-router-dom";

import LogoValgroupColorido from "../../../assets/png/logo-valgroup.png";
import LogoSci from "../../../assets/png/logo-sci.png";
import LogoValgroupMin from "../../../assets/png/Valgroup Logo - min.png";

const MenuHeader = ({ children }) => {
  return (
    <div className="flex py-3 px-3">
      <Link to="/" className="flex items-center ">
        <img
          className="max-h-8 m-f9:hidden"
          alt="Logo Valgroup"
          src={LogoValgroupMin}
        />
        <img
          className="max-h-8 m-f9:block hidden"
          alt="Logo Valgroup"
          src={LogoValgroupColorido}
        />
      </Link>
      <div className="h-full ml-2 border-r-2 border-black"></div>
      <div className="flex">
        <div className="flex m-f9:py-1 ml-2">
          <img className="max-h-12" alt="Logo SCI" src={LogoSci} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default MenuHeader;
