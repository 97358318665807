import imageClose from "../../assets/png/fechar-filtro.png";

const Header = ({ title, onClose }) => {
  return (
    <>
      <div className="relative w-full py-4 flex items-centerfont-bold text-xl">
        <div className="font-semibold">{title}</div>
        <button
          type="button"
          className="absolute right-0 cursor-pointer"
          onClick={onClose}
        >
          <img alt="Close" src={imageClose} className="w-7 h-7" />
        </button>
      </div>
      <div className="w-full border-t border-[#B3B3B3] " />
    </>
  );
};
export default Header;
