import { HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";

const useNotification = (items, setItems, toggleNotification) => {
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_CHAT_URL)
      .withAutomaticReconnect()
      .build();

    setConnection(connection);
    if (connection._connectionState === "Disconnected") connection.start();
  }, []);

  useEffect(() => {
    if (!connection) return;

    connection.on("ReceiveNotification", (itemId) => {
      toggleNotification(itemId, items, setItems);
    });

    return () => {
      connection.off("ReceiveNotification");
    };
  }, [connection, items, setItems, toggleNotification]);
};

export default useNotification;
