import clsx from "clsx";
import { Link } from "react-router-dom";

const ArrowLeft = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0668 16.3777C16.1555 16.2908 16.2259 16.1871 16.274 16.0726C16.3221 15.9581 16.3469 15.8352 16.3469 15.7111C16.3469 15.5869 16.3221 15.464 16.274 15.3495C16.2259 15.235 16.1555 15.1313 16.0668 15.0444L10.8279 9.99994L16.0446 4.9555C16.2082 4.77826 16.2979 4.54509 16.2951 4.30385C16.2923 4.06261 16.1973 3.83158 16.0296 3.65817C15.8618 3.48475 15.6341 3.3821 15.3931 3.37128C15.1521 3.36045 14.9161 3.44227 14.7335 3.59995L8.11123 9.99994L14.7335 16.4055C14.8226 16.4917 14.9279 16.5594 15.0433 16.6048C15.1587 16.6501 15.2819 16.6721 15.4059 16.6695C15.5299 16.6669 15.6521 16.6398 15.7655 16.5897C15.879 16.5396 15.9814 16.4676 16.0668 16.3777Z"
        fill="white"
      />
      <path
        d="M5.28334 17.2222C5.53382 17.2222 5.77404 17.1227 5.95116 16.9455C6.12828 16.7684 6.22778 16.5282 6.22778 16.2777L6.22778 3.72217C6.22778 3.47169 6.12828 3.23146 5.95116 3.05434C5.77404 2.87723 5.53382 2.77772 5.28334 2.77772C5.03286 2.77772 4.79263 2.87723 4.61551 3.05434C4.4384 3.23146 4.33889 3.47169 4.33889 3.72217L4.33889 16.2777C4.33889 16.5282 4.4384 16.7684 4.61552 16.9455C4.79263 17.1227 5.03286 17.2222 5.28334 17.2222Z"
        fill="white"
      />
    </svg>
  );
};

const ArrowRight = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.93321 3.62228C3.84452 3.70918 3.77406 3.8129 3.72596 3.92738C3.67785 4.04185 3.65308 4.16477 3.65308 4.28894C3.65308 4.41311 3.67785 4.53604 3.72596 4.65051C3.77406 4.76499 3.84452 4.86871 3.93321 4.95561L9.1721 10.0001L3.95543 15.0445C3.79176 15.2217 3.70212 15.4549 3.70491 15.6961C3.7077 15.9374 3.8027 16.1684 3.97043 16.3418C4.13816 16.5152 4.36589 16.6179 4.60691 16.6287C4.84792 16.6396 5.08394 16.5577 5.26655 16.4001L11.8888 10.0001L5.26655 3.5945C5.17744 3.50828 5.07214 3.44056 4.95672 3.39525C4.8413 3.34994 4.71806 3.32794 4.59409 3.33053C4.47012 3.33311 4.3479 3.36022 4.23447 3.4103C4.12104 3.46037 4.01865 3.53242 3.93321 3.62228Z"
        fill="white"
      />
      <path
        d="M14.7167 2.77783C14.4662 2.77783 14.226 2.87734 14.0488 3.05445C13.8717 3.23157 13.7722 3.47179 13.7722 3.72228V16.2778C13.7722 16.5283 13.8717 16.7685 14.0488 16.9457C14.226 17.1228 14.4662 17.2223 14.7167 17.2223C14.9671 17.2223 15.2074 17.1228 15.3845 16.9457C15.5616 16.7685 15.6611 16.5283 15.6611 16.2778V3.72228C15.6611 3.47179 15.5616 3.23157 15.3845 3.05445C15.2074 2.87734 14.9671 2.77783 14.7167 2.77783Z"
        fill="white"
      />
    </svg>
  );
};

const Arrow = ({ page, children, className }) => {
  return (
    <Link to={`?page=${page}`}>
      <li
        className={clsx(
          "flex items-center h-full justify-center py-1.5 m-f9:px-3 px-2",
          "border-0 bg-transparent outline-none focus:shadow-none bg-[#9C9C9C] cursor-pointer",
          className
        )}
      >
        {children}
      </li>
    </Link>
  );
};

const Page = ({ pageIndex, pageLabel, currentPage }) => {
  return (
    <Link to={`?page=${pageIndex}`}>
      <li
        className={clsx(
          "m-f9:min-w-[2.5rem] py-1.5 m-f9:px-3 px-2 transition-all h-full whitespace-nowrap",
          "duration-300 cursor-pointer flex justify-center items-center",
          pageIndex === currentPage
            ? "hover:text-white text-white bg-[#1B2646] hover:bg-blue-800"
            : "hover:bg-gray-200 text-[#B9ADAD]"
        )}
      >
        {pageLabel || pageIndex + 1}
      </li>
    </Link>
  );
};

const Pagination = ({
  maxPages = 5,
  currentPage,
  recordsTotal,
  recordsFiltered,
}) => {
  // Se houver somente uma página,
  // não é necessário exibir a paginação
  if (recordsTotal <= recordsFiltered) {
    return <></>;
  }

  let pageQuantity = Math.ceil(recordsTotal / recordsFiltered);
  let pages = Array.from({ length: pageQuantity }, (_, i) => i);

  let previousPage = currentPage - 1;
  let nextPage = currentPage + 1;

  let firstVisiblePage = currentPage - Math.floor(maxPages / 2);
  let lastVisiblePage = currentPage + Math.ceil(maxPages / 2);

  // Se firstVisiblePage for menor que 0,
  // então podemos exibir mais páginas no final e.g.:
  // 1 2 3 4 5 ...10
  if (firstVisiblePage < 0) {
    lastVisiblePage += Math.abs(firstVisiblePage);
    firstVisiblePage = 0;
  }

  // Se lastVisiblePage for maior que a quantidade
  // total de páginas, podemos exibir mais páginas
  // do começo e.g.:
  // ...1 16 17 18 19 20
  if (lastVisiblePage > pageQuantity) {
    firstVisiblePage -= Math.abs(lastVisiblePage - pageQuantity);
    lastVisiblePage = pageQuantity;
  }

  // Mas se tivermos menos de 6 páginas no total,
  // essa lógica vai quebrar, e por isso temos que
  // ter mais um fail safe
  if (firstVisiblePage < 0) firstVisiblePage = 0;
  if (lastVisiblePage > pageQuantity) lastVisiblePage = pageQuantity;

  pages = pages.slice(firstVisiblePage, lastVisiblePage);

  let hasFirstPage = pages.indexOf(0) === -1;
  let hasLastPage = pages.indexOf(pageQuantity - 1) === -1;

  if (previousPage < 0) {
    previousPage = pageQuantity - 1;
  }

  if (nextPage > pageQuantity - 1) {
    nextPage = 0;
  }

  return (
    <div className="flex justify-center m-f9:justify-end">
      <nav className="border rounded-lg">
        <ul className="flex list-style-none m-f9:min-h-[3rem]">
          <Arrow page={previousPage} className="rounded-l-lg">
            <ArrowLeft />
          </Arrow>

          {hasFirstPage && (
            <Page currentPage={currentPage} pageIndex={0} pageLabel="1..." />
          )}

          {pages.map((pagina, index) => (
            <Page key={index} currentPage={currentPage} pageIndex={pagina} />
          ))}

          {hasLastPage && (
            <Page
              currentPage={currentPage}
              pageIndex={pageQuantity - 1}
              pageLabel={`...${pageQuantity}`}
            />
          )}

          <Arrow page={nextPage} className="rounded-r-lg">
            <ArrowRight />
          </Arrow>
        </ul>
      </nav>
    </div>
  );
};
export default Pagination;
