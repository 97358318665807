import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import Input from "../../components/Input";
import { ticketApi } from "../../utility/api";
import Loading from "../../components/Loading";
import Dropdown from "../../components/Dropdown";
import { useSubmit } from "../../hooks/useSubmit";
import { selectUserToken } from "../../features/user";
import { Validator } from "../../utility/validations";
import Button, { buttonStyle } from "../../components/Button";
import {
  useCategories,
  useDepartamentos,
  useTimes,
  useUsers,
} from "../../hooks/fetch";
import clsx from "clsx";
import { CancelarButton } from "../../components/Cancelar/CancelarButton";
import { Modal } from "../../components/Modal";

const ConfigurarChamado = ({ setIsVisible, ticketId, onFinished }) => {
  const form = useForm();
  const { reset, setValue, getValues, watch } = form;

  const token = useSelector(selectUserToken);

  const [filterUserName, setFilterUserName] = useState(null);
  const [category, setCategory] = useState({});
  const [ticket, setTicket] = useState(undefined);

  const { result: departments, isLoading: isDepartamentosLoading } =
    useDepartamentos(form);

  const { result: categories, isLoading: isCategoryLoading } = useCategories({
    form,
  });

  const { result: teams, isLoading: isTeamsLoading } = useTimes(form);

  const { result: users, islo: isUsersLoading } = useUsers(
    form,
    filterUserName,
    true
  );

  const scoreOptions = useMemo(
    () => [
      { id: "1", text: "Baixo" },
      { id: "2", text: "Médio" },
      { id: "3", text: "Alto" },
    ],
    []
  );

  const fetchChamado = useCallback(async () => {
    let result = await ticketApi.getConfiguration(ticketId, token);

    if (result.hasErro) {
      Swal.fire({
        icon: "error",
        text: "Algo deu errado",
      });
      return;
    }

    setTicket(result.data);
    reset({ ...result.data });
    setCategory(result.data);
  }, [reset, ticketId, token]);

  useEffect(() => {
    fetchChamado();
  }, [fetchChamado]);

  const prepareData = useCallback(
    (data) => {
      return {
        ...data,
        id: ticketId,
      };
    },
    [ticketId]
  );

  const onSuccess = useCallback(() => {
    onFinished?.();
    setIsVisible(undefined);
  }, [onFinished, setIsVisible]);

  const { onSubmit, isLoading } = useSubmit({
    form,
    endpoint: ticketApi.configure,
    prepareData,
    onSuccess,
  });

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name === "gravity" || name === "urgency") {
        let gravity = parseInt(getValues("gravity"));
        let urgency = parseInt(getValues("urgency"));

        let score = gravity * urgency;

        if (isNaN(score)) score = 0;

        if (!isNaN(score)) setValue("score", score);
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, setValue, watch]);

  if (
    isDepartamentosLoading ||
    isCategoryLoading ||
    isTeamsLoading ||
    isUsersLoading
  ) {
    return <Loading />;
  }

  return (
    <Modal
      title={`Configurar Chamado`}
      onClose={() => setIsVisible(false)}
      form={form}
    >
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-wrap gap-y-3 m-f9:grid m-f9:grid-cols-2 m-f9:gap-x-3 w-full pb-20"
      >
        <div className="order-1 m-f9:order-1">
          <Input name="number" label="Número" form={form} readOnly />
        </div>

        <div className="order-6 w-full m-f9:order-2">
          <Dropdown
            name="departmentId"
            form={form}
            options={departments}
            label="Departamento"
            readOnly
            defaultValue={category.departmentId}
          />
        </div>

        <div className="order-2 m-f9:order-3 m-f9:col-span-2">
          <Input name="subject" label="Assunto" form={form} readOnly />
        </div>

        <div className="order-3 w-full m-f9:order-4 m-f9:col-span-2">
          <Input
            name="description"
            label="Descrição"
            form={form}
            multiline
            readOnly
          />
        </div>

        <div className="order-7 w-full m-f9:order-5">
          <Dropdown
            name="categoryId"
            form={form}
            options={categories}
            label="Categoria"
            defaultValue={category.categoryId}
          />
        </div>

        <div className="order-4 w-full m-f9:order-6">
          <Input
            name="occuredTime"
            label="Hora do Ocorrido"
            form={form}
            readOnly
          />
        </div>

        <div className="order-8 w-full m-f9:order-7">
          <Dropdown
            name="teamId"
            form={form}
            options={teams}
            label="Time"
            defaultValue={category.teamId}
          />
        </div>

        <div className="order-9 w-full m-f9:order-8">
          <Dropdown
            name="analystId"
            form={form}
            options={users}
            label="Analista"
            onFilter={setFilterUserName}
            defaultValue={category.analystId}
          />
        </div>

        <div className="order-10 w-full m-f9:order-9">
          <Dropdown
            name="gravity"
            form={form}
            options={scoreOptions}
            label="Gravidade"
            defaultValue={category.gravity}
          />
        </div>

        <div className="order-11 w-full m-f9:order-10">
          <Dropdown
            name="urgency"
            form={form}
            options={scoreOptions}
            label="Urgência"
            defaultValue={category.urgency}
          />
        </div>

        <div className="order-5 w-full m-f9:order-11">
          <Input name="score" label="Score" form={form} readOnly />
        </div>

        <div className="order-12 w-full m-f9:order-12">
          <Input
            name="deadline"
            label="Prazo"
            mask={{
              mask: "__/__/____",
              replacement: { _: /\d/ },
            }}
            form={form}
            required
            validate={(text) => {
              return new Validator.Builder().validDate().build().validate(text);
            }}
            defaultValue={ticket?.deadline}
          />
        </div>

        <div className="order-[13] w-full m-f9:flex justify-between col-span-2 grid grid-cols-2 gap-10">
          <CancelarButton
            form={form}
            onReturn={() => setIsVisible(false)}
            className="w-auto min-w-0 m-f9:min-w-[11rem] h-auto py-2"
          />

          <div className="flex justify-center">
            <Button
              style={buttonStyle.darkGreen}
              type="submit"
              isLoading={isLoading}
              className={clsx(
                "min-w-0 m-f9:min-w-[11rem] h-auto py-2",
                isLoading ? "w-auto" : "w-full"
              )}
            >
              Salvar
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ConfigurarChamado;
