import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userLoggedOut } from "../features/user";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userLoggedOut());
    window.location.replace(process.env.REACT_APP_Portal_URL);
  }, [dispatch]);

  return <></>;
};

export default Logout;
