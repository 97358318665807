import clsx from "clsx";
import React, { useCallback, useEffect, useId, useState, useRef } from "react";

import OlhoAberto from "../../assets/png/olho-aberto.svg";
import OlhoFechado from "../../assets/png/olho-fechado.svg";
import Label from "../Label";

const InputMultiline = React.forwardRef(
  (
    {
      label,
      name,
      type = "text",
      value,
      placeholder,
      validate,
      readOnly,
      disabled,
      onChange,
      onFocus,
      required,
      className,
      icon,
      button,
      form,
      defaultValue,
      onBlur,
    },
    ref
  ) => {
    const id = useId();
    const textareRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const { register, watch, formState: { errors } = {} } = form || {};

    const customRegister =
      register?.(name, {
        validate: validate,
        value: value,
        required: required && "Este campo não pode ficar vazio",
      }) ?? {};

    const customOnChange = (ev) => {
      customRegister.onChange?.(ev);
      onTextChange();
      onChange?.(ev);
    };

    const customOnBlur = (ev) => {
      if (customRegister) customRegister.onBlur?.(ev);
      onBlur?.(ev);
    };

    const onTextChange = useCallback((value) => {
      textareRef.current.value = value || textareRef.current.value || "";
      textareRef.current.style.height = `1px`;
      let height = textareRef.current.scrollHeight + 5;
      textareRef.current.style.height = `calc(${height}px)`;
    }, []);

    useEffect(() => {
      onTextChange();
    }, [onTextChange]);

    useEffect(() => {
      if (!form) return;

      const subscription = watch((value, { name: watchName, type }) => {
        if (
          watchName === name ||
          (watchName === undefined && type === undefined)
        ) {
          onTextChange(value[name]);
        }
      });
      return () => subscription.unsubscribe();
    }, [form, name, onTextChange, watch]);

    return (
      <div className="flex flex-col">
        {label && <Label id={id}>{label}</Label>}
        <div className="relative w-full flex">
          <textarea
            id={id}
            readOnly={readOnly || disabled}
            defaultValue={defaultValue}
            onChange={(ev) => customOnChange(ev)}
            onBlur={(ev) => customOnBlur(ev)}
            onFocus={(ev) => onFocus?.(ev)}
            ref={(el) => {
              if (customRegister) customRegister.ref?.(el);
              if (ref) ref.current = el;

              textareRef.current = el;
            }}
            name={name}
            className={clsx(
              "w-full rounded-md text-xs outline-none resize-none max-h-52",
              className,
              icon && "pl-10",
              readOnly
                ? "font-semibold text-base"
                : "px-2 border border-[#686868] pl-2 m-f2:py-2 d-f1:py-3 h-11",
              disabled && "bg-[#D1CCCC] opacity-[30%] text-[#4c4444]",
              errors?.[name] && "!border-[#AF0505]"
            )}
            placeholder={disabled ? "" : placeholder}
          ></textarea>
          {icon && (
            <img
              className="absolute top-1/2 left-2 -translate-y-1/2"
              alt="Icone de Nome"
              src={icon}
            />
          )}
          {button}
          {type === "password" && (
            <button
              type="button"
              onClick={() => !readOnly && setIsVisible(!isVisible)}
              className="absolute bottom-0 right-0 h-full px-2 w-11 flex items-center justify-center bg-[#1D284A] rounded-r"
            >
              <img
                alt="Exibir senha"
                title="Exibir senha"
                src={isVisible ? OlhoAberto : OlhoFechado}
              />
            </button>
          )}
        </div>
        <div className="flex justify-start">
          {errors?.[name] && (
            <span className="text-[#AF0505] text-[12px] my-[3px]">
              {errors[name].message}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default InputMultiline;
