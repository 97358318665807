import { useForm } from "react-hook-form";
import { useCallback } from "react";

import Input from "../../components/Input";
import Loading from "../../components/Loading";
import Body from "../../components/Modal/body";
import { useTranslation } from "react-i18next";
import Dropdown from "../../components/Dropdown";
import { useSubmit } from "../../hooks/useSubmit";
import { categoriaApi } from "../../utility/api/";
import Button, { buttonStyle } from "../../components/Button";
import { useDepartamentos, useTimes } from "../../hooks/fetch";
import InformationGroupName from "../../components/InformationGroupName";
import useScore from "./useScore";
import { Validator } from "../../utility/validations";
import { Modal } from "../../components/Modal";
import { CancelarButton } from "../../components/Cancelar/CancelarButton";

const AdicionarCategoria = ({ setIsVisible, onFinished }) => {
  const { t } = useTranslation();

  const form = useForm({
    defaultValues: {
      score: 0,
      gravity: null,
      urgency: null,
    },
  });

  const { result: times, isLoading: isTimesLoading } = useTimes(form);
  const { result: departamentos, isLoading: isDepartamentosLoading } =
    useDepartamentos();

  const prepareData = useCallback((data) => {
    return {
      ...data,
      gravity: data.gravity ? data.gravity : undefined,
      urgency: data.urgency ? data.urgency : undefined,
    };
  }, []);

  const { onSubmit, isLoading } = useSubmit({
    prepareData,
    endpoint: categoriaApi.create,
    form,
    onSuccess: onFinished,
  });

  const { nivelOptions } = useScore(form);

  if (isTimesLoading || isDepartamentosLoading) {
    return <Loading />;
  }

  return (
    <Modal
      title={t("ADD_CATEGORY")}
      onClose={() => setIsVisible(false)}
      width="w-[50%]"
      form={form}
    >
      <Body>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid grid-cols-3 gap-4"
        >
          <div className="col-span-3">
            <Dropdown
              name="departmentId"
              form={form}
              options={departamentos}
              label={t("DEPARTAMENT") + "*"}
              placeholder={t("PLACEHOLDER_DROPDOWN")}
              required
            />
          </div>
          <div className="col-span-3">
            <Input
              name="description"
              label={t("DESCRIPTION") + "*"}
              form={form}
              className="max-h-40"
              required
              placeholder={t("PLACEHOLDER_CATEGORY")}
              validate={(text) =>
                new Validator.Builder()
                  .required()
                  .maxLenght(100)
                  .build()
                  .validate(text)
              }
            />
          </div>

          <InformationGroupName
            className="col-span-3"
            Name={t("TICKET_INFORMATION")}
          />

          <div className="col-span-2">
            <Input
              name="ticketDescription"
              label={t("TICKET_TIP")}
              form={form}
              className="max-h-40"
              placeholder={t("PLACEHOLDER_TICKET")}
              multiline
              validate={(text) =>
                new Validator.Builder()
                  .maxLenght(500)
                  .build()
                  .validate(text)
              }
            />
          </div>

          <Dropdown
            name="teamId"
            form={form}
            options={times}
            label="Time"
            placeholder={t("PLACEHOLDER_DROPDOWN")}
            readOnly={!form.getValues("departmentId")}
          />

          <Dropdown
            name="gravity"
            form={form}
            options={nivelOptions}
            label={t("GRAVITY")}
            placeholder={t("PLACEHOLDER_DROPDOWN")}
          />
          <Dropdown
            name="urgency"
            form={form}
            options={nivelOptions}
            label={t("URGENCY")}
            placeholder={t("PLACEHOLDER_DROPDOWN")}
          />
          <Input
            name="score"
            form={form}
            options={nivelOptions}
            label="Score"
            placeholder="0"
            readOnly
          />
          <Input
            name="leadTime"
            form={form}
            label="Lead Time"
            placeholder="00:00"
            mask={{ mask: "__:__", replacement: { _: /\d/ } }}
          />

          <p className="text-red-700 col-span-3 text-[11px]">
            <b>{t("ATTENTION")}</b>: {t("ATTENTION_INFORMATION")}
          </p>

          <div className="mt-5 flex justify-between col-span-3">
            <CancelarButton form={form} onReturn={() => setIsVisible(false)} />

            <Button
              style={buttonStyle.darkGreen}
              isLoading={isLoading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>
      </Body>
    </Modal>
  );
};
export default AdicionarCategoria;
