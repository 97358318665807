import { useCallback, useMemo } from "react";
import { categoriaApi } from "../../utility/api/";
import useFetch from "./useFetch";

export const useCategories = ({ form }) => {
  const { getValues } = form ?? {};

  const data = useCallback(() => {
    return {
      departmentId: getValues?.("departmentId"),
    };
  }, [getValues]);

  const watchFields = useMemo(() => ["departmentId"], []);

  const prepareResult = useCallback(
    (result) => {
      if (getValues && !getValues("departmentId")) return [];

      return result;
    },
    [getValues]
  );

  return useFetch({
    endpoint: categoriaApi.getDropdown,
    data,
    form,
    watchFields,
    prepareResult,
  });
};
