import { useCallback, useState, useEffect } from "react";

const useQueue = () => {
  const [queue, setQueue] = useState({
    isProcessing: false,
    tasks: [],
  });

  const enqueue = useCallback((task) => {
    setQueue((prev) => ({
      isProcessing: prev.isProcessing,
      tasks: [...prev.tasks, task],
    }));
  }, []);

  useEffect(() => {
    if (queue.tasks.length === 0) return;
    if (queue.isProcessing) return;

    const task = queue.tasks[0];
    setQueue((prev) => ({
      isProcessing: true,
      tasks: prev.tasks.slice(1),
    }));

    Promise.resolve(task()).finally(() => {
      setQueue((prev) => ({
        isProcessing: false,
        tasks: prev.tasks,
      }));
    });
  }, [queue]);

  return { enqueue };
};

export default useQueue;
