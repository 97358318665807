import axios from "axios";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { selectUserToken } from "../features/user";

const useDownloadFile = (url, fileName, data) => {
  const token = useSelector(selectUserToken);

  const download = useCallback(async () => {
    let server = process.env.REACT_APP_API_URL;

    let response;

    try {
      response = await axios({
        method: "GET",
        data,
        url: server + url,
        responseType: "blob",
        headers: {
          token: token,
        },
      });
    } catch (ex) {
      if (response.hasError) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Algo deu errado."
        });
        return;
      }
    }

    const href = URL.createObjectURL(response.data);

    // create "a" HTLM element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
  }, [data, fileName, token, url]);

  return download;
};

export default useDownloadFile;
