import { HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";

export const useChat = ({
  ticket,
  token,
  onReceiveJoinMessage,
  onReceiveNewMessage,
  onReceiveOldMessage,
  onHasLoadedAll,
}) => {
  const [messages, setMessages] = useState([]);
  const [connection, setConnection] = useState(null);
  const [hasLoadedAll, setHasLoadedAll] = useState(false);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_CHAT_URL)
      .withAutomaticReconnect()
      .build();

    setConnection(connection);
    if (connection._connectionState === "Disconnected") {
      connection.start().then(() => {
        connection?.invoke("JoinChat", ticket.id, token);
      });
    }
  }, [ticket.id, token]);

  useEffect(() => {
    if (!connection) return;

    connection.on("ReceiveJoinMessage", (message) => {
      setMessages((array) => [...array, message]);
      onReceiveJoinMessage?.();
    });

    connection.on("ReceiveNewMessage", (message) => {
      setMessages((array) => [...array, message]);
    });

    connection.on("ReceiveOldMessage", (message) => {
      setMessages((array) => [...array, message]);
    });

    connection.on("HasLoadedAll", () => {
      setHasLoadedAll(true);
    });

    return () => {
      connection.off("ReceiveJoinMessage");
      connection.off("ReceiveNewMessage");
      connection.off("ReceiveOldMessage");
      connection.off("HasLoadedAll");
    };
  }, [
    connection,
    messages,
    ticket.id,
    token,
    onReceiveJoinMessage,
  ]);

  return {
    connection,
    messages,
    setMessages,
    hasLoadedAll,
  };
};
