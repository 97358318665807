import clsx from "clsx";

export const ReadonlyTextBox = ({ className }) => {
  return (
    <div className={clsx("bg-white border-[#FDF3F3] py-1 px-10 w-full fixed bottom-0 translate-y-[calc(-100%_-_0.5rem)]", className)}>
      <p className="text-[#B4B4B4] text-xs m-f9:text-sm text-center">
        Você não pode enviar mensagens para essa pessoa porque o chamado já foi
        finalizado.
      </p>
    </div>
  );
};
