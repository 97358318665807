import { useSelector } from "react-redux";
import { useMemo, useState, useCallback } from "react";

import Consulta from "../../components/Consulta";
import Dropdown from "../../components/Dropdown";
import { departamentoApi } from "../../utility/api/";
import { selectUserToken } from "../../features/user";
import EditarDepartamento from "./EditarDepartamento";
import useConsulta from "../../components/Consulta/useConsulta";
import {
  NotAllowed,
  useAuthorize,
  functions,
  actions,
} from "../../hooks/useAthorize";
import { useTranslation } from "react-i18next";

const ConsultaDepartamentos = () => {
  const token = useSelector(selectUserToken);
  const { t } = useTranslation();

  const allowRead = useAuthorize(functions.department, actions.read);
  const allowUpdate = useAuthorize(functions.department, actions.update);
  const allowAtivarInativar = useAuthorize(
    functions.department,
    actions.active
  );

  const [departmentId, setDepartmentId] = useState(undefined);

  const options = useMemo(
    () => [
      { id: 1, text: "Sim" },
      { id: 0, text: "Não" },
    ],
    []
  );

  const filtros = [
    {
      label: "Departamento",
      placeholder: "Pesquise pelo departamento",
      name: "description",
    },
    {
      element: (form) => (
        <Dropdown
          form={form}
          name="hasSCI"
          label="Filtrar por"
          options={options}
          placeholder="Selecione..."
        />
      ),
    },
  ];

  const colunas = [
    {
      label: "Departamento",
      prop: "description",
      width: "50%",
    },
    {
      label: "Tem SCI?",
      prop: "hasSCI",
      width: "50%",
      className: "",
      value: (item) => (item.hasSCI ? "Sim" : "Não"),
      background: (item) => (item.hasSCI ? "bg-[#187733]" : "bg-[#AF0000]"),
    },
  ];

  let config = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: departamentoApi.getListPaged,
      },
    };
  }, []);

  const cardOptions = useMemo(() => {
    return {
      editar: {
        label: t("EDIT"),
        isVisible: () => allowUpdate,
        action: (item) => setDepartmentId(item.id),
      },
      ativar: {
        label: "Ativar SCI",
        isVisible: (item) => !item.hasSCI && allowAtivarInativar,
        questionText: "Tem certeza que deseja ativar?",
        fetchFunction: (id, token) => departamentoApi.activateSci(id, token),
      },
      inativar: {
        label: "Inativar SCI",
        isVisible: (item) => item.hasSCI && allowAtivarInativar,
        questionText: "Tem certeza que deseja inativar?",
        fetchFunction: (id, token) => departamentoApi.inactivateSci(id, token),
      },
    };
  }, [allowAtivarInativar, allowUpdate, t]);

  const consulta = useConsulta({ token, config, cardOptions });

  const onFinished = useCallback(() => {
    consulta.onSearch();
    setDepartmentId(undefined);
  }, [consulta]);

  if (!allowRead) return <NotAllowed />;

  return (
    <div className="w-full min-h-screen">
      <Consulta
        title="Departamentos"
        colunas={colunas}
        filtros={filtros}
        consulta={consulta}
      />

      {departmentId && allowUpdate && (
        <EditarDepartamento
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          onFinished={onFinished}
        />
      )}
    </div>
  );
};

export default ConsultaDepartamentos;
