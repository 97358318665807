import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useState, useEffect, useCallback, useMemo } from "react";

import { deepCopy } from "../../utility/util";
import { useNavigate } from "react-router-dom";
import { useQueryString } from "../../utility/useQueryString";
import useQueue from "../../hooks/useQueue";
import { useDispatch } from "react-redux";
import { updatePagination } from "../../features/pagination";
import withReactContent from "sweetalert2-react-content";

const useConsulta = ({ token, config, cardOptions, defaultFields }) => {
  let query = useQueryString();
  let pagina = parseInt(query.get("page") ?? 0);
  const [quantity, setQuantity] = useState(5);

  const mySwal = useMemo(() => withReactContent(Swal), []);

  const dispatch = useDispatch();

  const {
    onSearch: { fetchFunction },
    onBeforeReset,
    onAfterReset,
  } = config;
  const { enqueue } = useQueue();

  const form = useForm({
    mode: "onChange",
    defaultValues: defaultFields,
  });

  const { watch, getValues } = form;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState({
    recordsFiltered: quantity,
    recordsTotal: 0,
    records: [],
  });

  const onSearch = useCallback(
    async (onFinished) => {
      if (!token) return;

      enqueue(async () => {
        let data = {
          ...getValues(),
          page: pagina,
          take: quantity,
        };

        let response = await fetchFunction(data, token);

        setTimeout(() => {
          setIsLoading(false);
        }, 250);

        if (response.hasError) {
          mySwal.fire({
            title: "Erro!",
            text: response.error?.response?.data?.message || "Algo deu errado",
            icon: "error",
          });
        } else {
          setItems(response.data);

          let quantidadeMaximaPaginas =
            response.data.recordsTotal / response.data.recordsFiltered;

          if (quantidadeMaximaPaginas < pagina) navigate("?page=0");

          dispatch(
            updatePagination({
              length: response.data.records.length,
              recordsTotal: response.data.recordsTotal,
              recordsFiltered: response.data.recordsFiltered,
            })
          );

          onFinished?.();
        }
      });
    },
    [
      token,
      enqueue,
      getValues,
      pagina,
      quantity,
      fetchFunction,
      mySwal,
      navigate,
      dispatch,
    ]
  );

  useEffect(() => {
    onSearch();
    const subscription = watch((_, { type, name }) => {
      if (type === "keyup" || type === "change" || name) onSearch();
    });
    return () => subscription.unsubscribe();
  }, [onSearch, watch]);

  const cardOptionsWrapper = useMemo(() => {
    let temp = deepCopy(cardOptions);

    let keys = Object.keys(temp);

    for (let key of keys) {
      if (!Boolean(temp[key].fetchFunction)) continue;

      temp[key].action = async (item, onSearch) => {
        mySwal
          .fire({
            icon: temp[key].icon || "info",
            title: temp[key].questionText,
            html: temp[key].questionHtml,
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: `Não`,
          })
          .then(async (swalResult) => {
            if (swalResult.isConfirmed) {
              let result = await temp[key].fetchFunction(item.id, token, item);

              if (result.hasError) {
                let text = "Algo deu errado";

                if (result.error.response.data.notifications)
                  text = result.error.response.data.notifications[0].message;

                Swal.fire({
                  title: "Erro!",
                  text: text,
                  icon: "error",
                });
              } else {
                (temp[key].successFunction &&
                  temp[key].successFunction(item, onSearch)) ||
                  onSearch(() => {
                    Swal.fire({
                      title: temp[key].title || "Sucesso!",
                      text: temp[key].successText,
                      icon: temp[key].icon || "success",
                    });
                  });
              }
            }
          });
      };
    }

    return temp;
  }, [cardOptions, mySwal, token]);

  return {
    onBeforeReset,
    onAfterReset,
    isLoading,
    form,
    setItems,
    items,
    pagina,
    onSearch,
    cardOptions: cardOptionsWrapper,
    quantity,
    setQuantity,
  };
};

export default useConsulta;
