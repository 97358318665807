import api from "./api";

export const ticketApi = {
  postChatMessageViewed: async (data, token) => {
    return api.post(`/Ticket/PostChatMessageViewed`, data, token);
  },
  getListPaged: async (data, token) => {
    return api.get(`/Ticket/GetListPaged`, data, token);
  },
  create: async (data, token) => {
    return api.post(`/Ticket/Create`, data, token);
  },
  configure: async (data, token) => {
    return api.put(
      `/Ticket/Configure`,
      {
        ...data,
        gravity: data.gravity ? data.gravity : null,
        urgency: data.urgency ? data.urgency : null,
      },
      token
    );
  },
  answer: async (id, token) => {
    return api.put(`/Ticket/Answer`, { id }, token);
  },
  getById: async (id, token) => {
    return api.get(`/Ticket/${id}`, null, token);
  },
  examine: async (id, token) => {
    return api.get(`/Ticket/Examine`, { id }, token);
  },
  getConfiguration: async (id, token) => {
    return api.get(`/Ticket/GetConfiguration`, { id }, token);
  },
  getDisapprove: async (id, token) => {
    return api.get(`/Ticket/GetDisapprove`, { id }, token);
  },
  disapprove: async (data, token) => {
    return api.put(`/Ticket/Disapprove`, data, token);
  },
  getClose: async (id, token) => {
    return api.get(`/Ticket/GetClose`, { id }, token);
  },
  getManagerClose: async (id, token) => {
    return api.get(`/Ticket/GetManagerClose`, { id }, token);
  },
  close: async (data, token) => {
    return api.put(`/Ticket/Close`, data, token);
  },
  managerClose: async (data, token) => {
    return api.put(`/Ticket/ManagerClose`, data, token);
  },
  downloadTicketFile: async (data, token, config) => {
    return api.get(`/Ticket/DownloadTicketFile`, data, token, config);
  },
  uploadTicketMessageFile: async (data, token) => {
    return api.post(`/Ticket/UploadTicketMessageFile`, data, token);
  },
  returnTicket: async (ticketId, token) => {
    return api.put(`/Ticket/Return?ticketId=${ticketId}`, undefined, token);
  },
};
