import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo, useState } from "react";

import Card from "./Card";
import Loading from "../Loading";
import Pagination from "../Pagination";
import FiltroContainer from "./Filtros";
import excelImg from "../../assets/png/excel.png";
import InformacaoNaoEncontrada from "./InformacaoNaoEncontrada";
import InformacaoNaoCadastrada from "./NenhumaInformacaoCadastrada";
import Dropdown from "../Dropdown";

const Consulta = ({
  title,
  colunas,
  filtros,
  adicionarLink,
  adicionarButton,
  onAdd,
  consulta,
  filtroStyle,
  download,
}) => {
  const {
    form,
    items,
    pagina,
    cardOptions,
    onSearch,
    onBeforeReset,
    onAfterReset,
    setQuantity,
  } = consulta;

  const { reset, getValues } = form;
  const { t } = useTranslation();

  const [isFiltroVisible, setIsFiltroVisible] = useState(false);

  const toggleFiltro = useCallback((isVisible) => {
    setIsFiltroVisible(isVisible);
  }, []);

  const resetFiltro = useCallback(() => {
    if (isFiltroVisible) {
      onBeforeReset?.(form);
      reset();
      onAfterReset?.(form);

      onSearch();
    }
  }, [form, isFiltroVisible, onAfterReset, onBeforeReset, onSearch, reset]);

  const hasFiltro = useCallback(() => {
    return (
      Object.values(getValues()).filter((item) => Boolean(item)).length > 0
    );
  }, [getValues]);

  const onQuantitySelecChanged = useCallback(
    (value) => {
      setQuantity(parseInt(value.id));
    },
    [setQuantity]
  );

  const quantityOptions = useMemo(
    () => [
      { id: '5', text: "5" },
      { id: '10', text: "10" },
      { id: '15', text: "15" },
      { id: '50', text: "50" },
    ],
    []
  );

  if (consulta.isLoading) return <Loading />;

  return (
    <>
      {/* Versão Tablet e Desktop */}
      <div className="flex justify-between m-f1:hidden t-f1:flex">
        <div className="flex items-center">
          <div className="text-[28px] font-semibold mr-4 text-[#1B2646]">
            {title}
          </div>
          <div>
            {onAdd && (
              <button
                className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
                onClick={onAdd}
              >
                {t("ADD")}
              </button>
            )}
            {adicionarButton ??
              (adicionarLink && (
                <Link
                  to={adicionarLink}
                  className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
                >
                  Adicionar
                </Link>
              ))}
          </div>
        </div>
        {filtros && (
          <div>
            <button
              className="bg-[#005BBF] text-[#FFF] mt-1 py-1 px-10 rounded-md hover:bg-[#1E3784]"
              onClick={() => toggleFiltro(!isFiltroVisible)}
            >
              {t("FILTER")}
            </button>
          </div>
        )}
      </div>

      {/* Versão Mobile */}
      <div className="text-[28px] font-semibold mr-4 text-[#1B2646] t-f1:hidden">
        {title}
      </div>

      <div className="flex justify-between mt-3 t-f1:hidden">
        {onAdd && (
          <button
            className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
            onClick={onAdd}
          >
            {t("ADD")}
          </button>
        )}
        {adicionarButton ??
          (adicionarLink && (
            <Link
              to={adicionarLink}
              className="bg-[#005BBF] text-[#FFF] py-1 px-5 my-0 rounded-md hover:bg-[#1E3784]"
            >
              Adicionar
            </Link>
          ))}
        {filtros && (
          <div>
            <button
              className="bg-[#005BBF] text-[#FFF] py-1 px-10 rounded-md hover:bg-[#1E3784]"
              onClick={() => toggleFiltro(!isFiltroVisible)}
            >
              Filtrar
            </button>
          </div>
        )}
      </div>

      {filtros && (
        <FiltroContainer
          filtroStyle={filtroStyle}
          form={form}
          configuration={filtros}
          isFiltroVisible={isFiltroVisible}
          toggleFiltro={toggleFiltro}
          resetFiltro={resetFiltro}
          search={onSearch}
        />
      )}

      {items.records?.length > 0 && (
        <div className="flex justify-between mt-10">
          <h1 className="text-[#1B2646] font-medium text-[20px]">
            {t("LISTING")}
          </h1>

          <div className="flex items-center gap-2">
            {download && (
              <button onClick={download} className="h-[35px]">
                <img alt="Download" src={excelImg} />
              </button>
            )}

            <div>
              <Dropdown
                defaultValue={5}
                hideSearchInput={true}
                onChange={onQuantitySelecChanged}
                options={quantityOptions}
              />
            </div>
          </div>
        </div>
      )}

      <div className="min-h-80 pb-6">
        {items.records?.length === 0 &&
          (hasFiltro() ? (
            <InformacaoNaoEncontrada />
          ) : (
            <InformacaoNaoCadastrada />
          ))}

        {items.records?.map((item, index) => (
          <Card
            key={index}
            item={item}
            colunas={colunas}
            cardOptions={cardOptions}
            onSearch={onSearch}
            consulta={consulta}
          />
        ))}
      </div>

      {items.records?.length > 0 &&
        items.recordsTotal > items.recordsFiltered && (
          <div className="w-full items-center justify-center pb-6 hidden m-f9:justify-end m-f9:flex">
            <Pagination
              currentPage={pagina}
              recordsTotal={items.recordsTotal}
              recordsFiltered={items.recordsFiltered}
            />
          </div>
        )}
    </>
  );
};

export default Consulta;
