import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  length: 0,
  recordsTotal: 0,
  recordsFiltered: 0,
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    resetPagination() {
      return initialState;
    },
    updatePagination(_, action) {
      return {
        ...action.payload,
      };
    },
  },
});

export const { resetPagination, updatePagination } = paginationSlice.actions;

export const selectPagination = (state) => state.pagination;

export default paginationSlice.reducer;
