const index = ({ Name, className }) => {
  return (
    <div className={`h-[35px] flex ${className}`}>
      <div className="w-[1.5%] h-full bg-[#1B2646]"></div>
      <div className="w-[98.5%] h-full py-1 pl-3 bg-[#F5F5F5] font-semibold text-[#1B2646]">
        {Name}
      </div>
    </div>
  );
};
export default index;
