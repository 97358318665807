const es = {
  ADD_CATEGORY: 'Añadir Categoría',
  ACTIVE: 'Activo',
  ADD: 'Agregar',
  ADD_TEAM: 'Add team',
  ALREADY_EXISTS:  'The {{field}} field already exists.',
  ANALYST: 'Analista',
  ATTENTION: 'ATENCIÓN',
  ATTENTION_INFORMATION: 'ATENCIÓN: Todos los campos con el símbolo "*" delante son obligatorios.',

  CANCEL: 'Cancel',
  CATEGORIES: 'Categorías',
  CATEGORY: "Categoría",
  CONCLUSION_DATE: "Fecha de finalizacion",
  CREATION_DATE: "Fecha de creación",

  DEADLINE: 'Plazo',
  DELETE: 'Borrar',
  DEPARTAMENT: 'Departamento',
  DESCRIPTION: 'Descripción',
  DIVISION: 'División',
  
  EDIT: 'Para editar',
  EDIT_CATEGORY: 'Editar Categoria',
  EDIT_TEAM: 'Editar Equipo',
  EFFORT: 'Esfuerzo',

  FILTER: 'Filtrar',
  FILTER_BY: 'Filtrado por',

  GRAVITY: 'Gravedad',

  INACTIVE: 'Inactivo',
  
  LISTING: 'Listado',

  NAME: 'Nombre',
  NUMBER: 'Número',

  ORDER: 'Ordernar',

  PLACEHOLDER_CATEGORY: 'Introduce el nombre de la categoría',
  PLACEHOLDER_DEPARTAMENT: 'Buscar por departamento',
  PLACEHOLDER_DROPDOWN: 'Seleccione...',
  PLACEHOLDER_NAME: 'Buscar por descripción',
  PLACEHOLDER_TEAM: 'Introduce el nombre del equipo',
  PLACEHOLDER_TICKET: 'Introduce la punta',

  REQUESTER: 'Solicitante',
  REQUIRED_FIELD_MESSAGE: 'The {{field}} field is required.',
  FAIL: 'Fallar',

  SAVE: 'Save',
  SCORE: 'Puntaje',
  SETOR: 'Tecnología de la Informacion',
  STATUS: 'Estado',
  SUBJECT: "Tema",
  
  TEAM: 'Equipo',
  TEAMS: 'Equipos',
  TICKET_TIP: 'Propina de boleto',
  TICKET_INFORMATION: 'Información de entradas',
  
  URGENCY: 'Urgencia',
};

module.exports = es;
