import api from "./api";

export const timeApi = {
  getDropdown: async (data, token) => {
    return api.get(`/Team/GetAllActives`, data, token);
  },
  getById: async (id, token) => {
    return api.get(`/Team/${id}`, null, token);
  },
  getListPaged: async (data, token) => {
    return api.get(`/Team/GetListPaged`, data, token);
  },
  create: async (data, token) => {
    return api.post(`/Team/Create`, data, token);
  },
  update: async (data, token) => {
    return api.put(`/Team/Update`, data, token);
  },
  delete: async (id, token) => {
    return api.delete(`/Team/${id}`, null, token);
  },
  activate: async (id, token) => {
    return api.patch(`/Team/${id}/IsDisabled`, { isDisabled: false }, token);
  },
  inactivate: async (id, token) => {
    return api.patch(`/Team/${id}/IsDisabled`, { isDisabled: true }, token);
  },
  getLoggedUserTeams: async (token) => {
    return api.get(`/Team/GetLoggedUserTeams`, null, token);
  },
};
