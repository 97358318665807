import { useMemo, useCallback } from "react";
import useFetch from "./useFetch";
import { userApi } from "../../utility/api/";

export const useUsers = (form, userName, requireTeam) => {
  const { getValues } = form;

  const data = useCallback(() => {
    return {
      userName: userName,
      teamId: getValues("teamId"),
    };
  }, [getValues, userName]);

  const watchFields = useMemo(() => ["teamId"], []);

  const prepareResult = useCallback(
    (result) => {
      if (requireTeam && !getValues("teamId")) return [];

      return result;
    },
    [getValues, requireTeam]
  );

  return useFetch({
    endpoint: userApi.getDropdown,
    data,
    form,
    watchFields,
    prepareResult,
  });
};
