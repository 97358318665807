import { ticketStatus } from "../../../../../utility/enums";
import { DefaultTextbox } from "./DefaultTextBox";
import { ReadonlyTextBox } from "./ReadonlyTextbox";

export const Textbox = ({
  ticket,
  onAttachmentClick,
  inputRef,
  onKeyUp,
  onSend,
  className,
  maxLength,
}) => {
  if (
    ticket.statusCode === ticketStatus.closed ||
    ticket.statusCode === ticketStatus.reproved
  ) {
    return <ReadonlyTextBox className={className} />;
  }

  return (
    <DefaultTextbox
      maxLength={maxLength}
      className={className}
      onAttachmentClick={onAttachmentClick}
      inputRef={inputRef}
      onKeyUp={onKeyUp}
      onSend={onSend}
    />
  );
};
