import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";

import Input from "../../components/Input";
import { ticketApi } from "../../utility/api/";
import { useSubmit } from "../../hooks/useSubmit";
import { selectUserToken } from "../../features/user";
import Button, { buttonStyle } from "../../components/Button";
import Loading from "../../components/Loading";
import clsx from "clsx";
import FileContainer from "../../components/FileContainer";
import useFileContainer from "../../components/FileContainer/useFileContainer";
import { CancelarButton } from "../../components/Cancelar/CancelarButton";
import { Modal } from "../../components/Modal";

const validExtensions = [
  ".xlsx",
  ".docx",
  ".pptx",
  ".pdf",
  ".jpeg",
  ".jpg",
  ".png",
  ".txt",
  ".mp4",
  ".avi",
  ".mpeg",
  ".mpg",
];

const FinalizarChamado = ({ setCloseTicket, closeTicket, onFinished }) => {
  const form = useForm();
  const token = useSelector(selectUserToken);

  const { ticketId, isManager } = closeTicket;
  const [isLoading, setIsLoading] = useState(true);

  const maxSize = 2 * 10 ** 9; // 2Gb

  const {
    fileInputRef,
    arquivos,
    adicionarArquivos,
    removerArquivo,
    openFileSearchModal,
  } = useFileContainer({
    form,
    name: "files",
    validExtensions,
    maxFileQuantity: 5,
    maxSize,
  });

  const fetchChamado = useCallback(async () => {
    let result;
    if (isManager) result = await ticketApi.getManagerClose(ticketId, token);
    else result = await ticketApi.getClose(ticketId, token);

    if (result.hasErro) {
      Swal.fire({
        icon: "error",
        text: "Algo deu errado",
      });
      setIsLoading(false);
      onFinished?.();
      return;
    }

    form.reset({ ...result.data });

    setInterval(() => {
      setIsLoading(false);
    }, 1000);
  }, [form, isManager, onFinished, ticketId, token]);

  useEffect(() => {
    fetchChamado();
  }, [fetchChamado]);

  const prepareData = useCallback(
    (data) => {
      const formData = new FormData();
      let keys = Object.keys(data);

      for (let key of keys) {
        formData.append(key, data[key]);
      }

      formData.append("id", ticketId);

      for (let file of arquivos) formData.append("files", file);

      return formData;
    },
    [arquivos, ticketId]
  );

  const onSuccess = useCallback(() => {
    onFinished?.();
    setCloseTicket(undefined);
  }, [onFinished, setCloseTicket]);

  const { onSubmit, isLoading: isSubmiting } = useSubmit({
    form,
    endpoint: isManager ? ticketApi.managerClose : ticketApi.close,
    prepareData,
    onSuccess,
    successMessage: "Sucesso! Chamado finalizado com sucesso",
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Modal
      title={`Finalizar Chamado`}
      onClose={() => setCloseTicket(undefined)}
      form={form}
    >
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-wrap gap-y-3"
      >
        <div className="w-full m-f9:order-1 m-f9:w-1/2">
          <Input name="number" label="Número" form={form} readOnly />
        </div>

        <div className="w-full m-f9:order-2 m-f9:w-1/2">
          <Input
            name="keyUserEmail"
            label="Email do Requisitante"
            form={form}
            readOnly
          />
        </div>

        <div className="m-f9:order-3 w-full">
          <Input
            name="subject"
            label="Assunto"
            form={form}
            readOnly
            multiline
          />
        </div>

        <div className="m-f9:order-4 w-full">
          <Input
            name="description"
            label="Descrição"
            form={form}
            readOnly
            multiline
          />
        </div>

        <div className="m-f9:order-6">
          <Button
            style={buttonStyle.darkBlue}
            onClick={() => openFileSearchModal()}
            className="min-w-0 leading-4 h-11 w-full"
          >
            Procurar Arquivo
          </Button>
        </div>

        <div className="m-f9:order-5 w-full">
          <Input
            name="resolution"
            label="Solução do chamado"
            placeholder="Insira aqui a solução do chamado"
            form={form}
            multiline
            required
          />
        </div>

        <div className="m-f9:order-7 w-full">
          <FileContainer
            arquivos={arquivos}
            adicionarArquivos={adicionarArquivos}
            removerArquivo={removerArquivo}
            fileInputRef={fileInputRef}
          />
        </div>

        <div className="order-8 w-full m-f9:flex justify-between col-span-2 grid grid-cols-2 gap-10">
          <CancelarButton
            form={form}
            onReturn={() => setCloseTicket(undefined)}
            className="w-auto min-w-0 m-f9:min-w-[11rem] h-auto py-2"
          />

          <div className="flex justify-center">
            <Button
              style={buttonStyle.darkGreen}
              type="submit"
              isLoading={isSubmiting}
              className={clsx(
                "min-w-0 m-f9:min-w-[11rem] h-auto py-2",
                isSubmiting ? "w-auto" : "w-full"
              )}
            >
              Salvar
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default FinalizarChamado;
