const GreenBalloon = ({ children }) => {
  return (
    <div className="relative">
      <svg
        width="338"
        height="20"
        viewBox="0 0 338 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M333.23 0.5H336.895L332.854 5.09851L332.851 5.10238L329.731 8.72732C329.028 9.54391 328.641 10.5856 328.641 11.663V28.5V53C328.641 54.933 327.074 56.5 325.141 56.5H4C2.06701 56.5 0.5 54.933 0.5 53V4C0.5 2.067 2.067 0.5 4 0.5H315.512H333.23Z"
          fill="#E1EFE5"
          stroke="#CEE3D4"
        />
      </svg>
      <div
        className="bg-[#E1EFE5] border-[#CEE3D4] w-[330px] px-[8px] leading-[10px]"
        style={{
          borderRadius: "0px 0px 6px 6px",
          borderWidth: "0px 1px 1px 1px",
        }}
      >
        <div className="leading-7 text-[10px] text-[#3E3E3E] font-semibold relative -top-[10px] break-words">
          {children}
        </div>
      </div>
    </div>
  );
};

export default GreenBalloon;
