import { clsx } from "clsx";

const SubMenu = ({ isVisibleSubMenuFuncionalidades, text, children, icon }) => {
  return (
    <div
      className={clsx(
        "absolute bg-[#f1ecec] z-10 h-screen left-0 top-6 w-56 -translate-x-full duration-500",
        isVisibleSubMenuFuncionalidades &&
          "m-f9:left-[31rem] left-[17rem] translate-x-full"
      )}
    >
      <div className="w-full h-[500px] grid grid-rows-6 gap-2">
        <div className="w-full flex justify-center">
          <div className="w-[90%] h-full flex items-end border-b border-[#1D284A]">
            <div className="py-2 font-semibold text-[#1D284A] flex">
              <img alt="Módulos" src={icon} className="pr-3" />
              {text}
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
export default SubMenu;
