import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";

import Input from "../../components/Input";
import { ticketApi } from "../../utility/api/";
import { Modal } from "../../components/Modal";
import { useSubmit } from "../../hooks/useSubmit";
import { selectUserToken } from "../../features/user";
import { CancelarButton } from "../../components/Cancelar/CancelarButton";
import Button, { buttonStyle } from "../../components/Button";
import clsx from "clsx";

const ReprovarChamado = ({ setIsVisible, ticketId, onFinished }) => {
  const form = useForm();
  const token = useSelector(selectUserToken);

  const fetchChamado = useCallback(async () => {
    let result = await ticketApi.getDisapprove(ticketId, token);

    if (result.hasErro) {
      Swal.fire({
        icon: "error",
        text: "Algo deu errado",
      });
      return;
    }

    form.reset({ ...result.data });
  }, [form, ticketId, token]);

  useEffect(() => {
    fetchChamado();
  }, [fetchChamado]);

  const prepareData = useCallback(
    (data) => {
      return {
        ...data,
        id: ticketId,
      };
    },
    [ticketId]
  );

  const onSuccess = useCallback(() => {
    onFinished?.();
    setIsVisible(undefined);
  }, [onFinished, setIsVisible]);

  const { onSubmit, isLoading } = useSubmit({
    form,
    prepareData,
    endpoint: ticketApi.disapprove,
    onSuccess,
    title: "Reprovado!",
    successMessage: "Chamado cancelado!",
    icon: "info",
  });

  return (
    <Modal
      title={`Reprovar Chamado`}
      form={form}
      onClose={() => setIsVisible(false)}
    >
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-wrap gap-y-3"
      >
        <div className="w-full m-f9:w-1/2">
          <Input name="number" label="Número do Chamado" form={form} readOnly />
        </div>

        <div className="w-full m-f9:w-1/2">
          <Input
            name="keyUserEmail"
            label="Email do Requisitante"
            form={form}
            readOnly
          />
        </div>

        <div className="w-full">
          <Input
            name="subject"
            label="Assunto"
            form={form}
            readOnly
            multiline
          />
        </div>

        <div className="w-full">
          <Input
            name="description"
            label="Descrição"
            form={form}
            readOnly
            multiline
          />
        </div>

        <div className="w-full">
          <Input
            name="disapproveReason"
            label="Motivo da Reprovação"
            placeholder="Insira aqui o motivo da reprovação"
            form={form}
            multiline
            required
          />
        </div>

        <div className="order-6 w-full m-f9:flex justify-between col-span-2 grid grid-cols-2 gap-10">
          <CancelarButton
            form={form}
            onReturn={() => setIsVisible(false)}
            className="w-auto min-w-0 m-f9:min-w-[11rem] h-auto py-2"
          />

          <div className="flex justify-center">
            <Button
              style={buttonStyle.darkGreen}
              type="submit"
              isLoading={isLoading}
              className={clsx(
                "min-w-0 m-f9:min-w-[11rem] h-auto py-2",
                isLoading ? "w-auto" : "w-full"
              )}
            >
              Salvar
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ReprovarChamado;
