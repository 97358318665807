import { NavLink } from "react-router-dom";
const MenuText = ({ configMenu }) => {
    return (
        <div>
            {configMenu.map((menu, index) => (
                <div key={index} className="h-[100px] flex flex-col justify-center">
                    <div className="text-white text-[16px] cursor-pointer" onClick={menu.actionText}>
                        <NavLink to={menu.to && `${menu.to}`}>{menu.title}</NavLink>
                    </div>
                </div>
            ))}
        </div>
    );
}
export default MenuText;