import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useCallback, useRef, useState, useEffect } from "react";

import Input from "../components/Input";
import Label from "../components/Label";
import Star from "../assets/png/star.png";
import { useSubmit } from "../hooks/useSubmit";
import StarFull from "../assets/png/star-full.png";
import MenuHeader from "./Layout/Header/MenuHeader";
import { useQueryString } from "../utility/useQueryString";
import { satisfactionSurveyApi } from "../utility/api";
import Button, { buttonStyle } from "../components/Button";
import Loading from "../components/Loading";

const PesquisaSatisfacao = () => {
  const queryString = useQueryString();
  const hash = queryString.get("id");
  const navigate = useNavigate();

  const [isPesquisaLoading, setIsPesquisaLoading] = useState(true);
  const [ticketNumber, setTicketNumber] = useState("");

  const commentaryRef = useRef(null);

  const [agility, setAgility] = useState(5);
  const [tempAgility, setTempAgility] = useState(undefined);

  const [quality, setQuality] = useState(5);
  const [tempQuality, setTempQuality] = useState(undefined);

  const fetchExists = useCallback(async () => {
    if (!hash) {
      Swal.fire({
        title: "Algo deu errado!",
        icon: "error",
      }).then((result) => {
        if (!result.isConfirmed) return;
        navigate("/");
      });
      return;
    }

    let result = await satisfactionSurveyApi.get({ hash });

    setTimeout(() => {
      setIsPesquisaLoading(false);
    }, 1000);

    if (result.hasError || result.data.hasException) {
      Swal.fire({
        title: "Algo deu errado!",
        icon: "error",
      }).then((result) => {
        if (!result.isConfirmed) return;
        navigate("/");
      });
      return;
    }

    if (result.data.isDuplicate) {
      Swal.fire({
        title: "Atenção!",
        text: "Essa pesquisa de satisfação já foi respondida",
        icon: "info",
      }).then((result) => {
        if (!result.isConfirmed) return;
        navigate("/");
      });
      return;
    }

    setTicketNumber(result.data.ticketNumber);
  }, [hash, navigate]);

  useEffect(() => {
    fetchExists();
  }, [fetchExists]);

  const prepareData = useCallback(() => {
    return {
      hash,
      quality,
      agility,
      commentary: commentaryRef.current.value,
    };
  }, [agility, hash, quality]);

  const onAlertSuccess = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const { onSubmit, isLoading } = useSubmit({
    endpoint: satisfactionSurveyApi.create,
    prepareData,
    successMessage: "Pesquisa de satisfação enviada",
    onAlertSuccess,
  });

  if (isPesquisaLoading) {
    return <Loading />;
  }

  return (
    <div id="background-geral">
      <div className="fixed flex top-0 left-0 w-full shadow h-14 justify-between z-40 bg-white m-f1:h-12 t-f1:h-14">
        <MenuHeader />
      </div>
      <div className="w-full min-h-screen">
        <div className="w-full hd-f1:min-h-[91vh] hd-f4:min-h-[92vh] hd-f6:min-h-[90vh] hd-f9:min-h-[94vh] flex justify-center pt-[56px]">
          <div className="flex items-center content-center justify-center max-w-[90vw]">
            <div className="bg-white border min-w-[50vw] py-10 flex content-center items-center flex-col">
              <div className="text-center mb-8">
                <h1 className="text-[#242b46] font-semibold text-3xl">
                  Pesquisa de Satisfação
                </h1>
                <h5 className="text-sm">
                  Avalie o atendimento do chamado #{ticketNumber}
                </h5>
              </div>

              <div className="grid grid-cols-2 gap-20 mb-8">
                <div className="text-center">
                  <Label>Agilidade</Label>
                  <div className="grid grid-cols-5">
                    <button
                      onMouseEnter={() => setTempAgility(1)}
                      onMouseLeave={() => setTempAgility(undefined)}
                      onClick={() => setAgility(1)}
                      className="px-2"
                    >
                      <img
                        alt="Agilidade"
                        src={(tempAgility || agility) >= 1 ? StarFull : Star}
                      />
                    </button>
                    <button
                      onMouseEnter={() => setTempAgility(2)}
                      onMouseLeave={() => setTempAgility(undefined)}
                      onClick={() => setAgility(2)}
                      className="px-2"
                    >
                      <img
                        alt="Agilidade"
                        src={(tempAgility || agility) >= 2 ? StarFull : Star}
                      />
                    </button>
                    <button
                      onMouseEnter={() => setTempAgility(3)}
                      onMouseLeave={() => setTempAgility(undefined)}
                      onClick={() => setAgility(3)}
                      className="px-2"
                    >
                      <img
                        alt="Agilidade"
                        src={(tempAgility || agility) >= 3 ? StarFull : Star}
                      />
                    </button>
                    <button
                      onMouseEnter={() => setTempAgility(4)}
                      onMouseLeave={() => setTempAgility(undefined)}
                      onClick={() => setAgility(4)}
                      className="px-2"
                    >
                      <img
                        alt="Agilidade"
                        src={(tempAgility || agility) >= 4 ? StarFull : Star}
                      />
                    </button>
                    <button
                      onMouseEnter={() => setTempAgility(5)}
                      onMouseLeave={() => setTempAgility(undefined)}
                      onClick={() => setAgility(5)}
                      className="px-2"
                    >
                      <img
                        alt="Agilidade"
                        src={(tempAgility || agility) >= 5 ? StarFull : Star}
                      />
                    </button>
                  </div>
                </div>

                <div className="text-center">
                  <Label>Qualidade</Label>
                  <div className="grid grid-cols-5">
                    <button
                      onMouseEnter={() => setTempQuality(1)}
                      onMouseLeave={() => setTempQuality(undefined)}
                      onClick={() => setQuality(1)}
                      className="px-2"
                    >
                      <img
                        alt="Qualidade"
                        src={(tempQuality || quality) >= 1 ? StarFull : Star}
                      />
                    </button>
                    <button
                      onMouseEnter={() => setTempQuality(2)}
                      onMouseLeave={() => setTempQuality(undefined)}
                      onClick={() => setQuality(2)}
                      className="px-2"
                    >
                      <img
                        alt="Qualidade"
                        src={(tempQuality || quality) >= 2 ? StarFull : Star}
                      />
                    </button>
                    <button
                      onMouseEnter={() => setTempQuality(3)}
                      onMouseLeave={() => setTempQuality(undefined)}
                      onClick={() => setQuality(3)}
                      className="px-2"
                    >
                      <img
                        alt="Qualidade"
                        src={(tempQuality || quality) >= 3 ? StarFull : Star}
                      />
                    </button>
                    <button
                      onMouseEnter={() => setTempQuality(4)}
                      onMouseLeave={() => setTempQuality(undefined)}
                      onClick={() => setQuality(4)}
                      className="px-2"
                    >
                      <img
                        alt="Qualidade"
                        src={(tempQuality || quality) >= 4 ? StarFull : Star}
                      />
                    </button>
                    <button
                      onMouseEnter={() => setTempQuality(5)}
                      onMouseLeave={() => setTempQuality(undefined)}
                      onClick={() => setQuality(5)}
                      className="px-2"
                    >
                      <img
                        alt="Qualidade"
                        src={(tempQuality || quality) >= 5 ? StarFull : Star}
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="mb-8 flex justify-center mx-2 max-w-[calc(100%_-_1rem)]">
                <div className="w-[32rem]">
                  <Input
                    label="Comente sobre o atendimento"
                    className="min-h-[6.6rem]"
                    multiline
                    ref={commentaryRef}
                  />
                </div>
              </div>

              <div>
                <Button
                  onClick={onSubmit}
                  style={buttonStyle.darkBlue}
                  isLoading={isLoading}
                >
                  Enviar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PesquisaSatisfacao;
