import { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import { shallowObjectComparison } from "../../utility/util";

export const useCancelar = ({ form, onReturn }) => {
  const { watch, getValues, reset } = form ?? {};

  const [initialData, setInitialData] = useState(undefined);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    if (!form) return;

    if (!initialData) {
      setInitialData(getValues());
    }

    const subscription = watch((values, { name, type }) => {
      let newValues = getValues();

      // O name e o type retorna como undefined quando é realizado o reset
      if (name === undefined && type === undefined) {
        setInitialData(values);
        setHasData(false);
      } else {
        setHasData(!shallowObjectComparison(newValues, initialData));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [form, getValues, initialData, watch]);

  useEffect(() => {
    if (!form) return;

    setHasData(!shallowObjectComparison(getValues(), initialData));
  }, [form, getValues, initialData]);

  const onCancelarClick = useCallback(() => {
    if (!hasData) {
      onReturn?.();
      return;
    }

    Swal.fire({
      icon: "info",
      title: "Tem certeza que deseja cancelar?",
      confirmButtonText: "Sim",
      denyButtonText: `Não`,
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        reset();
        onReturn?.();
      }
    });
  }, [hasData, onReturn, reset]);

  return {
    hasData,
    onCancelarClick,
  };
};
