import { useCallback, useMemo } from "react";

import useFetch from "./useFetch";
import { timeApi } from "../../utility/api/";

export const useTimes = (form, departments) => {
  let data = useCallback(() => {
    if (form) return { departmentId: form.getValues("departmentId") };

    if (departments && departments.length > 0)
      return { departments: departments.map((d) => d.id) };

    return undefined;
  }, [departments, form]);

  const watchFields = useMemo(
    () => (form ? ["departmentId"] : undefined),
    [form]
  );

  return useFetch({
    endpoint: timeApi.getDropdown,
    data,
    form,
    watchFields,
  });
};
