import useFetch from "./useFetch";
import { departamentoApi } from "../../utility/api";
import { useSelector } from "react-redux";
import { selectUserDivisionId } from "../../features/user";
import { useCallback } from "react";

export const useTicketDepartments = ({ prepareResult }) => {
  const divisionId = useSelector(selectUserDivisionId);

  const data = useCallback(() => {
    return { divisionId };
  }, [divisionId]);

  return useFetch({
    endpoint: departamentoApi.getAllForTicket,
    data,
    prepareResult,
  });
};
