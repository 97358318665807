export const ticketStatus = {
  reproved: 0,
  closed: 1,
  attending: 2,
  analyzing: 3,
  configured: 4,
};

export const ticketDeadlineStatus = {
  notSet: 0,
  onTime: 1,
  almostLate: 2,
  late: 3,
};
