import api from "./api";

export const userApi = {
  getDropdown: async (data, token) => {
    return api.get(`/User/GetAllActives`, data, token);
  },
  getAllForTicket: async (data, token) => {
    return api.get(`/User/GetAllForTicket`, data, token);
  },
  getAllForTeam: async (data, token) => {
    return api.get(`/User/GetUserForTeam`, data, token);
  },
  dropdownSearch: async (data, token) => {
    return api.get(`/User/DropdownSearch`, data, token);
  },
  startDropdownSearch: async (data, token) => {
    return api.get(`/User/StartDropdownSearch`, data, token);
  },
  getLoginData: async (data, token) => {
    return api.get(`/Account/AccessToken`, data, token, {
      serverUrl: process.env.REACT_APP_Portal_API_URL,
    });
  },
};
