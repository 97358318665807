import iconCloseMenu from "../../../assets/png/chevron-double-left.png";
const HeaderMenu = ({ closeMenu }) => {
  return (
    <button
      className="h-[50px] flex flex-row justify-center items-center"
      onClick={closeMenu}
    >
      <div className="w-[150px]"></div>
      <div className="pl-4">
        <img alt="" src={iconCloseMenu} className="w-[24px] h-[24px]" />
      </div>
    </button>
  );
};
export default HeaderMenu;
